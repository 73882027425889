import { Paper, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Title from "../../Components/Title";
import { checkAdmin, getToken, requestErrorHandler } from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import { showPopupSnackbar } from "../../redux/actions/snackbarActions";
import LifeCycleState from "./LifeCycleState";
const server = EnvSettings.server;

export default function DeviceOverview({ deviceData: _deviceData }) {
  const { t } = useTranslation();
  const [deviceData, setDeviceData] = useState();
  const [editActive, setEditActive] = useState(false);
  const [editText, setEditText] = useState(t("deviceDetails.edit"));
  const [companies, setCompanies] = useState();
  const dispatch = useDispatch();

  const getCompanyID = (c) => {
    if (!c) {
      return undefined;
    }
    var a = companies.filter((comp) => {
      return comp.name === c;
    });
    if (a.length !== 0) {
      return a[0].id;
    }
    return undefined;
  };

  const updateDevice = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        deviceData: { ...deviceData },
      }),
    };
    fetch(server + "/edit_device", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);

        if (!result.error) {
          dispatch(showPopupSnackbar(result));
          setEditActive(false);
          setEditText(
            editActive ? t("deviceDetails.edit") : t("deviceDetails.cancel")
          );
        }
      });
  };

  useEffect(() => {
    // const getDevicedata = () => {
    //   const requestOptions = {
    //     method: "POST",
    //     headers: { "Content-Type": "application/json" },
    //     body: JSON.stringify({
    //       token: getToken(),
    //       deviceid: getLastParameter(),
    //     }),
    //   };
    //   fetch(server + "/get_device_data", requestOptions)
    //     .then((res) => res.json())
    //     .then((result) => {
    //       requestErrorHandler(result, dispatch);
    //       if (!result.error) {
    //         setDeviceData(result.device);
    //       }
    //     });
    // };
    const getCompanies = () => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token: getToken() }),
      };
      fetch(server + "/get_companies", requestOptions)
        .then((res) => res.json())
        .then((result) => {
          requestErrorHandler(result, dispatch);
          if (!result.error) {
            setCompanies(result.companies);
          }
        });
    };
    // getDevicedata();
    if (checkAdmin()) {
      getCompanies();
    }
  }, [dispatch]);

  useEffect(() => {
    if (!deviceData && _deviceData) {
      setDeviceData(_deviceData);
    }
    // eslint-disable-next-line
  }, [_deviceData]);

  return deviceData ? (
    <>
      {checkAdmin() ? <LifeCycleState deviceData={deviceData} /> : undefined}
      <Grid container spacing={3} style={{ flexDirection: "row" }}>
        <Grid item>
          <Title>{t("deviceDetails.deviceOverview")}</Title>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            name="serialNumber"
            variant="outlined"
            required
            fullWidth
            id="serialNumber"
            label={t("deviceDetails.serial")}
            disabled
            value={deviceData.serialNumber}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="deviceType"
            variant="outlined"
            required
            fullWidth
            id="deviceType"
            label={t("deviceDetails.model")}
            disabled={checkAdmin() && editActive ? false : true}
            value={deviceData.model ?? ""}
            onChange={(e) => {
              setDeviceData({ ...deviceData, model: e.target.value });
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            name="deviceName"
            variant="outlined"
            required
            fullWidth
            id="deviceName"
            label={t("deviceDetails.name")}
            disabled={!editActive}
            //defaultValue={deviceName}
            value={deviceData.deviceName ?? ""}
            onChange={(e) => {
              setDeviceData({ ...deviceData, deviceName: e.target.value });
            }}
          />
        </Grid>
        {checkAdmin() && companies ? (
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="combo-box"
              PaperComponent={(props) => (
                <Paper style={{ border: "2px solid #3d57a5" }} {...props} />
              )}
              options={companies}
              defaultValue={deviceData.Company}
              disabled={!editActive}
              getOptionLabel={(option) => option.name}
              //style={{ width: 300 }}
              onInputChange={(e, n) => {
                setDeviceData({ ...deviceData, CompanyId: getCompanyID(n) });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("deviceDetails.company")}
                  variant="outlined"
                />
              )}
            />
          </Grid>
        ) : undefined}
        <Grid item xs={12}>
          <Button
            variant="outlined"
            onClick={() => {
              setEditActive(editActive ? false : true);
              setEditText(
                editActive ? t("deviceDetails.edit") : t("deviceDetails.cancel")
              );
            }}
          >
            <Typography>{editText}</Typography>
          </Button>
          <Button
            variant="outlined"
            style={editActive ? undefined : { display: "none" }}
            onClick={() => {
              updateDevice();
            }}
          >
            <Typography>{t("deviceDetails.submit")}</Typography>
          </Button>
        </Grid>
      </Grid>
    </>
  ) : null;
}

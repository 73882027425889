import {
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import generatePDF from "react-to-pdf";
import {
  getToken,
  invoiceIssuers,
  requestErrorHandler,
} from "../../../Utils/Common";
import EnvSettings from "../../../Utils/EnvSettings";
import ccodes from "../../WorldMap/country-codes-lat-lon.json";

const server = EnvSettings.server;

const filterIds = (data) => {
  if (!data) {
    return data;
  }
  var d = { ...data };
  Object.keys(d).forEach((k) => {
    if (
      k &&
      (k
        .toLowerCase()
        .substring(k.length - 2)
        .includes("id") ||
        k === "unknownReceiver")
    ) {
      delete d[k];
    }
  });
  return d;
};

const formatData = (showPackingList) => {
  if (showPackingList) {
    return {
      exporter: Object.values(
        filterIds(invoiceIssuers[showPackingList.invoiceIssuer])
      ).join("\n"),
      delivery: Object.values(
        filterIds(showPackingList.shippingInfo ?? showPackingList.clientInfo)
      ).join("\n\n"),
      importer: Object.values(filterIds(showPackingList.clientInfo)).join(
        "\n\n"
      ),
      buyer: "",
      invoiceNo: showPackingList.number,
      hsCode:
        showPackingList.genericDevices.length === 1
          ? showPackingList.genericDevices[0]?.packingDetail?.hsCode
          : "",
      otherRefrences: "",
      portOfLoading: "ESPOO",
      portOfDischarge: "",
      incoterms: "",
      finalDestination: "",
      countryOfOrigin: "FINLAND",
      countryOfDestination: filterIds(
        showPackingList.shippingInfo ?? showPackingList.clientInfo
      )
        ? ccodes.ref_country_codes
            .find(
              (o) =>
                o &&
                o.country &&
                Object.values(
                  filterIds(
                    showPackingList.shippingInfo ?? showPackingList.clientInfo
                  )
                )
                  .join("\n")
                  .toLowerCase()
                  .includes(o.country.toLowerCase())
            )
            ?.country.toUpperCase()
        : "",
      goods: showPackingList.Devices
        ? showPackingList.genericDevices.map((f, ii) => {
            var m = { ...f };
            showPackingList.Devices.forEach((d) => {
              if (
                m.sku.includes("CTN_EC") &&
                ((m.sku.includes("W") && d.color.includes("W")) ||
                  (m.sku.includes("G") && d.color.includes("G")))
              ) {
                if (m.serialNumbers) {
                  m.serialNumbers.push(d.serialNumber);
                } else {
                  m.serialNumbers = [d.serialNumber];
                }
              }
            });
            if (m.PackingDetail) {
              m.packages = m.PackingDetail?.packages
                ? m.PackingDetail.packages
                    .map((p) => p.type + "\n(" + p.count + " pcs)")
                    .join("\n")
                : "";
              m.weight = m.PackingDetail?.packages
                ? m.PackingDetail.weight
                : "";
              m.dimensions = m.PackingDetail?.packages
                ? m.PackingDetail.packages
                    .map((p) => p.length + " x " + p.width + " x " + p.height)
                    .join("\n")
                : "";
              m.hsCode =
                m.PackingDetail?.hsCodes
                  ?.map((h) =>
                    h.code && h.code.trim() !== ""
                      ? "HS Code: " + h.code.trim()
                      : ""
                  )
                  .join("\n") || "";
            }
            return m;
          })
        : showPackingList.genericDevices,
      totalPackages: showPackingList.genericDevices?.reduce(
        (a, m) =>
          m.PackingDetail
            ? m.PackingDetail?.packages.length * m.count + a
            : 0 * a,
        0
      ),
      totalWeight: showPackingList.genericDevices?.reduce(
        (a, m) =>
          m.PackingDetail ? m.PackingDetail.weight * m.count + a : 0 * a,
        0
      ),
      totalQuantity: showPackingList.genericDevices?.reduce(
        (a, m) => m.count + a,
        0
      ),
    };
  }
  return;
};

export default function CreatePackingList({
  showPackingList,
  setShowPackingList,
  callback,
  // setPackingListCancelled,
}) {
  const { t } = useTranslation();
  const componentRef = useRef();
  const [packingList, setPackingList] = useState();
  const [showPL, setShowPL] = useState(false);
  const [submit, setSubmit] = useState(false);
  const dispatch = useDispatch();

  const downloadPdf = () =>
    generatePDF(
      () => {
        return componentRef.current;
      },
      {
        method: submit ? "build" : "open",
      }
    );

  /*
    {
      exporter:,
      delivery:,
      importer:,
      buyer:undefined,
      invoiceNo:,
      hsCode:,
      otherRefrences:,
      portOfLoading:,
      portOfDischarge:,
      incoterms:,
      finalDestination:,
      countryOfOrigin:,
      countryOfDestination:,
      goods:,
      totalPackages:,
      totalWeight:,
    }
   */

  const exit = () => {
    setShowPackingList(false);
    setPackingList();
    // setPackingListCancelled(true);
  };

  const changeValues = (val, key, s) => {
    // console.log(val, key);
    val = val || "";
    if (key) {
      var d = packingList ? { ...packingList } : { ...formatData(s) };
      if (key.includes(".")) {
        key = key.split(".");
      }
      if (Array.isArray(key)) {
        if (key[0] === "goods") {
          var idx = d.goods.findIndex((o) => o.sku && o.sku === key[1]);
          d.goods[idx][key[2]] = val;
        }
      } else {
        d[key] = val;
      }
      setPackingList({ ...d });
    }
  };

  const submitOrder = (file) => {
    if (!file || !showPackingList) {
      return;
    }
    const data = new FormData();
    data.append("token", getToken());
    data.append("orderData", JSON.stringify(showPackingList));
    var v = file.output("blob");
    v = new File([v], "packing_list_" + showPackingList.number + ".pdf", {
      type: "application/pdf",
    });
    data.append("packing_list", v);
    const requestOptions = {
      method: "POST",
      body: data,
    };
    fetch(server + "/edit_order", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        setSubmit(false);
        if (callback) {
          callback();
        }
        exit();
      });
  };

  useEffect(() => {
    if (showPackingList) {
      setTimeout(() => {
        var v = formatData(showPackingList);
        setPackingList(v);
        console.log(v);
      }, 100);
    }
  }, [showPackingList, setPackingList]);

  useEffect(() => {
    if (showPL && componentRef.current) {
      downloadPdf().then((pdf) => {
        if (submit) {
          submitOrder(pdf);
        }
        setShowPL(false);
      });
    }
    // eslint-disable-next-line
  }, [showPL, submit]);

  return (
    <Dialog
      open={!!showPackingList}
      onClose={exit}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="xl"
    >
      <DialogTitle id="alert-dialog-title">Create Packing List?</DialogTitle>

      <DialogContent style={{ overflowY: "initial" }}>
        <Grid container style={{ border: "1px solid #000" }} spacing={1}>
          <Grid
            container
            item
            xs={12}
            style={{ borderBottom: "1px solid #000" }}
          >
            <Grid item xs={6} style={{ borderRight: "1px solid #000" }}>
              <Typography style={{ fontWeight: "bold" }}>
                Exporter/Shipper
              </Typography>
              {packingList ? (
                <TextField
                  onChange={(e, v) => {
                    changeValues(e.target.value, "exporter", showPackingList);
                  }}
                  variant="outlined"
                  fullWidth
                  size="small"
                  multiline
                  defaultValue={packingList.exporter}
                ></TextField>
              ) : null}
            </Grid>
            <Grid item xs={6}>
              <Typography style={{ fontWeight: "bold" }}>
                Shipping marks. DELIVERY TO:
              </Typography>
              {packingList ? (
                <TextField
                  onChange={(e, v) => {
                    changeValues(e.target.value, "delivery", showPackingList);
                  }}
                  variant="outlined"
                  fullWidth
                  size="small"
                  multiline
                  defaultValue={packingList.delivery}
                ></TextField>
              ) : null}
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            style={{ borderBottom: "1px solid #000" }}
          >
            <Grid item xs={6} style={{ borderRight: "1px solid #000" }}>
              <Typography style={{ fontWeight: "bold" }}>
                Importer/Consignee
              </Typography>
              {packingList ? (
                <TextField
                  onChange={(e, v) => {
                    changeValues(e.target.value, "importer", showPackingList);
                  }}
                  variant="outlined"
                  fullWidth
                  size="small"
                  multiline
                  defaultValue={packingList.importer}
                ></TextField>
              ) : null}
            </Grid>
            <Grid item xs={6}>
              <Typography style={{ fontWeight: "bold" }}>
                Buyer (if other than consignee - name & address)
              </Typography>
              <TextField
                onChange={(e, v) => {
                  changeValues(e.target.value, "buyer", showPackingList);
                }}
                variant="outlined"
                fullWidth
                size="small"
                multiline
              ></TextField>
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            style={{ borderBottom: "1px solid #000" }}
          >
            <Grid item xs={3} style={{ borderRight: "1px solid #000" }}>
              <Typography style={{ fontWeight: "bold" }}>
                Invoice No.
              </Typography>
              {packingList ? (
                <TextField
                  onChange={(e, v) => {
                    changeValues(e.target.value, "invoiceNo", showPackingList);
                  }}
                  variant="outlined"
                  fullWidth
                  size="small"
                  defaultValue={packingList.invoiceNo}
                ></TextField>
              ) : null}
            </Grid>
            <Grid item xs={3} style={{ borderRight: "1px solid #000" }}></Grid>{" "}
            <Grid item xs={3} style={{ borderRight: "1px solid #000" }}>
              <Typography style={{ fontWeight: "bold" }}>HS Code</Typography>
              {packingList ? (
                <TextField
                  onChange={(e, v) => {
                    changeValues(e.target.value, "hsCode", showPackingList);
                  }}
                  variant="outlined"
                  fullWidth
                  size="small"
                  style={{ whiteSpace: "pre-wrap" }}
                  defaultValue={packingList.hsCode}
                ></TextField>
              ) : null}
            </Grid>
            <Grid item xs={3}>
              <Typography style={{ fontWeight: "bold" }}>
                Other refrence(s)
              </Typography>
              {packingList ? (
                <TextField
                  onChange={(e, v) => {
                    changeValues(
                      e.target.value,
                      "otherRefrences",
                      showPackingList
                    );
                  }}
                  variant="outlined"
                  fullWidth
                  size="small"
                  defaultValue={packingList.otherRefrences}
                ></TextField>
              ) : null}
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            style={{ borderBottom: "1px solid #000" }}
          >
            <Grid item xs={3} style={{ borderRight: "1px solid #000" }}>
              <Typography style={{ fontWeight: "bold" }}>
                Port of loading
              </Typography>
              {packingList ? (
                <TextField
                  onChange={(e, v) => {
                    changeValues(
                      e.target.value,
                      "portOfLoading",
                      showPackingList
                    );
                  }}
                  variant="outlined"
                  fullWidth
                  size="small"
                  defaultValue={packingList.portOfLoading}
                ></TextField>
              ) : null}
            </Grid>
            <Grid item xs={3} style={{ borderRight: "1px solid #000" }}>
              <Typography style={{ fontWeight: "bold" }}>
                Port of discharge
              </Typography>
              {packingList ? (
                <TextField
                  onChange={(e, v) => {
                    changeValues(
                      e.target.value,
                      "portOfDischarge",
                      showPackingList
                    );
                  }}
                  variant="outlined"
                  fullWidth
                  size="small"
                  defaultValue={packingList.portOfDischarge}
                ></TextField>
              ) : null}
            </Grid>
            <Grid item xs={3} style={{ borderRight: "1px solid #000" }}>
              <Typography style={{ fontWeight: "bold" }}>Incoterms</Typography>
              {packingList ? (
                <TextField
                  onChange={(e, v) => {
                    changeValues(e.target.value, "incoterms", showPackingList);
                  }}
                  variant="outlined"
                  fullWidth
                  size="small"
                  defaultValue={packingList.incoterms}
                ></TextField>
              ) : null}
            </Grid>
            <Grid item xs={3}>
              <Typography style={{ fontWeight: "bold" }}>
                Final destination
              </Typography>
              {packingList ? (
                <TextField
                  onChange={(e, v) => {
                    changeValues(
                      e.target.value,
                      "finalDestination",
                      showPackingList
                    );
                  }}
                  variant="outlined"
                  fullWidth
                  size="small"
                  defaultValue={packingList.finalDestination}
                ></TextField>
              ) : null}
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            style={{ borderBottom: "1px solid #000" }}
          >
            {" "}
            <Grid item xs={6} style={{ borderRight: "1px solid #000" }}>
              <Typography style={{ fontWeight: "bold" }}>
                Country of origin of goods
              </Typography>
              {packingList ? (
                <TextField
                  onChange={(e, v) => {
                    changeValues(
                      e.target.value,
                      "countryOfOrigin",
                      showPackingList
                    );
                  }}
                  variant="outlined"
                  fullWidth
                  size="small"
                  defaultValue={packingList.countryOfOrigin}
                ></TextField>
              ) : null}
            </Grid>
            <Grid item xs={6}>
              <Typography style={{ fontWeight: "bold" }}>
                Country of final destination
              </Typography>
              {packingList ? (
                <TextField
                  onChange={(e, v) => {
                    changeValues(
                      e.target.value,
                      "countryOfDestination",
                      showPackingList
                    );
                  }}
                  variant="outlined"
                  fullWidth
                  size="small"
                  defaultValue={packingList.countryOfDestination}
                ></TextField>
              ) : null}
            </Grid>
          </Grid>
          <Grid
            container
            item
            xs={12}
            style={{ borderBottom: "1px solid #000" }}
          >
            <Grid item style={{ borderRight: "1px solid #000" }} xs={2}>
              <Typography style={{ fontWeight: "bold", textAlign: "center" }}>
                Marks
              </Typography>
            </Grid>
            <Grid item style={{ borderRight: "1px solid #000" }} xs={2}>
              <Typography style={{ fontWeight: "bold", textAlign: "center" }}>
                Number & kinds of packages
              </Typography>
            </Grid>
            <Grid item style={{ borderRight: "1px solid #000" }} xs={1}>
              <Typography style={{ fontWeight: "bold", textAlign: "center" }}>
                Gross weight (kg)
              </Typography>
            </Grid>
            <Grid item style={{ borderRight: "1px solid #000" }} xs={1}>
              <Typography style={{ fontWeight: "bold", textAlign: "center" }}>
                Net weight (kg)
              </Typography>
            </Grid>
            <Grid item style={{ borderRight: "1px solid #000" }} xs={2}>
              <Typography style={{ fontWeight: "bold", textAlign: "center" }}>
                Dimensions L x W x H (cm)
              </Typography>
            </Grid>
            <Grid item style={{ borderRight: "1px solid #000" }} xs={1}>
              <Typography style={{ fontWeight: "bold", textAlign: "center" }}>
                Quantity
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography style={{ fontWeight: "bold", textAlign: "center" }}>
                Description
              </Typography>
            </Grid>
          </Grid>
          {packingList &&
            packingList?.goods.map((m, i) => {
              return (
                <Grid
                  key={i}
                  container
                  item
                  xs={12}
                  style={{ borderBottom: "1px solid #000" }}
                >
                  <Grid item style={{ borderRight: "1px solid #000" }} xs={2}>
                    {m.serialNumbers ? (
                      m.serialNumbers.map((d, ii) => (
                        <Typography key={ii} style={{ textAlign: "center" }}>
                          {d}
                        </Typography>
                      ))
                    ) : (
                      <TextField
                        // onChange={(e,v)=>{changeValues(e.target.value, )}}
                        variant="outlined"
                        fullWidth
                        size="small"
                      ></TextField>
                    )}
                  </Grid>
                  <Grid item style={{ borderRight: "1px solid #000" }} xs={2}>
                    <TextField
                      onChange={(e, v) => {
                        changeValues(
                          e.target.value,
                          "goods." + m.sku + ".packages",
                          showPackingList
                        );
                      }}
                      defaultValue={m.packages}
                      multiline
                      variant="outlined"
                      fullWidth
                      size="small"
                    ></TextField>
                  </Grid>
                  <Grid item style={{ borderRight: "1px solid #000" }} xs={1}>
                    <TextField
                      onChange={(e, v) => {
                        changeValues(
                          e.target.value,
                          "goods." + m.sku + ".weight",
                          showPackingList
                        );
                      }}
                      variant="outlined"
                      fullWidth
                      size="small"
                      defaultValue={m.weight}
                    ></TextField>
                  </Grid>
                  <Grid item style={{ borderRight: "1px solid #000" }} xs={1}>
                    <TextField
                      // onChange={(e,v)=>{changeValues(e.target.value, )}}
                      variant="outlined"
                      fullWidth
                      size="small"
                    ></TextField>
                  </Grid>
                  <Grid item style={{ borderRight: "1px solid #000" }} xs={2}>
                    <TextField
                      onChange={(e, v) => {
                        changeValues(
                          e.target.value,
                          "goods." + m.sku + ".dimensions",
                          showPackingList
                        );
                      }}
                      multiline
                      variant="outlined"
                      fullWidth
                      size="small"
                      defaultValue={m.dimensions}
                    ></TextField>
                  </Grid>
                  <Grid item style={{ borderRight: "1px solid #000" }} xs={1}>
                    <Typography style={{ textAlign: "center" }}>
                      {m.count}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography style={{ textAlign: "center" }}>
                      {m.name}
                    </Typography>
                    <TextField
                      onChange={(e, v) => {
                        changeValues(
                          e.target.value,
                          "goods." + m.sku + ".hsCode",
                          showPackingList
                        );
                      }}
                      variant="outlined"
                      fullWidth
                      size="small"
                      defaultValue={m.hsCode}
                    ></TextField>
                  </Grid>
                </Grid>
              );
            })}
          <Grid
            container
            item
            xs={12}
            style={{ borderBottom: "1px solid #000" }}
          >
            <Grid item style={{ borderRight: "1px solid #000" }} xs={2}>
              <Typography style={{ textAlign: "center" }}>TOTAL:</Typography>
            </Grid>
            <Grid item style={{ borderRight: "1px solid #000" }} xs={2}>
              {packingList ? (
                <TextField
                  onChange={(e, v) => {
                    changeValues(e.target.value, "totalPackages");
                  }}
                  variant="outlined"
                  fullWidth
                  size="small"
                  defaultValue={packingList.totalPackages}
                ></TextField>
              ) : null}
            </Grid>
            <Grid item style={{ borderRight: "1px solid #000" }} xs={1}>
              {packingList ? (
                <TextField
                  onChange={(e, v) => {
                    changeValues(e.target.value, "totalWeight");
                  }}
                  variant="outlined"
                  fullWidth
                  size="small"
                  defaultValue={packingList.totalWeight}
                ></TextField>
              ) : null}
            </Grid>
            <Grid item style={{ borderRight: "1px solid #000" }} xs={1}></Grid>
            <Grid item style={{ borderRight: "1px solid #000" }} xs={2}></Grid>
            <Grid item style={{ borderRight: "1px solid #000" }} xs={1}>
              {packingList ? (
                <TextField
                  onChange={(e, v) => {
                    changeValues(e.target.value, "totalQuantity");
                  }}
                  variant="outlined"
                  fullWidth
                  size="small"
                  defaultValue={packingList.totalQuantity}
                ></TextField>
              ) : null}
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item style={{ borderRight: "1px solid #000" }} xs={5}>
              <Typography style={{ fontWeight: "bold" }}>Signature</Typography>
              <TextField
                onChange={(e, v) => {
                  changeValues(e.target.value);
                }}
                variant="outlined"
                fullWidth
                size="small"
                multiline
              ></TextField>
            </Grid>
            <Grid item style={{ borderRight: "1px solid #000" }} xs={2}>
              <Typography style={{ fontWeight: "bold" }}>Date:</Typography>
              <TextField
                onChange={(e, v) => {
                  changeValues(e.target.value);
                }}
                variant="outlined"
                fullWidth
                size="small"
                multiline
              ></TextField>
            </Grid>
            <Grid item xs={5}>
              <Typography style={{ fontWeight: "bold" }}>Remarks</Typography>
              <TextField
                onChange={(e, v) => {
                  changeValues(e.target.value);
                }}
                variant="outlined"
                fullWidth
                size="small"
                multiline
              ></TextField>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={exit} color="primary" variant="outlined" autoFocus>
          {t("no")}
        </Button>
        <Button
          onClick={() => {
            setShowPL(true);

            // setPackingList(true);
          }}
          color="primary"
          variant="contained"
        >
          {t("preview")}
        </Button>
        <Button
          onClick={() => {
            setSubmit(true);
            setShowPL(true);
          }}
          color="primary"
          variant="contained"
        >
          {t("yes")}
        </Button>
      </DialogActions>
      {packingList ? (
        <div
          ref={componentRef}
          style={
            !showPL
              ? { display: "none" }
              : {
                  position: "absolute",
                  top: 2000,
                  width: 1280,
                  padding: 10,
                  paddingTop: 20,
                }
          }
        >
          <Container>
            <Grid
              container
              style={{ border: "1px solid #000", minHeight: 1000 }}
              spacing={1}
            >
              <Grid
                item
                xs={12}
                style={{
                  backgroundColor: "lightgrey",
                  alignContent: "center",
                  justifyContent: "center",
                  borderBottom: "1px solid #000",
                }}
              >
                <Typography
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: 30,
                  }}
                >
                  CTN.FI PACKING LIST
                </Typography>
              </Grid>
              <Grid
                container
                item
                xs={12}
                style={{ borderBottom: "1px solid #000" }}
              >
                <Grid item xs={6} style={{ borderRight: "1px solid #000" }}>
                  <Typography style={{ fontSize: 20, fontWeight: "bold" }}>
                    Exporter/Shipper
                  </Typography>
                  <Typography style={{ fontSize: 20, whiteSpace: "pre-wrap" }}>
                    {packingList.exporter}
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 5 }}>
                  <Typography style={{ fontSize: 20, fontWeight: "bold" }}>
                    Shipping marks. DELIVERY TO:
                  </Typography>
                  {packingList ? (
                    <Typography
                      style={{ fontSize: 20, whiteSpace: "pre-wrap" }}
                    >
                      {packingList.delivery}
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                style={{ borderBottom: "1px solid #000" }}
              >
                <Grid
                  item
                  xs={6}
                  style={{ borderRight: "1px solid #000", minWidth: 200 }}
                >
                  <Typography style={{ fontSize: 20, fontWeight: "bold" }}>
                    Importer/Consignee
                  </Typography>
                  <Typography style={{ fontSize: 20, whiteSpace: "pre-wrap" }}>
                    {packingList.importer}
                  </Typography>
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 5 }}>
                  <Typography style={{ fontSize: 20, fontWeight: "bold" }}>
                    Buyer (if other than consignee - name & address)
                  </Typography>
                  <Typography style={{ fontSize: 20, whiteSpace: "pre-wrap" }}>
                    {packingList.buyer}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                style={{ borderBottom: "1px solid #000" }}
              >
                <Grid item xs={6} style={{ borderRight: "1px solid #000" }}>
                  <Typography style={{ fontSize: 20, fontWeight: "bold" }}>
                    Invoice No.
                  </Typography>
                  {packingList ? (
                    <Typography style={{ fontSize: 20 }}>
                      {packingList.invoiceNo}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{ borderRight: "1px solid #000", paddingLeft: 5 }}
                >
                  <Typography style={{ fontSize: 20, fontWeight: "bold" }}>
                    HS Code
                  </Typography>
                  {packingList ? (
                    <Typography style={{ fontSize: 20 }}>
                      {packingList.hsCode}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid item xs={3} style={{ paddingLeft: 5 }}>
                  <Typography style={{ fontSize: 20, fontWeight: "bold" }}>
                    Other refrence(s)
                  </Typography>
                  {packingList ? (
                    <Typography style={{ fontSize: 20 }}>
                      {packingList.otherRefrences}
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                style={{ borderBottom: "1px solid #000" }}
              >
                <Grid item xs={3} style={{ borderRight: "1px solid #000" }}>
                  <Typography style={{ fontSize: 20, fontWeight: "bold" }}>
                    Port of loading
                  </Typography>
                  {packingList ? (
                    <Typography style={{ fontSize: 20 }}>
                      {packingList.portOfLoading}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{ borderRight: "1px solid #000", paddingLeft: 5 }}
                >
                  <Typography style={{ fontSize: 20, fontWeight: "bold" }}>
                    Port of discharge
                  </Typography>
                  {packingList ? (
                    <Typography style={{ fontSize: 20 }}>
                      {packingList.portOfDischarge}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  xs={3}
                  style={{ borderRight: "1px solid #000", paddingLeft: 5 }}
                >
                  <Typography style={{ fontSize: 20, fontWeight: "bold" }}>
                    Incoterms
                  </Typography>
                  {packingList ? (
                    <Typography style={{ fontSize: 20 }}>
                      {packingList.incoterms}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid item xs={3} style={{ paddingLeft: 5 }}>
                  <Typography style={{ fontSize: 20, fontWeight: "bold" }}>
                    Final destination
                  </Typography>
                  {packingList ? (
                    <Typography style={{ fontSize: 20 }}>
                      {packingList.finalDestination}
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                style={{ borderBottom: "1px solid #000" }}
              >
                {" "}
                <Grid item xs={6} style={{ borderRight: "1px solid #000" }}>
                  <Typography style={{ fontSize: 20, fontWeight: "bold" }}>
                    Country of origin of goods
                  </Typography>
                  {packingList ? (
                    <Typography style={{ fontSize: 20 }}>
                      {packingList.countryOfOrigin}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 5 }}>
                  <Typography style={{ fontSize: 20, fontWeight: "bold" }}>
                    Country of final destination
                  </Typography>
                  {packingList ? (
                    <Typography style={{ fontSize: 20 }}>
                      {packingList.countryOfDestination}
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                style={{ borderBottom: "1px solid #000" }}
              >
                <Grid item style={{ borderRight: "1px solid #000" }} xs={2}>
                  <Typography
                    style={{
                      fontSize: 20,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Marks
                  </Typography>
                </Grid>
                <Grid item style={{ borderRight: "1px solid #000" }} xs={2}>
                  <Typography
                    style={{
                      fontSize: 20,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Number & kinds of packages
                  </Typography>
                </Grid>
                <Grid item style={{ borderRight: "1px solid #000" }} xs={1}>
                  <Typography
                    style={{
                      fontSize: 20,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Gross weight (kg)
                  </Typography>
                </Grid>
                <Grid item style={{ borderRight: "1px solid #000" }} xs={1}>
                  <Typography
                    style={{
                      fontSize: 20,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Net weight (kg)
                  </Typography>
                </Grid>
                <Grid item style={{ borderRight: "1px solid #000" }} xs={2}>
                  <Typography
                    style={{
                      fontSize: 20,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Dimensions L x W x H (cm)
                  </Typography>
                </Grid>
                <Grid item style={{ borderRight: "1px solid #000" }} xs={1}>
                  <Typography
                    style={{
                      fontSize: 20,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Quantity
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    style={{
                      fontSize: 20,
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Description
                  </Typography>
                </Grid>
              </Grid>
              {packingList &&
                packingList?.goods.map((m, i) => {
                  return (
                    <Grid
                      key={i}
                      container
                      item
                      xs={12}
                      style={{ borderBottom: "1px solid #000" }}
                    >
                      <Grid
                        item
                        style={{
                          borderRight: "1px solid #000",
                          minHeight: 50,
                        }}
                        xs={2}
                      >
                        {m.serialNumbers
                          ? m.serialNumbers.map((d, ii) => (
                              <Typography
                                key={ii}
                                style={{
                                  padding: 5,
                                  textAlign: "center",
                                  fontSize: 20,
                                }}
                              >
                                {d}
                              </Typography>
                            ))
                          : ""}
                      </Grid>
                      <Grid
                        item
                        style={{ borderRight: "1px solid #000" }}
                        xs={2}
                      >
                        <Typography
                          style={{
                            padding: 5,
                            textAlign: "center",
                            fontSize: 20,
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          {m.packages}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        style={{ borderRight: "1px solid #000" }}
                        xs={1}
                      >
                        <Typography
                          style={{
                            padding: 5,
                            fontSize: 20,
                            textAlign: "center",
                          }}
                        >
                          {m.weight}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        style={{ borderRight: "1px solid #000" }}
                        xs={1}
                      ></Grid>
                      <Grid
                        item
                        style={{ borderRight: "1px solid #000" }}
                        xs={2}
                      >
                        <Typography
                          style={{
                            textAlign: "center",
                            fontSize: 20,
                            whiteSpace: "pre-wrap",
                            padding: 5,
                          }}
                        >
                          {m.dimensions}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        style={{ borderRight: "1px solid #000" }}
                        xs={1}
                      >
                        <Typography
                          style={{ fontSize: 20, textAlign: "center" }}
                        >
                          {m.count}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography
                          style={{
                            fontSize: 20,
                            textAlign: "center",
                            padding: 5,
                          }}
                        >
                          {m.name}
                        </Typography>
                        <Typography
                          style={{
                            textAlign: "center",
                            fontSize: 20,
                            whiteSpace: "pre-wrap",
                            padding: 5,
                          }}
                        >
                          {m.hsCode}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })}
              <Grid
                container
                item
                xs={12}
                style={{ borderBottom: "1px solid #000" }}
              >
                <Grid
                  item
                  style={{ borderRight: "1px solid #000", minHeight: 75 }}
                  xs={2}
                >
                  <Typography style={{ fontSize: 20, textAlign: "center" }}>
                    TOTAL:
                  </Typography>
                </Grid>
                <Grid item style={{ borderRight: "1px solid #000" }} xs={2}>
                  {packingList ? (
                    <Typography style={{ fontSize: 20, textAlign: "center" }}>
                      {packingList.totalPackages}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid item style={{ borderRight: "1px solid #000" }} xs={1}>
                  {packingList ? (
                    <Typography style={{ fontSize: 20, textAlign: "center" }}>
                      {packingList.totalWeight}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid
                  item
                  style={{ borderRight: "1px solid #000" }}
                  xs={1}
                ></Grid>
                <Grid
                  item
                  style={{ borderRight: "1px solid #000" }}
                  xs={2}
                ></Grid>
                <Grid item style={{ borderRight: "1px solid #000" }} xs={1}>
                  {" "}
                  {packingList ? (
                    <Typography style={{ fontSize: 20, textAlign: "center" }}>
                      {packingList.totalQuantity}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid item xs={3}></Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid
                  item
                  style={{ borderRight: "1px solid #000", minHeight: 200 }}
                  xs={5}
                >
                  <Typography style={{ fontSize: 20, fontWeight: "bold" }}>
                    Signature
                  </Typography>
                </Grid>
                <Grid
                  item
                  style={{ borderRight: "1px solid #000", paddingLeft: 5 }}
                  xs={2}
                >
                  <Typography style={{ fontSize: 20, fontWeight: "bold" }}>
                    Date:
                  </Typography>
                </Grid>
                <Grid item xs={5} style={{ paddingLeft: 5 }}>
                  <Typography style={{ fontSize: 20, fontWeight: "bold" }}>
                    Remarks
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>
      ) : null}
    </Dialog>
  );
}

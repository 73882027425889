import {
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Link,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Title from "../../../Components/Title";
import {
  getLastParameter,
  getToken,
  requestErrorHandler,
  USER_GROUPS,
} from "../../../Utils/Common";
import EnvSettings from "../../../Utils/EnvSettings";
import { useStyles } from "../../../Utils/Styles";

const server = EnvSettings.server;

const user_keys = [
  { key: "userName", editable: false },
  { key: "emailAddress", editable: false },
  { key: "permissionLevel", editable: true },
  { key: "fullName", editable: false },
  { key: "lastLogin", editable: false },
  { key: "disabled", editable: true },
  { key: "CompanyId", editable: true },
];

export default function EditUser(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [user, setUser] = useState();
  const user_id = getLastParameter();
  const [companies, setCompanies] = useState();
  const dispatch = useDispatch();
  const [unedited, setUnedited] = useState();
  const [open, setOpen] = useState(false);

  const getUser = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        user_id: user_id,
      }),
    };
    fetch(server + "/get_user", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          setUser(result.users);
          setUnedited(result.users);
        }
        if (result.error && result.message === "Invalid user") {
          window.location.replace("/cpanel/users");
        }
      });
  };

  const editUser = (remove) => {
    if (JSON.stringify(user) === JSON.stringify(unedited) && !remove) {
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        user: user,
        remove_user: remove,
      }),
    };
    fetch(server + "/edit_user", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          if (remove) {
            window.location.replace("/cpanel/users");
            return;
          }
          window.location.reload();
        }
      });
  };

  const getCompanies = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token: getToken() }),
    };
    fetch(server + "/get_companies", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          setCompanies(result.companies);
        }
      });
  };

  useEffect(getCompanies, []);

  useEffect(getUser, []);

  return (
    <Grid container spacing={3}>
      <Dialog open={open}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              editUser(true);
            }}
          >
            {t("yes")}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setOpen(false);
            }}
          >
            {t("no")}
          </Button>
        </DialogActions>
      </Dialog>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Breadcrumbs>
                <Link color="inherit" href="/cpanel/users">
                  <Typography>{t("users.header")}</Typography>
                </Link>
                <Typography>{t("users.editUser")}</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12}>
              <Title>{t("users.editUser")}</Title>
            </Grid>
            {user &&
              Object.keys(user)
                .filter((k) => user_keys.find((u) => u.key === k))
                .map((u) =>
                  u !== "CompanyId" ? (
                    <Grid key={u + "1"} item sm={3} xs={12}>
                      <TextField
                        value={user[u] || 0}
                        label={u}
                        variant="outlined"
                        fullWidth
                        disabled={!user_keys.find((k) => k.key === u).editable}
                        select={user_keys.find((k) => k.key === u).editable}
                        inputProps={
                          u === "disabled" ? { min: 0, max: 1 } : undefined
                        }
                        onChange={(e, v) => {
                          if (e && e.target) {
                            setUser({ ...user, [u]: Number(e.target.value) });
                          }
                        }}
                      >
                        {u === "permissionLevel"
                          ? Object.keys(USER_GROUPS).map((k) => (
                              <MenuItem key={k} value={USER_GROUPS[k]}>
                                {k}
                              </MenuItem>
                            ))
                          : u === "disabled"
                          ? [0, 1].map((m) => (
                              <MenuItem key={m ? "Yes" : "No"} value={m}>
                                {m ? t("yes") : t("no")}
                              </MenuItem>
                            ))
                          : null}
                      </TextField>
                    </Grid>
                  ) : (
                    <Grid key={u} item sm={3} xs={12}>
                      {companies && companies.length ? (
                        <Autocomplete
                          id="combo-box"
                          options={companies}
                          value={companies.find((c) => c.id === user[u])}
                          getOptionLabel={(option) => option.name}
                          PaperComponent={(props) => (
                            <Paper
                              style={{ border: "2px solid #3d57a5" }}
                              {...props}
                            />
                          )}
                          onChange={(e, v) => {
                            if (v) {
                              setUser({ ...user, [u]: v.id });
                            }
                          }}
                          //style={{ width: 300 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("newUser.company")}
                              variant="outlined"
                            />
                          )}
                        />
                      ) : undefined}
                    </Grid>
                  )
                )}
            <Grid item xs={12}>
              {user ? (
                <>
                  <Button
                    disabled={JSON.stringify(unedited) === JSON.stringify(user)}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      editUser();
                    }}
                  >
                    {t("submit")}
                  </Button>
                  <Button
                    style={{ marginLeft: 10 }}
                    disabled={JSON.stringify(unedited) === JSON.stringify(user)}
                    variant="contained"
                    onClick={() => {
                      setUser(unedited);
                    }}
                  >
                    {t("cancel")}
                  </Button>
                  <Button
                    style={{ marginLeft: 10 }}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      setOpen(true);
                    }}
                  >
                    {t("delete")}
                  </Button>
                </>
              ) : null}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
}

import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import SortableTable from "../../../Components/SortableTable";
import Title from "../../../Components/Title";
import {
  getDeviceType,
  getFromStorage,
  getToken,
  requestErrorHandler,
  saveToStorage,
} from "../../../Utils/Common";
import EnvSettings from "../../../Utils/EnvSettings";
import { useStyles } from "../../../Utils/Styles";

const server = EnvSettings.server;
const DEVICE_SEARCH_ID = "device_search";

async function search(devices, searchValue) {
  var array = devices.filter((device) => {
    return (
      device.serialNumber.toLowerCase().includes(searchValue.toLowerCase()) ||
      device.deviceName?.toLowerCase().includes(searchValue.toLowerCase()) ||
      device.version?.toLowerCase().includes(searchValue.toLowerCase())

      /*(device.deviceType === 1
        ? "medibag".includes(searchValue.toLowerCase())
        : "sensor module".includes(searchValue.toLowerCase())) */
    );
  });
  return array;
}

export default function AdminDevices(props) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [devices, setDevices] = useState();
  const [searchList, setSearchList] = useState();
  const [searchValue, setSearchValue] = useState();
  const [defSearchVal, setDefSearchVal] = useState();
  const dispatch = useDispatch();

  const showDevice = (id) => {
    history.push("/cpanel/devices/" + id.id);
  };

  const showDeviceNewTab = (event, id) => {
    window.open("/cpanel/devices/" + id.id, "_blank");
  };

  useEffect(() => {
    const getDevices = () => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: getToken(),
          requireLCS: false,
        }),
      };
      fetch(server + "/get_admin_devices", requestOptions)
        .then((res) => res.json())
        .then((result) => {
          requestErrorHandler(result, dispatch);
          if (!result.error) {
            setDevices(result.devices);
            setSearchList(result.devices);
          }
        });
    };
    getDevices();
  }, [dispatch]);

  useEffect(() => {
    const filterResults = () => {
      search(devices, searchValue).then((arr) => {
        setSearchList(arr);
      });
    };
    if (devices && searchValue) {
      filterResults();
    }
  }, [searchValue, devices]);

  useEffect(() => {
    var x = getFromStorage(DEVICE_SEARCH_ID);
    setDefSearchVal(x);
    if (x !== null) {
      setSearchValue(x);
    }
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" href="/cpanel/devices/dashboard">
            {t("devices.header")}
          </Link>
          <Typography>{t("search")}</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          {defSearchVal === undefined ? null : (
            <TextField
              autoFocus
              label={t("devices.search")}
              margin="dense"
              type="text"
              fullWidth
              size="medium"
              defaultValue={defSearchVal}
              onChange={(e) => {
                setSearchValue(e.target.value.toLowerCase());
                saveToStorage(DEVICE_SEARCH_ID, e.target.value.toLowerCase());
              }}
            />
          )}
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Title>{t("deviceList.header")}</Title>
          {searchList ? (
            <SortableTable
              headCells={[
                {
                  id: "serialNumber",
                  numeric: false,
                  disablePadding: false,
                  label: t("deviceList.serial"),
                },
                {
                  id: "model",
                  numeric: false,
                  disablePadding: false,
                  label: t("deviceList.model"),
                },
                {
                  id: "version",
                  numeric: false,
                  disablePadding: false,
                  label: t("deviceList.version"),
                },
                {
                  id: "deviceName",
                  numeric: false,
                  disablePadding: false,
                  label: t("deviceList.name"),
                },
                {
                  id: "location",
                  numeric: false,
                  disablePadding: false,
                  label: t("deviceList.location"),
                },
                {
                  id: "macAddress",
                  numeric: false,
                  disablePadding: false,
                  label: "MACAddress",
                },
                {
                  id: "teamviewerId",
                  numeric: false,
                  disablePadding: false,
                  label: "TeamViewerID",
                },
                {
                  id: "deviceType",
                  numeric: false,
                  disablePadding: false,
                  label: t("deviceList.type"),
                },
                {
                  id: "Company.name",
                  numeric: false,
                  disablePadding: false,
                  label: t("deviceList.company"),
                },
              ]}
              defaultSortRow={"serialNumber"}
              handleRowClick={showDevice}
              handleScrollClick={showDeviceNewTab}
              tableData={searchList}
              rowsPerPage={50}
              specialDataFormatting={[
                {
                  id: "deviceType",
                  format: (data, b, con) => {
                    return getDeviceType(con.serialNumber);
                  },
                },
              ]}
            />
          ) : undefined}
        </Paper>
      </Grid>
    </Grid>
  );
}

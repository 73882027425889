import {
  Breadcrumbs,
  CircularProgress,
  Link,
  Paper,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import NoteSection from "../../../Components/NoteSection";
import Title from "../../../Components/Title";
import {
  ECABIN,
  formatTimeWithTZOffset,
  getToken,
  requestErrorHandler,
  resolveAlertType,
} from "../../../Utils/Common";
import EnvSettings from "../../../Utils/EnvSettings";
import { useStyles } from "../../../Utils/Styles";

const server = EnvSettings.server;
export default function Alert(props) {
  const alert_id = props.computedMatch.params.alert_id;
  const [alertobj, setAlert] = useState();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    const getNotifications = () => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token: getToken(), alert_id: alert_id }),
      };
      fetch(server + "/get_alerts", requestOptions)
        .then((res) => res.json())
        .then((result) => {
          requestErrorHandler(result, dispatch);
          if (!result.error) {
            if (result.alerts) {
              setAlert(result.alerts[0]);
            }
          }
        });
    };
    getNotifications();
  }, [alert_id, dispatch]);

  return (
    <Paper className={classes.paper}>
      <Grid container spacing={3}>
        {alertobj ? (
          <React.Fragment>
            <Grid item xs>
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" href="/cpanel/alerts">
                  {t("alertDetails.alerts")}
                </Link>
                <Typography color="textPrimary">{`${t(
                  "alertDetails.alert"
                )}`}</Typography>
              </Breadcrumbs>
            </Grid>
            <Grid item xs={12}>
              <Title>{t("alertDetails.header")}</Title>
            </Grid>
            <Grid item xs={6}>
              {alertobj.message ? (
                <Typography>{alertobj.message}</Typography>
              ) : null}
              <Typography>{resolveAlertType(alertobj)}</Typography>
              <Typography>
                {formatTimeWithTZOffset(alertobj.createdAt, ECABIN)}
              </Typography>
            </Grid>
            {alertobj.Device ? (
              <Grid item xs={12}>
                <Title>{t("alertDetails.deviceHeader")}</Title>
                <Typography>{alertobj.Device.serialNumber}</Typography>
                <Typography>{alertobj.Device.deviceName}</Typography>
              </Grid>
            ) : null}
            {alertobj.SessionId ? (
              <Grid item xs={12}>
                <Link href={"/cpanel/sessions/" + alertobj.SessionId}>
                  {t("showSession")}
                </Link>
              </Grid>
            ) : null}
            <NoteSection
              notes={alertobj.Notes}
              parent={{ alert_id: alert_id }}
              reload={() => {
                window.location.reload();
              }}
            />
          </React.Fragment>
        ) : (
          <CircularProgress />
        )}
      </Grid>
    </Paper>
  );
}

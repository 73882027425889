import { shallowEqual } from "react-redux";
import XToneImage from "../images/220214-CTN_012_alpha_2.png";
import CryoCabinImage from "../images/cryocabin.png";
import ECabinImage from "../images/ecabin_white.png";
import EQCryoImage from "../images/eqcryo.png";
import VetCryoImage from "../images/vetcryo.png";
import XCryoImage from "../images/xcryo_new.jpeg";
import { showPopupSnackbar } from "../redux/actions/snackbarActions";

export const CRYOCABIN = "CRYO°CABIN";
export const XCRYO = "X°CRYO";
export const EQCRYO = "EQ°CRYO";
export const VETCRYO = "VET°CRYO";
export const ECABIN = "E°CABIN";
export const XTONE = "X°TONE";
export const OXYPRO = "OXYPRO";
export const LEDPRO = "LEDPRO";
export const QUATTRO = "MONOLITH QUATTRO";

/**
 * - Identification for permissionlevels
 * - NOTE: Remeber to add these user groups to backend as well
 */
export const USER_GROUPS = {
  ADMIN: 1,
  CLIENT: 2,
  SUPER_USER: 99,
  PRODUCTION: 10,
  SALES: 11,
  CLIENT_MAINTENANCE: 22,
};

export const RETURN_MESSAGES = {
  invalid_token: "Invalid token",
  unauthorised: "Unauthorised",
  ok: "OK",
  success: "Success",
  failed: "Failed",
  invalid_data: "Invalid data",
  error: "Error occurred",
  no_records: "No records found",
  no_device: "No device found",
  token_expired: "Token Expired",
  password_renewal: "Renew password",
  user_not_found: "User Not Found",
  incorrect_old_password: "Old password is incorrect",
};

export const paymentStates = [
  {
    label: "Invoiced",
    value: "invoiced",
  },
  {
    label: "Deposit Completed",
    value: "deposit",
  },
  {
    label: "Fully Completed",
    value: "completed",
  },
  {
    label: "Items Delivered",
    value: "delivered",
  },
  {
    label: "Cancelled",
    value: "cancelled",
  },
];

export const shippingMethods = [
  {
    label: "Air Freight",
    value: "air",
  },
  {
    label: "Sea Freight",
    value: "sea",
  },
  {
    label: "Rail Freight",
    value: "rail",
  },
  {
    label: "Other",
    value: "other",
  },
];

export const checkNewSerialNumber = (data) => {
  data = data.replace(" ", "");
  var val;
  if (data.toLowerCase()[0] === "q") {
    val = parseInt(data.toLowerCase().replace("q", ""));
    if (data.length === 7 && val > 180000 && val < 190000) {
      return;
    }
    return "Quattro Serial number should be in format of Q180000";
  }
  if (data.toLowerCase().includes("xc")) {
    // XCryo
    val = parseInt(data.toLowerCase().replace("xc", ""));
    if (data.length === 8 && val > 180000 && val < 190000) {
      return;
    }
    return "XCryo Serial number should be in format of XC180000";
  }
  if (data.toLowerCase().includes("xt")) {
    // XTone
    val = parseInt(data.toLowerCase().replace("xt", ""));
    if (data.length >= 8 && val > 210000 && val < 2200000) {
      return;
    }
    return "XTone Serial number should be in format of XT2100000";
  }
  if (data.length < 8) {
    return "Incorrect Serial Number Format!";
  }
};

export const checkValidClientObject = (val) => {
  var x = false;
  if (!val) {
    return false;
  }
  try {
    val = JSON.parse(val);
  } catch (error) {}
  Object.values(val).forEach((v) => {
    if (v) {
      x = true;
    }
  });
  return x;
};

export const ticketStates = [
  {
    label: "New",
    value: "new",
  },
  {
    label: "In progress",
    value: "progress",
  },
  {
    label: "Resolved",
    value: "resolved",
  },
];

export const lifeCycleStates = [
  {
    label: "In Production",
    value: "production",
  },
  {
    label: "Ready for Shipment",
    value: "ready",
  },
  {
    label: "Demo",
    value: "demo",
  },
  {
    label: "Dispatched to Client",
    value: "dispatched",
  },
  {
    label: "In Maintenance",
    value: "maintenance",
  },
  {
    label: "Decommissioned",
    value: "decommissioned",
  },
  {
    label: "Missing",
    value: "missing",
  },
  {
    label: "In Storage",
    value: "storaged",
  },
];

export const getLCState = (data) => {
  var d = "";
  lifeCycleStates.forEach((val) => {
    if (val.value === data) {
      d = val.label;
    }
  });
  return d;
};

export const getLCLocation = (data) => {
  var d = "";
  warehouseLocation.forEach((val) => {
    if (val.value === data) {
      d = val.label;
    }
  });
  return d;
};

export const warehouseLocation = [
  {
    label: "Reimax Klaukkala",
    value: "reimax",
  },
  {
    label: "Mailhouse Espoo",
    value: "mailhouse",
  },
  {
    label: "CTN Finland",
    value: "vantaa",
  },
  {
    label: "CTN Estonia",
    value: "estonia",
  },
  {
    label: "CTN Spain",
    value: "spain",
  },
];

/**
 *
 * Expects LifeCycleStates Array Object
 * Uses id as the identifier for the latest LCS
 * @param {Array} data
 */
export const getLatestLifeCycleState = (data) => {
  if (!data) {
    return data;
  }
  if (!data.length) {
    return data;
  }
  return data.reduce(
    (prev, current) => (prev.id > current.id ? prev : current),
    1
  );
};

export const resolveTransferLocation = (element) => {
  if (!element) {
    return element;
  }
  if (
    element.state === "demo" &&
    element.location === "client" &&
    !checkValidClientObject(element.clientInfo)
  ) {
    return "car";
  }
  return element.location;
};

export const transferStates = ["return", "demo", "sold"];

export const clientInfoOptions = [
  { value: "contactName", label: "Contact Name" },
  { value: "companyName", label: "Company Name" },
  { value: "country", label: "Country" },
];

/**
 *
 * @param {Array} data
 * @param {String} device
 * @param {Number} session_type
 * @returns - Formatted array
 */

export const formatChartData = (data, device, session_type, exclude = []) => {
  var retdata = [];
  data.forEach((element, idx) => {
    var obj = {};
    Object.keys(element).forEach((k) => {
      if (k.includes("temp") || k.includes("activeDevices")) {
        if (element[k] !== null && !exclude.includes(k)) {
          Object.assign(obj, { [k]: element[k] });
        }
      } else if (k.includes("pressure") && !exclude.includes(k)) {
        if (element.pressure !== null) {
          Object.assign(obj, { pressure: element.pressure * 10 });
        }
      }
      if (device === ECABIN && session_type === 1) {
        Object.assign(obj, { timeStamp: idx * 5 });
      } else {
        Object.assign(obj, { timeStamp: idx });
      }
    });
    retdata.push(obj);
  });
  return retdata;
};

export const getLogoImage = (data) => {
  if (!data) {
    return;
  }
  if (data.includes("CTN")) {
    return CryoCabinImage;
  } else if (data.includes("XC")) {
    return XCryoImage;
  } else if (data[0] === "H" || data.includes("EQ")) {
    return EQCryoImage;
  } else if (data.includes("VC")) {
    return VetCryoImage;
  } else if (data.includes("EC")) {
    return ECabinImage;
  } else if (data.includes("XT")) {
    return XToneImage;
  }
  return "";
};

export const hslToHex = (h, s, l) => {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0"); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};
/**
 * This function can resolve the keynames for each devices as the temperature slots have different meaning in different devices
 * @param {String} keyname
 * @param {String} device
 * @returns {String}
 */

export const getMeasurementName = (keyname, device) => {
  if (keyname.includes("timeStamp")) return "Time";
  if (keyname.includes("pressure")) return "Pressure";
  if (device === ECABIN) {
    if (keyname.includes("temp")) {
      var i = getIntegerFromSpelledNumber(keyname.replace("temp", ""));
      if (i <= 6) {
        return "Camera" + i;
      }
      if (i === 7) return "CabinTemp";
      if (i === 8) return "EvaporatorLeftTemp";
      if (i === 9) return "EvaporatorRightTemp";
      if (i === 10) return "CameraAmbientTemp";
    }
  }
  if (device === CRYOCABIN) {
    if (keyname.includes("tempOne")) return "VortexOut";
    if (keyname.includes("tempTwo")) return "VortexIn";
    if (keyname.includes("tempThree")) return "FloodSensor";
  }
  if (device === XCRYO) {
    if (keyname.includes("tempOne")) return "Evaporator";
    if (keyname.includes("tempTwo")) return "Compressor";
  }
  if (device === EQCRYO) {
    if (keyname.includes("tempOne")) return "LeftVortexOut";
    if (keyname.includes("tempTwo")) return "RightVortexOut";
    if (keyname.includes("tempThree")) return "LeftVortexIn";
    if (keyname.includes("tempFour")) return "RightVortexIn";
    if (keyname.includes("tempFive")) return "LeftFloodSensor";
    if (keyname.includes("tempSix")) return "RightFloodSensor";
  }
  return keyname;
};

export const getSessionType = (device, type) => {
  if (device === CRYOCABIN || device === EQCRYO || device === ECABIN) {
    switch (type) {
      case 1:
        return "PRECOOL";
      case 2:
        return "SESSION";
      case 11:
        return "TEST SEQUENCE";
      default:
        break;
    }
  } else if (device === XCRYO) {
    switch (type) {
      case 1:
        return "PRECOOL";
      case 2:
        return "SPOT";
      case 3:
        return "FACE";
      case 4:
        return "PRO";
      case 5:
        return "CUSTOM";
      case 6:
        return "CRYOLIPO";
      default:
        break;
    }
  }
  return "";
};

/**
 * Returns the consumption reference value
 * @param {String} type
 * @returns Integer
 */
export const getConsumptionRefrence = (type) => {
  switch (type) {
    case CRYOCABIN:
      return 5;
    case EQCRYO:
      return 15;
    case VETCRYO:
      return 10;
    default:
      return 5;
  }
};

/**
 * Takes Serial Number
 * @param {String} data
 * @returns DeviceType as String
 */

export const getDeviceType = (data) => {
  if (!data) {
    return "";
  }
  if (data.includes("CTN")) {
    return CRYOCABIN;
  } else if (data.includes("XC")) {
    return XCRYO;
  } else if (data[0] === "H" || data.includes("EQ")) {
    return EQCRYO;
  } else if (data.includes("VC")) {
    return VETCRYO;
  } else if (data.includes("EC") || data.includes("compilerrasp")) {
    return ECABIN;
  } else if (data.includes("XT")) {
    return XTONE;
  } else if (data.includes("OP")) {
    return OXYPRO;
  } else if (data.includes("RLT")) {
    return LEDPRO;
  } else if (data[0] === "Q") {
    return QUATTRO;
  }
  return "";
};

export const saveToStorage = (_id, _value) => {
  sessionStorage.setItem(_id, _value);
};

export const getFromStorage = (_id) => {
  return sessionStorage.getItem(_id) || null;
};

function b64e(str) {
  return btoa(
    encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
      return String.fromCharCode("0x" + p1);
    })
  );
}

function b64d(str) {
  return decodeURIComponent(
    Array.prototype.map
      .call(atob(str), function (c) {
        return "%" + c.charCodeAt(0).toString(16);
      })
      .join("")
  );
}

// return the user data from the session storage
export const getUser = () => {
  try {
    var myObject = sessionStorage.getItem("user");
    return (
      b64d(myObject) &&
      JSON.parse(b64d(myObject), function (key, value) {
        if (key === "permissionlevel" || key === "userId") {
          return parseInt(b64d(this[key]));
        }
        return key ? b64d(this[key]) : this[key];
      })
    );
  } catch (error) {
    removeUserSession();
    window.location.reload();
  }

  // if (userStr) return userStr;
  // else return null;
};

// return the token from the session storage
export const getToken = () => {
  return sessionStorage.getItem("token") || null;
};

// remove the token and user from the session storage
export const removeUserSession = () => {
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("user");
};

// set the token and user from the session storage
export const setUserSession = (token, user) => {
  sessionStorage.setItem("token", token);
  var newObject = {};
  if (!user) {
    return;
  }
  Object.keys(user).forEach(function (value, index) {
    newObject[value] = b64e(user[value]);
  });
  sessionStorage.setItem("user", b64e(JSON.stringify(newObject)));
  // sessionStorage.setItemHash("user", JSON.stringify(user));
};

export const setUserLanguage = (lang) => {
  localStorage.setItem("language", lang);
};

export const getUserLanguage = () => {
  return localStorage.getItem("language");
};

export const checkAdmin = () => {
  return window.location.href.includes("/cpanel");
};

/**
 *
 * @param {Array} requirements list of permissionlevels
 * @returns boolean
 */
export const checkRequiredPermissions = (requirements) => {
  if (requirements && Array.isArray(requirements)) {
    if (getUser().permissionlevel === USER_GROUPS.SUPER_USER) {
      return true;
    }
    return requirements.findIndex((o) => o === getUser().permissionlevel) > -1
      ? true
      : false;
  }
  return false;
};

function formatTimeStamp(time) {
  if (!isNaN(time)) {
    if (time < 1000000000 * 1000) {
      time = time * 1000;
    }
    return new Date(time).toISOString();
  } else if (time.includes(" ")) {
    time = time.replace(" ", "T");
    time = time + ".000Z";
    return time;
  }
  return time;
}

export const formatTimeWithTZOffset = (data, device, time_format) => {
  if (!data) {
    return data;
  }
  if (device !== ECABIN) return data;
  var v = "";
  if (!data.includes("-")) {
    // int
    v = new Date(formatTimeStamp(data)).toISOString();
  } else {
    v = data.replace(" ", "T");
    if (!v.includes("Z")) {
      v = v + ".000Z";
    }
  }
  var val = new Date(
    Date.parse(v) - new Date(v).getTimezoneOffset() * 60 * 1000
  )
    .toISOString()
    .substring(0, 19)
    .replace("T", " ");
  return val;
  //	return v
};
export const getTimestampWithoutOffset = (data) => {
  return new Date(Date.parse(data.replace(" ", "T")))
    .toISOString()
    .replace("T", " ")
    .substring(0, 19);
};

export const validateDateType = (data, format) => {
  if (!data) {
    return false;
  } else {
    if (format === "date") {
      return data.match(/^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/);
    } else {
      return data.match(/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/);
    }
  }
};

export const getLastParameter = () => {
  var data = window.location.href;
  var arr = data.split("/");
  return arr[arr.length - 1];
};

export const formatGoogleDriveMediaLink = (data, type) => {
  if (data && data.includes("drive.google.com")) {
    if (type === "video") {
      // https://drive.google.com/file/d/1i1X8IkOp0C_JlKsbvhgVaETn53XrQ7Ng/view?usp=drive_link
      // https://drive.google.com/file/d/1i1X8IkOp0C_JlKsbvhgVaETn53XrQ7Ng/preview
      return data.split("/view")[0] + "/preview";
    }
    // var st = "https://drive.google.com/uc?export=view&id=";
    var st = "https://lh3.google.com/u/0/d/";
    data = data.split("/d/");
    st = st + data[1].split("/view")[0];
    data = st;
    return data;
  }
  return data;
};

export const getIntegerFromSpelledNumber = (data) => {
  switch (data) {
    case "One":
      return 1;
    case "Two":
      return 2;
    case "Three":
      return 3;
    case "Four":
      return 4;
    case "Five":
      return 5;
    case "Six":
      return 6;
    case "Seven":
      return 7;
    case "Eight":
      return 8;
    case "Nine":
      return 9;
    case "Ten":
      return 10;
    default:
      break;
  }
};

export const numberToWord = (data) => {
  switch (data) {
    case 1:
      return "One";
    case 2:
      return "Two";
    case 3:
      return "Three";
    case 4:
      return "Four";
    case 5:
      return "Five";
    case 6:
      return "Six";
    case 7:
      return "Seven";
    case 8:
      return "Eight";
    case 9:
      return "Nine";
    case 10:
      return "Ten";
    default:
      break;
  }
};

/**
 * Alert formats
 * - First digit tells the general problem
 * - Second digit tells the detail
 *
 * - Example Duration of precool is too long, alert code 11
 * - Example Duration of session is too long? alert code 12
 * - Example Temp sensor 3 is broken alertcode 23
 *
 */
export const ALERT_TYPES = {
  duration: 1,
  broken_sensor: 2,
  failed_session: 3,
  failed_compressor: 4,
  device_transferred: 98,
  checklist_uploaded: 99,
};

export const skipAlertOptions = [
  {
    value: "long_precool",
    label: "Long Precool",
    type: ALERT_TYPES.duration,
  },
  {
    value: "failed_sensor",
    label: "Failed Sensor",
    type: ALERT_TYPES.broken_sensor,
  },
  {
    value: "failed_session",
    label: "Failed Session",
    type: ALERT_TYPES.failed_session,
  },
  {
    value: "failed_compressor",
    label: "Failed Compressor",
    type: ALERT_TYPES.failed_compressor,
  },
];
export const sensorOptions = [
  { value: 1, label: "Camera 1" },
  { value: 2, label: "Camera 2" },
  { value: 3, label: "Camera 3" },
  { value: 4, label: "Camera 4" },
  { value: 5, label: "Camera 5" },
  { value: 6, label: "Camera 6" },
  { value: 7, label: "Cabin Temp" },
  { value: 8, label: "Evaporator Left" },
  { value: 9, label: "Evaporator Right" },
  { value: 10, label: "Camera Ambient" },
];

/**
 * - Accepts object containing alertType
 * - Accept string which is the alertType
 * - Accepts number which is the alertType
 * @param {object} element
 * @param {string} element
 * @param {number} element
 * @returns {string}
 */
export const resolveAlertType = (element) => {
  if (typeof element === "string" || typeof element === "number") {
    return skipAlertOptions.find((o) => o.type === parseInt((element + "")[0]))
      .label;
  }
  return (
    skipAlertOptions.find(
      (o) => o.type === parseInt((element.alertType + "")[0])
    )?.label ?? ""
  );
};

/**
 * - Logs the result if in development
 * - Shows popup message on error
 * - Checks the validity of the post request against known return messages
 * @param {json} result JSON object
 * @param {function} dispatch useDispatch function
 * @param {object} history useHistory object OPTIONAL
 */

export const requestErrorHandler = (result, dispatch, history) => {
  if (process.env.NODE_ENV !== "production") {
    console.log(window.location.href, result);
  }
  // No errors to be handled
  if (!result.error) {
    return;
  }
  dispatch(showPopupSnackbar(result));
  switch (result.message) {
    case RETURN_MESSAGES.token_expired:
      // Logout the user if the token is invalid
      setTimeout(() => {
        removeUserSession();
        window.location.reload();
      }, 3000);
      break;
    case RETURN_MESSAGES.password_renewal:
      // If the password need to be renewed
      if (history) {
        history.push("/renew_password");
      }
      break;
    default:
      break;
  }
};

export const contactObjectSkeleton = {
  contactName: "",
  companyName: "",
  companyAdditionalInfo: "",
  contactAdditionalInfo: "",
  location: "",
  contactId: null,
  companyId: null,
};

/**
 * Reads LCS and returns formatted JSON object
 *
 * @param {JSON} data LCS object
 * @returns {JSON}
 */

export const readClientDataFromLCS = (data) => {
  if (!data || typeof data === "string") {
    return;
  }
  var latest_lcs;
  // Check for formatted data
  if (data.LifeCycleStates && data.LifeCycleStates.length) {
    latest_lcs = getLatestLifeCycleState(data.LifeCycleStates);
  } else if (Object.keys(data).find((k) => k === "clientInfoString")) {
    latest_lcs = data;
  } else if (data.state && data.location) {
    latest_lcs = data;
  }
  // Undefined data structure
  if (!latest_lcs) {
    return;
  }
  var contact = { ...contactObjectSkeleton };
  if (latest_lcs.Company) {
    contact.companyId = latest_lcs.Company.id;
    contact.companyName = latest_lcs.Company.name;
    contact.location = latest_lcs.Company.location;
  }
  if (latest_lcs.Contact) {
    contact.contactName = latest_lcs.Contact.name;
    contact.contactId = latest_lcs.Contact.id;
  }
  // Check if all values empty
  if (!Object.values(contact).find((v) => v) && latest_lcs.clientInfo) {
    if (typeof latest_lcs.clientInfo === "string") {
      try {
        latest_lcs.clientInfo = JSON.parse(latest_lcs.clientInfo);
      } catch (error) {}
    }
    try {
      contact.companyName = latest_lcs.clientInfo?.companyName;
      contact.contactName = latest_lcs.clientInfo?.contactName;
      contact.location = latest_lcs.clientInfo?.country;
    } catch (error) {}
  }
  return contact;
};

export const formatContacts = (data) => {
  var contact = { ...contactObjectSkeleton };
  var arr = [];
  if (data.contacts.length) {
    data.contacts.forEach((element) => {
      var obj = {
        contactName: element.name,
        contactId: element.id,
        ...element.additionalInfo,
      };
      Object.keys(contact).forEach((k) => {
        if (k === "companyAdditionalInfo" && element.Company) {
          obj = { ...obj, ...element.Company.additionalInfo };
        } else if (k.includes("company") && element.Company) {
          obj[k] = element.Company[k.replace("company", "").toLowerCase()];
        } else if (k === "location" && element.Company) {
          obj[k] = element.Company.location;
        }
      });
      arr.push({
        // ...contact,
        ...obj,
      });
    });
  }
  if (data.companies.length) {
    data.companies.forEach((element) => {
      var obj = {
        // ...contact,
        companyName: element.name,
        companyId: element.id,
        location: element.location,
        ...element.additionalInfo,
      };
      element.Contacts.forEach((co) => {
        obj.contactId = co.id;
        obj.contactName = co.name;
        obj = { ...obj, ...co.additionalInfo };
        if (!arr.find((o) => shallowEqual(o, obj))) {
          arr.push(JSON.parse(JSON.stringify(obj)));
        }
      });
      if (!arr.find((o) => shallowEqual(o, obj))) {
        arr.push(JSON.parse(JSON.stringify(obj)));
      }
    });
  }
  return arr;
};

/**
 * Expects object with keys contactId, companyId, companyName,contactName and location
 * @param {JSON} data
 * @param {Array<String>} selectedKeys
 * @returns string
 */

export const formatClientInfoString = (data, selectedKeys) => {
  if (!data) return "";
  var st;
  var _data;
  if (Object.keys(data).length) {
    if (data.companyId) {
      delete data.companyId;
    }
    if (data.contactId) {
      delete data.contactId;
    }
    if (Object.values(data).every((o) => !o)) {
      return "";
    }
    _data = JSON.parse(JSON.stringify(data));
    if (selectedKeys) {
      Object.keys(data).forEach((k) => {
        selectedKeys.includes(k) || delete _data[k];
      });
    }
    st = Object.values(_data).join("/");
    st = st.replace("//", "/");
    if (st[0] === "/") {
      st = st.substring(1, st.length);
    }
    if (st[st.length - 1] === "/") {
      st = st.substring(0, st.length - 1);
    }
    return st;
  }
  var obj = JSON.parse(data);
  if (obj.companyId) {
    delete obj.companyId;
  }
  if (obj.contactId) {
    delete obj.contactId;
  }
  if (Object.values(obj).every((o) => !o)) {
    return "";
  }
  _data = JSON.parse(JSON.stringify(obj));
  if (selectedKeys) {
    Object.keys(obj).forEach((k) => {
      selectedKeys.includes(k) || delete _data[k];
    });
  }
  st = Object.values(_data).join("/");
  st = st.replace("//", "/");
  if (st[0] === "/") {
    st = st.substring(1, st.length);
  }
  if (st[st.length - 1] === "/") {
    st = st.substring(0, st.length - 2);
  }
  return st;
};

export const findValueInText = (data, value) => {
  if (!value) {
    return true;
  }
  if (data && typeof data === "object") {
    return JSON.stringify(data).toLowerCase().includes(value.toLowerCase());
  }
  if (data) {
    return data.toLowerCase().includes(value.toLowerCase());
  }
  return false;
};

export const invoiceIssuers = {
  finland: {
    name: "Cryotech Finland Oy",
    street: "Jaakonkatu 2",
    location: "01620 Vantaa",
    vat: "FI25386878",
  },
  estonia: {
    name: "Cryotech Nordic OU",
    street: "Joa tee 13",
    location: "76903 Harjumaa",
    vat: "EE101634087",
  },
};

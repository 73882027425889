import { Button } from "@material-ui/core";
import Search from "@material-ui/icons/Search";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Client from "../../Views/Dashboard/Client";

export default function DeviceModule() {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <>
      <Button
        startIcon={<Search />}
        variant="outlined"
        style={{ background: "white", marginBottom: 5 }}
        onClick={() => {
          history.push("/cpanel/devices");
        }}
      >
        {t("devices.search")}
      </Button>
      <Client />
    </>
  );
}

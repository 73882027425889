import { Button } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import SortableTable from "../../Components/SortableTable";
import TicketEntry from "../../Components/TicketEntry";
import {
  ECABIN,
  formatTimeWithTZOffset,
  getLastParameter,
  getToken,
  requestErrorHandler,
} from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
const server = EnvSettings.server;

function formatTickets(data) {
  var arr = [];
  data.forEach((d) => {
    arr.push({
      ...d,
      shortdescription:
        d.description && d.description.length > 30
          ? d.description.substring(0, 29) + "..."
          : d.description,
      shortaction:
        d.action && d.action.length > 30
          ? d.action.substring(0, 29) + "..."
          : d.action,
      shortdetails:
        d.details && d.details.length > 30
          ? d.details.substring(0, 29) + "..."
          : d.details,
      state: d.state,
      user: d.User.fullName,
      createdAt: formatTimeWithTZOffset(d.createdAt, ECABIN),
      updatedAt: formatTimeWithTZOffset(d.updatedAt, ECABIN),
      id: d.id,
      description: d.description,
      action: d.action,
      details: d.details,
    });
  });
  return arr;
}

export default function TicketList(props) {
  const { t } = useTranslation();
  const [showTicketEntry, setShowTicketEntry] = useState(false);
  const [refreshList, setRefreshList] = useState("dsfsdfds");
  const [tickets, setTickets] = useState();
  const device_id = getLastParameter();
  const [selectedTicket, setSelectedTicket] = useState();

  const dispatch = useDispatch();

  let handleRowClick = (data) => {
    if (data) {
      setSelectedTicket(data);
      setShowTicketEntry(true);
      // setShowTicketEntry(false);
      // setTimeout(() => {
      //   setShowTicketEntry(true);
      // }, 1);
    }
  };

  const handleScrollClick = (event, id) => {};

  useEffect(() => {
    setTickets();
    const getTickets = () => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: getToken(),
          deviceid: device_id,
        }),
      };
      fetch(server + "/get_tickets", requestOptions)
        .then((res) => res.json())
        .then((result) => {
          requestErrorHandler(result, dispatch);
          if (!result.error) {
            if (result.tickets.length !== 0) {
              setTickets(formatTickets(result.tickets));
            }
          }
        });
    };
    getTickets();
  }, [device_id, dispatch, refreshList]);

  // useEffect(() => {
  //   if (showTicketEntry === false) {
  //     setRefreshList(Date.now());
  //   }
  // }, [setRefreshList, showTicketEntry]);

  return (
    <>
      <Button
        variant="outlined"
        onClick={() => {
          setSelectedTicket();
          setShowTicketEntry(false);
          setTimeout(() => {
            setShowTicketEntry(true);
          }, 1);
        }}
      >
        {t("newTicket")} +
      </Button>
      <TicketEntry
        device_id={device_id}
        showTicketEntry={showTicketEntry}
        setShowTicketEntry={setShowTicketEntry}
        selectedTicket={selectedTicket}
        setSelectedTicket={setSelectedTicket}
        setRefreshList={setRefreshList}
        showHeader={true}
      />
      {tickets ? (
        <SortableTable
          headCells={[
            {
              id: "shortdescription",
              numeric: false,
              disablePadding: false,
              label: t("ticketList.description"),
            },
            {
              id: "shortaction",
              numeric: false,
              disablePadding: false,
              label: t("ticketList.action"),
            },
            {
              id: "shortdetails",
              numeric: false,
              disablePadding: false,
              label: t("ticketList.details"),
            },
            {
              id: "state",
              numeric: false,
              disablePadding: false,
              label: t("ticketList.state"),
            },
            {
              id: "user",
              numeric: false,
              disablePadding: false,
              label: t("ticketList.editor"),
            },
            {
              id: "createdAt",
              numeric: false,
              disablePadding: false,
              label: t("createdAt"),
            },
            {
              id: "updatedAt",
              numeric: false,
              disablePadding: false,
              label: t("updatedAt"),
            },
          ]}
          getRowStyle={(data) => {
            var obj = { cursor: "pointer" };
            if (data.state === "new") {
              return { ...obj, backgroundColor: "lightcoral" };
            }
            if (data.state === "resolved") {
              return { ...obj, backgroundColor: "lightgreen" };
            }
            if (data.state === "progress") {
              return { ...obj, backgroundColor: "lightblue" };
            }
            return obj;
          }}
          defaultSortRow={"updatedAt"}
          handleRowClick={handleRowClick}
          handleScrollClick={handleScrollClick}
          tableData={tickets}
          rowsPerPage={200}
        />
      ) : undefined}
    </>
  );
}

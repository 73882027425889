import { CircularProgress, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import withWidth from "@material-ui/core/withWidth";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import DeviceDialog from "../../Components/DeviceDialog";
import DeviceStatusBadge from "../../Components/DeviceStatusBadge";
import SortableTable from "../../Components/SortableTable";
import Title from "../../Components/Title";
import {
  ECABIN,
  formatClientInfoString,
  formatTimeWithTZOffset,
  getToken,
  getUser,
  readClientDataFromLCS,
  requestErrorHandler,
} from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import { useStyles } from "../../Utils/Styles";

const server = EnvSettings.server;

function DispatchedDevices(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [searchList, setSearchList] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedTransfer, setSelectedTransfer] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    /**
     *
     * @param {array} data List of devices
     * @returns
     */
    const formatTransfer = (data) => {
      var arr = [];
      data.forEach((el) => {
        // Find the element with the dispatch
        var dispatch_element = el.LifeCycleStates.find(
          (o) => o.state === "dispatched" && o.UserId === getUser().userId
        );
        var element = el.LifeCycleStates.reduce((a, b) =>
          a.id > b.id ? a : b
        );
        var obj = {
          createdAt: formatTimeWithTZOffset(dispatch_element.createdAt, ECABIN),
          clientInfoString: formatClientInfoString(
            readClientDataFromLCS(dispatch_element),
            ["contactName", "companyName", "location"]
          ),
          serialNumber: el.serialNumber,
          withClient:
            dispatch_element.id === element.id ||
            (JSON.stringify(element.clientInfo) ===
              JSON.stringify(dispatch_element.clientInfo) &&
              element.location === dispatch_element.location)
              ? t("yes")
              : `${t("returned")}`,
          Company: dispatch_element.Company,
          Contact: dispatch_element.Contact,
        };
        arr.push({
          ...element,
          ...obj,
        });
      });
      return arr;
    };
    const getTransfers = () => {
      setLoading(true);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: getToken(),
        }),
      };
      fetch(server + "/get_personal_dispatches", requestOptions)
        .then((res) => res.json())
        .then((result) => {
          requestErrorHandler(result, dispatch);
          if (!result.error) {
            var a = formatTransfer(result.lcs);
            setSearchList(a);
          }
          setLoading(false);
        })
        .catch((e) => {
          console.error(e);
          setLoading(false);
        });
    };
    getTransfers();
  }, [dispatch, t]);

  const handleClose = () => {
    setSelectedTransfer();
  };

  return (
    <Grid container spacing={3}>
      <DeviceDialog
        data={selectedTransfer}
        handleClose={handleClose}
        header={t("return")}
        quickEditOptions={["return"]}
      />
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Title>{t("mainSideBar.dispatches")}</Title>
          {/* <EditOrder selectedOrder={selectedOrder} /> */}
          {props.width === "xs" && searchList && searchList.length ? (
            searchList
              .sort((a, b) =>
                a.createdAt > b.createdAt
                  ? -1
                  : b.createdAt > a.createdAt
                  ? 1
                  : 0
              )
              .map((val, i) => (
                <DeviceStatusBadge
                  data={val}
                  onClick={setSelectedTransfer}
                  key={i}
                />
              ))
          ) : searchList && searchList.length ? (
            <SortableTable
              headCells={[
                {
                  id: "createdAt",
                  numeric: false,
                  disablePadding: false,
                  label: t("date"),
                },
                {
                  id: "clientInfoString",
                  numeric: false,
                  disablePadding: false,
                  label: t("clientInfo"),
                },
                {
                  id: "serialNumber",
                  numeric: false,
                  disablePadding: false,
                  label: t("serialNumber"),
                },
                {
                  id: "withClient",
                  numeric: false,
                  disablePadding: false,
                  label: t("dispatchedDevices.withClient"),
                },
              ]}
              disablePagination={true}
              defaultSortRow={"createdAt"}
              handleRowClick={(e, v) => {
                if (e.state !== "dispatched") {
                  return;
                }
                setSelectedTransfer(e);
              }}
              // handleScrollClick={showDeviceNewTab,
              tableData={searchList}
              rowsPerPage={200}
            />
          ) : searchList ? (
            <Typography>
              {t("dispatchedDevices.noDispatchedDevices")}
            </Typography>
          ) : loading ? (
            <CircularProgress />
          ) : undefined}
        </Paper>
      </Grid>
    </Grid>
  );
}

export default withWidth()(DispatchedDevices);

import { Paper, TextField, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getToken, requestErrorHandler } from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";

const server = EnvSettings.server;

async function search(stock, searchValue) {
  var array = [];
  if (searchValue.includes(",")) {
    var servallist = searchValue.split(",");
    servallist = servallist.filter((ser) => ser.trim() !== "");
    array = stock.filter((s) => {
      return servallist.some(
        (ser) =>
          s.name.toLowerCase().includes(ser.toLowerCase()) ||
          s.sku.toLowerCase().includes(ser.toLowerCase()) ||
          s.Warehouse?.name.toLowerCase().includes(ser.toLowerCase()) ||
          s.StockItemGroups?.map((ss) =>
            ss.StockItemGroupName.name.toLowerCase()
          )
            .join(" ")
            .includes(ser.toLowerCase())
      );
    });
    return array;
  }
  array = stock.filter((s) => {
    return (
      s.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      s.sku.toLowerCase().includes(searchValue.toLowerCase()) ||
      s.Warehouse?.name.toLowerCase().includes(searchValue.toLowerCase()) ||
      s.StockItemGroups?.map((ss) => ss.StockItemGroupName.name.toLowerCase())
        .join(" ")
        .includes(searchValue.toLowerCase())
    );
  });
  return array;
}

export default function StockItemSearch({
  onChange,
  required,
  defaultValue,
  allowNewItems,
  clearOnSelect,
  placeholder,
  includeQuantity,
  includeTags,
  includePacking,
  label,
  filters,
}) {
  const dispatch = useDispatch();
  const [usersList, setUsersList] = useState([]);
  const [loadingStockItems, setLoadingStockItems] = useState(false);
  const [refreshElement, setRefreshElement] = useState("dfafd");
  const { t } = useTranslation();

  const getStockItems = (ser_val) => {
    if (!ser_val) {
      return;
    }
    setLoadingStockItems(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        search_value: ser_val,
        includeQuantity: includeQuantity,
        includeTags: includeTags,
        includePacking: includePacking,
        filters: filters,
      }),
    };
    fetch(server + "/get_stock_items", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          search(result.stock, ser_val).then((arr) => {
            setUsersList(arr);
          });
        }
        setLoadingStockItems(false);
      });
  };

  return (
    <Grid item xs={12}>
      <Autocomplete
        id="users"
        options={[...usersList]}
        fullWidth
        getOptionSelected={(option, value) => option.sku === value.sku}
        getOptionLabel={(option) => option.sku ?? ""}
        loading={loadingStockItems}
        defaultValue={{ sku: defaultValue }}
        freeSolo={allowNewItems ? !!allowNewItems : true}
        key={refreshElement}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label || t("stockModule.name")}
            variant="outlined"
            required={required}
            placeholder={placeholder}
          />
        )}
        PaperComponent={(props) => (
          <Paper style={{ border: "2px solid #3d57a5" }} {...props} />
        )}
        filterOptions={(options, params) => {
          if (!options.length && allowNewItems !== false) {
            return [{ sku: "Add new item" }];
          }
          return options;
        }}
        renderOption={(option, { selected }) => (
          <React.Fragment key={option.sku}>
            <Grid container>
              <Grid item xs={12}>
                <Typography style={{ fontSize: 13 }}>{option.sku}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography style={{ fontSize: 11 }}>{option.name}</Typography>
              </Grid>
              {option.StockItemGroups && option.StockItemGroups.length ? (
                <Grid item xs={12}>
                  <Typography style={{ fontSize: 11 }}>
                    {option.StockItemGroups.map(
                      (m, i) => m.StockItemGroupName.name
                    ).join(", ")}
                  </Typography>
                </Grid>
              ) : null}
            </Grid>
          </React.Fragment>
        )}
        onChange={
          clearOnSelect
            ? (e, v) => {
                onChange(e, v);
                setRefreshElement(Date.now());
              }
            : onChange
        }
        onChangeCapture={(e) => {
          var v;
          if (e.target.value) {
            v = e.target.value.toLowerCase();
          }
          getStockItems(v);
          // getClientInfo(v);
        }}
      />
    </Grid>
  );
}

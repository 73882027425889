import { Grid, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  getLastParameter,
  getToken,
  requestErrorHandler,
} from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
const server = EnvSettings.server;

const formatText = (data) => {
  if (data) {
    var arr = [];
    data.split(",").forEach((element) => {
      arr.push(
        <>
          {element + ","} <br />{" "}
        </>
      );
    });
    return arr;
  }
  return data;
};

export default function RecipeFile(props) {
  const [crashlogs, setCrashlogs] = useState();
  const device_id = getLastParameter();

  const dispatch = useDispatch();

  useEffect(() => {
    const getSessions = () => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: getToken(),
          deviceid: device_id,
        }),
      };
      fetch(server + "/get_recipe_file", requestOptions)
        .then((res) => res.json())
        .then((result) => {
          requestErrorHandler(result, dispatch);
          if (!result.error) {
            if (result.recipe) {
              setCrashlogs(JSON.stringify(result.recipe, undefined, 8));
            }
          }
        });
    };
    getSessions();
  }, [device_id, dispatch]);

  return crashlogs ? (
    <>
      <Grid container spacing={3} style={{ flexDirection: "row" }}>
        <Grid item>
          <Typography>{formatText(crashlogs)}</Typography>
        </Grid>
      </Grid>
    </>
  ) : null;
}

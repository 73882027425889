import { Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import SortableTable from "../../Components/SortableTable";
import Title from "../../Components/Title";
import {
  ECABIN,
  formatTimeWithTZOffset,
  getLastParameter,
  getToken,
  requestErrorHandler,
} from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import { showPopupSnackbar } from "../../redux/actions/snackbarActions";
const server = EnvSettings.server;

export default function DailyLogs(props) {
  const { t } = useTranslation();
  const [logs, setLogs] = useState();
  const device_id = getLastParameter();

  const dispatch = useDispatch();

  let handleRowClick = (data) => {
    if (!(data && data.filename)) return;

    const requestOptions = {
      method: "POST",
      redirect: "follow",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        deviceid: device_id,
        filename: data.filename,
      }),
    };
    fetch(server + "/download_log", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (result.error) {
          dispatch(showPopupSnackbar(result));
        }
        window.location.assign(server + "/execute_download/" + result.file_url);
      });
  };

  useEffect(() => {
    setLogs();
    const getDailyLogs = () => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: getToken(),
          deviceid: device_id,
        }),
      };
      fetch(server + "/get_daily_log_list", requestOptions)
        .then((res) => res.json())
        .then((result) => {
          requestErrorHandler(result, dispatch);
          if (!result.error) {
            if (result.file_list && result.file_list.length !== 0) {
              setLogs(result.file_list);
            }
          }
        });
    };
    getDailyLogs();
  }, [device_id, dispatch]);

  return (
    <>
      <Grid container spacing={3} style={{ flexDirection: "row" }}>
        <Grid item>
          <Title>{t("deviceDetails.dailyLogs")}</Title>
        </Grid>
      </Grid>
      {logs ? (
        <SortableTable
          headCells={[
            {
              id: "filename",
              numeric: false,
              disablePadding: false,
              label: t("dailyLogList.filename"),
            },
            {
              id: "time",
              numeric: false,
              disablePadding: false,
              label: t("dailyLogList.time"),
            },
            {
              id: "size",
              numeric: false,
              disablePadding: false,
              label: t("dailyLogList.size"),
            },
          ]}
          defaultSortRow={"time"}
          handleRowClick={handleRowClick}
          tableData={logs}
          rowsPerPage={90}
          specialDataFormatting={[
            {
              id: "time",
              format: (data) => {
                return formatTimeWithTZOffset(data, ECABIN);
              },
            },
            {
              id: "size",
              format: (data) => {
                return `${data / 1000} kB`;
              },
            },
          ]}
        />
      ) : undefined}
    </>
  );
}

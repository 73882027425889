import { Button, Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import Badge from "@material-ui/core/Badge";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import NotificationsIcon from "@material-ui/icons/Notifications";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getToken, requestErrorHandler } from "../Utils/Common";
import EnvSettings from "../Utils/EnvSettings";
import { useStyles } from "../Utils/Styles";
import { showPopupSnackbar } from "../redux/actions/snackbarActions";
import AlertDialog from "./AlertDialog";

const server = EnvSettings.server;

export default function AlertMenu() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [alerts, setAlerts] = useState([]);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const [dialogAlert, setDialogAlert] = useState();
  const [clearAll, setClearAll] = useState(false);
  const dispatch = useDispatch();
  const [note, setNote] = useState();

  const formatTime = (time) => {
    return time
      ? new Date(Date.parse(time) - new Date().getTimezoneOffset() * 60 * 1000)
          .toISOString()
          .substring(0, 19)
          .replace("T", " ")
      : "";
  };

  const handleAlert = (alert, index, accept) => {
    //    if (alert.alertType === 0) {
    if (accept === undefined) {
      setDialogAlert({ alert: alert, index: index });
      return;
    } else if (accept === false) {
      setDialogAlert(undefined);
      setOpen(false);
      return;
    }
    //    }
    alerts.splice(index, 1);
    setOpen(false);
    setDialogAlert(undefined);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        alert_id: alert.id,
        note: note,
      }),
    };
    fetch(server + "/handle_alert", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          dispatch(showPopupSnackbar(result));
        }
      });
  };

  const handleAllAlerts = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        all_alerts: true,
      }),
    };
    fetch(server + "/handle_alert", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          window.location.reload();
        }
      });
  };

  const getNotifications = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token: getToken(), seen: false }),
    };
    fetch(server + "/get_alerts", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          if (result.alerts) {
            var al = [];
            result.alerts.forEach((a) => {
              var id = al.findIndex(
                (aa) => aa.Device?.serialNumber === a.Device?.serialNumber
              );
              if (id === -1) {
                al.push(a);
              } else {
                if (al[id].count) {
                  al[id].count = al[id].count + 1;
                } else {
                  al[id].count = al[id].count = 2;
                }
              }
            });
            setAlerts(al);
          }
        }
      });
  };

  useEffect(getNotifications, []);

  return (
    <div>
      <IconButton
        color="inherit"
        aria-haspopup="true"
        onClick={(e) => {
          if (alerts.length > 0) {
            setAnchorEl(e.currentTarget);
            setOpen(true);
          }
        }}
      >
        <Badge badgeContent={alerts.length} color="secondary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        {/* <div
          style={{
            position: "sticky",
            top: 0,
            backgroundColor: "white",
            zIndex: 2,
            padding: 5,
          }}
        >
          <Link
            style={{ cursor: "pointer" }}
            onClick={() => {
              setClearAll(true);
            }}
          >
            <Typography style={{ textAlign: "center", fontWeight: "bold" }}>
              {t("alertMenu.clearAll")}
            </Typography>
          </Link>
        </div> */}
        {alerts.length > 0
          ? alerts.map((element, index) => (
              <MenuItem
                className={classes.alertItem}
                style={{ flexDirection: "column" }}
                onClick={() => {
                  handleAlert(element, index);
                }}
                key={element.id}
              >
                <Typography style={{ fontSize: 10 }}>
                  {formatTime(element.updatedAt)}
                </Typography>
                <Typography>{element.Device?.serialNumber}</Typography>
                {element.count ? (
                  <Typography>
                    {"+ " + element.count + " active alerts"}
                  </Typography>
                ) : null}
                <Typography>{element.message}</Typography>
              </MenuItem>
            ))
          : undefined}
      </Menu>
      <Dialog
        open={clearAll}
        onClose={() => {
          setClearAll(false);
        }}
      >
        <DialogTitle>{t("alertMenu.confirmClear")}</DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              handleAllAlerts();
              setClearAll(false);
            }}
          >
            <Typography>{t("yes")}</Typography>
          </Button>
          <Button
            onClick={() => {
              setClearAll(false);
            }}
          >
            <Typography>{t("no")}</Typography>
          </Button>
        </DialogActions>
      </Dialog>
      {dialogAlert ? (
        <AlertDialog
          alertData={dialogAlert}
          handleAnswer={handleAlert}
          setNote={setNote}
        />
      ) : undefined}
    </div>
  );
}

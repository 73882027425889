import { TextField, withWidth } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ContactInfoBadge from "../../Components/Contacts/ContactInfoBadge";
import SortableTable from "../../Components/SortableTable";
import Title from "../../Components/Title";
import {
  checkAdmin,
  findValueInText,
  formatContacts,
  getToken,
  requestErrorHandler,
} from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import { useStyles } from "../../Utils/Styles";

const server = EnvSettings.server;

function Contacts({ width }) {
  const classes = useStyles();
  const history = useHistory();
  const [contactsList, setContactsList] = useState([]);
  const [searchList, setSearchList] = useState();
  const [search, setSearch] = useState();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let getContacts = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token: getToken(), search_value: "" }),
    };
    fetch(server + "/get_contacts", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          setContactsList(formatContacts(result));
        }
      });
  };
  let showCompany = (id) => {
    var type = id.companyId ? "company" : "contact";
    history.push(
      (checkAdmin() ? "/cpanel" : "") +
        "/contacts/" +
        type +
        "/" +
        (id.companyId || id.contactId)
    );
  };

  const handleScrollClick = (event, id) => {
    return;
    // if (window.location.href.includes("/cpanel/")) {
    //   window.open("/cpanel/companies/" + id.id, "_blank");
    // }
  };

  const filterResults = () => {
    if (!contactsList) {
      return;
    }
    setSearchList(
      contactsList.filter((contact) => {
        return (
          findValueInText(contact.name, search) ||
          findValueInText(contact.contactName, search) ||
          findValueInText(contact.companyName, search) ||
          findValueInText(contact.location, search)
        );
      })
    );
  };

  useEffect(getContacts, []);

  useEffect(filterResults, [search, contactsList]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <TextField
            variant="outlined"
            label={t("search")}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          ></TextField>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Title>{t("companyList.header")}</Title>
          {width === "xs" ? (
            searchList && searchList.length ? (
              searchList
                .sort((a, b) =>
                  a.createdAt > b.createdAt
                    ? -1
                    : b.createdAt > a.createdAt
                    ? 1
                    : 0
                )
                .map((val, i) => (
                  <ContactInfoBadge
                    key={i}
                    clientInfoObject={val}
                    onClick={() => {
                      showCompany(val);
                    }}
                    containerStyles={{ marginTop: 10 }}
                  />
                ))
            ) : null
          ) : searchList ? (
            <SortableTable
              headCells={[
                {
                  id: "companyName",
                  numeric: false,
                  disablePadding: false,
                  label: t("contactInfo.companyName"),
                },
                {
                  id: "contactName",
                  numeric: false,
                  disablePadding: false,
                  label: t("contactInfo.contactName"),
                },

                {
                  id: "location",
                  numeric: false,
                  disablePadding: false,
                  label: t("contactInfo.location"),
                },
              ]}
              defaultSortRow={"companyName"}
              handleRowClick={showCompany}
              handleScrollClick={handleScrollClick}
              tableData={searchList}
              rowsPerPage={200}
              defaultOrder={"asc"}
            />
          ) : undefined}
        </Paper>
      </Grid>
    </Grid>
  );
}

export default withWidth()(Contacts);

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Clear from "@material-ui/icons/Clear";
import Delete from "@material-ui/icons/Delete";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  ComposedChart,
  Label,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import DialogAppBar from "../../Components/DialogAppBar";
import Title from "../../Components/Title";
import {
  formatGoogleDriveMediaLink,
  getToken,
  requestErrorHandler,
} from "../../Utils/Common";

import EnvSettings from "../../Utils/EnvSettings";

const server = EnvSettings.server;

function PackageDialog({ pack, setPack, _edit, _setEdit }) {
  const { t } = useTranslation();
  const [obj, setObj] = useState();
  const dispatch = useDispatch();
  const [shippingCrates, setShippingCrates] = useState([]);

  const handleExit = () => {
    setObj();
    setPack();
  };

  useEffect(() => {
    if (pack && Object.keys(pack).length) {
      setObj(pack);
    } else {
      setObj({
        type: "",
        count: 0,
        width: 0,
        height: 0,
        length: 0,
      });
    }
  }, [pack, setObj]);

  useEffect(() => {
    const getCrates = () => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: getToken(),
        }),
      };
      fetch(server + "/get_shipping_crates", requestOptions)
        .then((res) => res.json())
        .then((result) => {
          requestErrorHandler(result, dispatch);
          if (!result.error) {
            setShippingCrates(result.crates);
            // setTagList(result.stock);
          }
        });
    };
    if (!shippingCrates.length) {
      getCrates();
    }
  }, [shippingCrates, setShippingCrates, dispatch]);

  return (
    <Dialog open={!!pack} onExit={handleExit}>
      <DialogTitle>
        {obj && obj.index !== undefined ? t("edit") : t("add")} {t("package")}
      </DialogTitle>
      {obj ? (
        <DialogContent>
          {obj && obj.index === undefined ? (
            <>
              <Typography>{t("stockModule.chooseDefaultCrate")}</Typography>
              <TextField
                variant="outlined"
                select
                fullWidth
                label={t("stockModule.defaultCrates")}
                onChange={(e, f) => {
                  if (e && e.target && e.target.value) {
                    var v = { count: 1 };
                    Object.keys(obj).forEach((k) => {
                      if (e.target.value[k] !== undefined) {
                        v[k] = e.target.value[k];
                      }
                    });
                    setObj();
                    setTimeout(() => {
                      setObj(JSON.parse(JSON.stringify(v)));
                    }, 1);
                  }
                }}
              >
                {shippingCrates.map((d, i) => (
                  <MenuItem key={i} value={d}>
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography>{d.name}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography style={{ color: "grey" }}>
                          {d.description}
                        </Typography>
                      </Grid>
                    </Grid>
                  </MenuItem>
                ))}
              </TextField>
            </>
          ) : null}
          {obj &&
            Object.keys(obj).map((o, i) =>
              o !== "index" ? (
                <TextField
                  key={i}
                  variant="outlined"
                  label={t(o)}
                  defaultValue={obj[o]}
                  fullWidth
                  style={{ marginTop: 10 }}
                  onChange={(e) => {
                    var z = { ...obj, [o]: e.target.value };
                    setObj({ ...z });
                  }}
                />
              ) : null
            )}
        </DialogContent>
      ) : null}
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            setObj();
            setPack();
          }}
        >
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            var v = JSON.parse(JSON.stringify({ ..._edit }));
            if (!v.packages) {
              v.packages = [];
            }
            if (v.packages && obj.index !== undefined) {
              var x = obj.index;
              delete obj.index;
              v.packages[x] = { ...obj };
            } else if (v.packages) {
              v.packages = [...v.packages, { ...obj }];
            } else {
              v.packages = [{ ...obj }];
            }
            _setEdit({ ...v });
            handleExit();
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function GraphDialog({ show, setShow, edit }) {
  const dispatch = useDispatch();
  const [stockChanges, setStockChanges] = useState([]);
  const { t } = useTranslation();
  const theme = useTheme();

  const handleExit = () => {
    setShow(false);
  };

  useEffect(() => {
    const getStockChanges = () => {
      if (!edit || !edit.StockItem || !edit.Warehouse) {
        console.log(edit);
        return;
      }
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: getToken(),
          stock_item_id: edit.StockItem.id,
          warehouse_id: edit.Warehouse.id,
        }),
      };
      fetch(server + "/get_stock_changes", requestOptions)
        .then((res) => res.json())
        .then((result) => {
          requestErrorHandler(result, dispatch);
          if (!result.error) {
            setStockChanges(result.stock);
            // setTagList(result.stock);
          }
        });
    };
    if (show && !stockChanges.length) {
      getStockChanges();
    }
    // eslint-disable-next-line
  }, [show, stockChanges, setStockChanges, dispatch]);

  return stockChanges.length ? (
    <Dialog open={show} onExit={handleExit}>
      <DialogContent>
        <Grid
          item
          xs={12}
          style={{ overflow: "hidden", width: 500, height: 500 }}
        >
          <ResponsiveContainer>
            <ComposedChart
              data={stockChanges
                .sort((a, b) => {
                  return new Date(
                    b.projection || b.createdAt.substring(0, 10)
                  ).getTime() >
                    new Date(
                      a.projection || a.createdAt.substring(0, 10)
                    ).getTime()
                    ? -1
                    : 1;
                })
                .map((s) => {
                  return {
                    timeStamp: s.projection || s.createdAt.substring(0, 10),
                    free: s.free,
                    total: s.projection ? null : s.total,
                    projection: s.projection ? s.total : null,
                  };
                })}
              margin={{
                top: 16,
                right: 16,
                bottom: 0,
                left: 24,
              }}
            >
              <XAxis
                dataKey="timeStamp"
                stroke={theme.palette.text.secondary}
              />
              <YAxis stroke={theme.palette.text.secondary}>
                <Label
                  angle={270}
                  position="left"
                  style={{
                    textAnchor: "middle",
                    fill: theme.palette.text.primary,
                  }}
                >
                  {t("stockModule.stock")}
                </Label>
              </YAxis>
              <Tooltip />
              <Line dot={false} dataKey={"free"} stroke={"red"} connectNulls />
              <Line
                dot={false}
                dataKey={"total"}
                stroke={"blue"}
                connectNulls
              />
              <Line dot={true} dataKey={"projection"} stroke={"black"} />
              {edit && edit.alertQuantity ? (
                <ReferenceLine
                  y={edit.alertQuantity}
                  label={t("stockModule.alertQuantity")}
                  stroke="blue"
                  strokeDasharray="3 3"
                />
              ) : null}
            </ComposedChart>
          </ResponsiveContainer>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={() => {
            setShow(false);
          }}
        >
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
}

export default function EditDialog({ edit, setEdit, addStockQuantity }) {
  const [editedValues, setEditedValues] = useState();
  const [editImgUrl, setEditImgUrl] = useState(false);
  const [editPackage, setEditPackage] = useState();
  const [showChart, setShowChart] = useState(false);
  const dispatch = useDispatch();
  const [tagList, setTagList] = useState([]);
  const [autocompKey, setAutocompKey] = useState("sfsadfas");
  const { t } = useTranslation();

  const getTagNames = (ser_val) => {
    if (!ser_val && ser_val !== "") {
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        search_value: ser_val,
      }),
    };
    fetch(server + "/get_stock_item_group_names", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          setTagList(result.stock);
        }
      });
  };

  const getMap = () => {
    if (editedValues && editedValues?.Tags) {
      return [...editedValues?.Tags];
    }
    return [];
  };

  // Returns true if the values match
  const compareArrays = () => {
    if (
      editedValues &&
      ((editedValues.free && editedValues.free !== String(edit.free)) ||
        (editedValues.total && editedValues.total !== String(edit.total)) ||
        (editedValues.alertQuantity &&
          editedValues.alertQuantity !== String(edit.alertQuantity)) ||
        (editedValues.listPrice &&
          editedValues.listPrice !== String(edit.listPrice)) ||
        (editedValues.packages &&
          JSON.stringify(editedValues.packages) !==
            JSON.stringify(edit?.StockItem?.PackingDetail?.packages)) ||
        (editedValues.hsCodes &&
          JSON.stringify(editedValues.hsCodes) !==
            JSON.stringify(edit?.StockItem?.PackingDetail?.hsCodes)) ||
        (editedValues.weight &&
          editedValues.weight !==
            String(edit?.StockItem?.PackingDetail?.weight)) ||
        editedValues.imageUrl)
    ) {
      return false;
    }
    return (
      JSON.stringify(editedValues?.Tags?.sort((a, b) => (a > b ? -1 : 1))) ===
      JSON.stringify(
        edit.StockItem.StockItemGroups.sort((a, b) =>
          a.StockItemGroupName.name > b.StockItemGroupName.name ? -1 : 1
        ).map((sig) => sig.StockItemGroupName.name)
      )
    );
  };

  const handleExit = () => {
    setEdit();
    setEditedValues();
    setEditImgUrl(false);
  };

  useEffect(() => {
    if (edit) {
      var o = { ...editedValues };
      if (edit?.StockItem.StockItemGroups.length) {
        o = {
          ...o,
          Tags: edit.StockItem.StockItemGroups.map(
            (tt) => tt.StockItemGroupName.name
          ),
        };
      }
      if (edit.StockItem?.PackingDetail?.packages?.length) {
        o = {
          ...o,
          packages: edit.StockItem.PackingDetail.packages,
        };
      }
      setEditedValues({ ...o });
    }
    // eslint-disable-next-line
  }, [edit]);

  return (
    <Dialog open={!!edit} onClose={handleExit} fullScreen>
      <PackageDialog
        pack={editPackage}
        setPack={setEditPackage}
        _edit={editedValues}
        _setEdit={setEditedValues}
      />
      <GraphDialog setShow={setShowChart} show={showChart} edit={edit} />
      {edit ? (
        <DialogContent>
          <DialogAppBar
            header={t("stockModule.editItem")}
            onClose={handleExit}
          />
          <Grid container spacing={2} style={{ marginTop: 60 }}>
            <Grid container direction="row" item xs={12} justify="space-evenly">
              <Typography>
                {edit.Warehouse.name !== "vantaa"
                  ? t("stockModule.onlyVantaa")
                  : ""}
              </Typography>
              <Button
                onClick={() => {
                  setShowChart(true);
                }}
                variant="outlined"
              >
                {t("stockModule.showStockChanges")}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} style={{ minHeight: 350 }}>
              {edit.StockItem.imageUrl && !editImgUrl ? (
                <>
                  <img
                    alt={edit.StockItem.name + "-image"}
                    src={formatGoogleDriveMediaLink(edit.StockItem.imageUrl)}
                    style={{ width: 200 }}
                  />
                  <IconButton
                    onClick={() => {
                      setEditImgUrl(true);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </>
              ) : (
                <TextField
                  variant="outlined"
                  label={"Image URL"}
                  placeholder="Add image url e.g. from Google Drive"
                  fullWidth
                  onChange={(e, v) => {
                    setEditedValues({
                      ...editedValues,
                      imageUrl: e.target.value,
                    });
                  }}
                ></TextField>
              )}
              <Grid item xs={12}>
                <>
                  {editedValues && editedValues?.Tags
                    ? getMap().map((ta, i) => (
                        <Grid key={i} item style={{ margin: "6px" }}>
                          <Button
                            style={{
                              fontSize: 12,
                              textAlign: "center",
                              backgroundColor: "lightgrey",
                              padding: 3,
                              borderRadius: 10,
                              zIndex: 9999,
                            }}
                            onClick={(e) => {
                              setEditedValues({
                                ...editedValues,
                                Tags: editedValues.Tags.filter(
                                  (tt) => tt !== ta
                                ),
                              });
                            }}
                            endIcon={<Clear />}
                          >
                            {ta}
                          </Button>
                        </Grid>
                      ))
                    : null}
                </>
                <Autocomplete
                  id="tags"
                  options={[...tagList]}
                  fullWidth
                  key={autocompKey}
                  onChangeCapture={(e, v) => {
                    getTagNames(e.target.value);
                  }}
                  getOptionSelected={(option, value) =>
                    option.name === value.name
                  }
                  getOptionLabel={(option) => option.name ?? ""}
                  renderInput={(params) => (
                    <TextField {...params} label={"Tags"} variant="outlined" />
                  )}
                  PaperComponent={(props) => (
                    <Paper style={{ border: "2px solid #3d57a5" }} {...props} />
                  )}
                  filterOptions={(options, params) => {
                    if (!options.length && params.inputValue) {
                      return [{ name: params.inputValue }];
                    }
                    return options;
                  }}
                  onChange={(e, v) => {
                    if (v && v.name) {
                      var arr = editedValues?.Tags || [];
                      arr.push(v.name);
                      setEditedValues({ ...editedValues, Tags: [...arr] });
                      setAutocompKey(Date.now());
                    }
                  }}
                  freeSolo={true}
                  renderOption={(option, { selected }) => (
                    <>
                      {option && option.name ? (
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography style={{ fontSize: 13 }}>
                              {option.name}
                            </Typography>
                          </Grid>
                        </Grid>
                      ) : null}
                    </>
                  )}
                />
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={6}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  disabled
                  label={"SKU"}
                  value={edit.StockItem.sku}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  disabled
                  label={t("stockModule.name")}
                  value={edit.StockItem.name}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  disabled
                  label={t("stockModule.warehouse")}
                  value={edit.Warehouse.name}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="number"
                  inputProps={{ min: 0 }}
                  disabled={edit.Warehouse.name !== "vantaa"}
                  label={t("stockModule.free")}
                  defaultValue={edit.free}
                  onChange={(e, v) => {
                    setEditedValues({ ...editedValues, free: e.target.value });
                  }}
                ></TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  disabled={edit.Warehouse.name !== "vantaa"}
                  type="number"
                  label={t("stockModule.total")}
                  inputProps={{ min: 0 }}
                  defaultValue={edit.total}
                  onChange={(e, v) => {
                    setEditedValues({ ...editedValues, total: e.target.value });
                  }}
                ></TextField>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                variant="outlined"
                type="number"
                inputProps={{ min: 0 }}
                label={t("stockModule.listPrice")}
                defaultValue={edit.StockItem.listPrice}
                onChange={(e, v) => {
                  setEditedValues({
                    ...editedValues,
                    listPrice: e.target.value,
                  });
                }}
              ></TextField>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                variant="outlined"
                type="number"
                label={t("stockModule.alertQuantity")}
                inputProps={{ min: 0 }}
                defaultValue={edit.alertQuantity}
                onChange={(e, v) => {
                  setEditedValues({
                    ...editedValues,
                    alertQuantity: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Title>{t("stockModule.shippingInformation")}</Title>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                variant="outlined"
                type="number"
                label={t("stockModule.totalWeight") + " (kg)"}
                inputProps={{ min: 0 }}
                defaultValue={edit.StockItem?.PackingDetail?.weight || 0}
                onChange={(e, v) => {
                  setEditedValues({
                    ...editedValues,
                    weight: e.target.value,
                  });
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                variant="outlined"
                label={t("stockModule.hsCodes")}
                multiline
                defaultValue={edit.StockItem?.PackingDetail?.hsCodes
                  ?.map((h) => h.code + "\n")
                  .join("")}
                placeholder={"00000000\n111111111\netc."}
                onChange={(e, v) => {
                  if (e && e.target && e.target.value) {
                    setEditedValues({
                      ...editedValues,
                      hsCodes: e.target.value
                        .split("\n")
                        .map((hs) => ({ code: hs })),
                    });
                    return;
                  }
                  var x = { ...editedValues, hsCodes: [] };
                  setEditedValues({ ...x });
                }}
              />
            </Grid>

            <Grid container item xs={12} sm={6}>
              {editedValues?.packages
                ? [...(editedValues?.packages || [])].map((p, i) => (
                    <Grid item xs={12} style={{ marginTop: 5 }} key={i}>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          setEditPackage({ ...p, index: i });
                        }}
                      >
                        {i + 1}. {p.type} x {p.count}
                      </Button>
                      <IconButton
                        onClick={() => {
                          setEditedValues({
                            ...editedValues,
                            // eslint-disable-next-line
                            packages: editedValues.packages.filter((f, ii) => {
                              if (ii !== i) return f;
                            }),
                          });
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Grid>
                  ))
                : null}
              <Grid item xs={12} style={{ marginTop: 5 }}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setEditPackage({});
                  }}
                >
                  {t("stockModule.addPackageDimensions")}
                </Button>
              </Grid>
            </Grid>
            <Grid item>
              <Button
                disabled={compareArrays()}
                variant="contained"
                color="primary"
                onClick={() => {
                  addStockQuantity({
                    ...edit,
                    free: isNaN(editedValues.free)
                      ? edit.free
                      : editedValues.free,
                    total: isNaN(editedValues.total)
                      ? edit.total
                      : editedValues.total,
                    alertQuantity: isNaN(editedValues.alertQuantity)
                      ? edit.alertQuantity
                      : editedValues.alertQuantity,
                    imageUrl: editedValues.imageUrl ?? edit.imageUrl,
                    listPrice: editedValues.listPrice ?? edit.listPrice,
                    Tags: editedValues.Tags
                      ? editedValues.Tags.map((ta) => ta.trim())
                      : undefined,
                    packages: editedValues.packages,
                    hsCodes: editedValues.hsCodes,
                    weight: editedValues.weight,
                  });
                }}
              >
                {t("submit")}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      ) : null}
    </Dialog>
  );
}

import { Paper, Tab } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CRYOCABIN,
  ECABIN,
  USER_GROUPS,
  getDeviceType,
  getUser,
} from "../../Utils/Common";
import AlertList from "./AlertList";
import Checklist from "./Checklist";
import CrashLogs from "./CrashLogList";
import CryoCabinSessionPresets from "./CryoCabinSessionPresets";
import DailyLogs from "./DailyLogList";
import DeviceOverview from "./DeviceOverview";
import EcabinSessionPresets from "./EcabinSessionPresets";
import RecipeFile from "./RecipeFile";
import SettingsFile from "./SettingsFile";
import TicketList from "./TicketList";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

export default function DetailTabs({ deviceData }) {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const { t } = useTranslation();
  const activeTabs = [
    {
      label: t("deviceDetails.deviceOverview"),
      content: <DeviceOverview deviceData={deviceData} />,
    },
    {
      label: t("deviceDetails.dailyLogs"),
      content: <DailyLogs />,
    },
    {
      label: t("deviceDetails.sessionPresets"),
      content:
        deviceData && getDeviceType(deviceData.serialNumber) === CRYOCABIN ? (
          <CryoCabinSessionPresets />
        ) : deviceData && getDeviceType(deviceData.serialNumber) === ECABIN ? (
          <EcabinSessionPresets />
        ) : null,
    },
    {
      permission: [USER_GROUPS.ADMIN, USER_GROUPS.SUPER_USER],
      label: t("deviceDetails.crashLogs"),
      content: <CrashLogs />,
    },
    {
      label: "Settings.json",
      content: <SettingsFile />,
    },
    {
      label: "Recipe.json",
      content: <RecipeFile />,
    },
    {
      permission: [
        USER_GROUPS.ADMIN,
        USER_GROUPS.CLIENT_MAINTENANCE,
        USER_GROUPS.SUPER_USER,
      ],
      label: t("deviceDetails.maintenanceTickets"),
      content: <TicketList />,
    },
    {
      permission: [USER_GROUPS.ADMIN, USER_GROUPS.SUPER_USER],
      label: "Checklist",
      content: <Checklist />,
    },
    {
      permission: [USER_GROUPS.ADMIN, USER_GROUPS.SUPER_USER],
      label: "Alerts",
      content: <AlertList />,
    },
  ];

  return (
    <Paper style={{ marginTop: 30 }}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          {activeTabs
            .filter(
              (a) =>
                !a.permission ||
                a.permission.find((o) => o === getUser().permissionlevel)
            )
            .map((a, i) => (
              <Tab key={i} label={a.label} {...a11yProps(i)} />
            ))}
        </Tabs>
      </AppBar>
      {activeTabs
        .filter(
          (a) =>
            !a.permission ||
            a.permission.find((o) => o === getUser().permissionlevel)
        )
        .map((a, i) => (
          <TabPanel key={i} value={value} index={i}>
            {a.content}
          </TabPanel>
        ))}
    </Paper>
  );
}

import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  MenuItem,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  CRYOCABIN,
  ECABIN,
  EQCRYO,
  VETCRYO,
  XCRYO,
  findValueInText,
  getDeviceType,
  getFromStorage,
  getLogoImage,
  getToken,
  requestErrorHandler,
  saveToStorage,
} from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import { useStyles } from "../../Utils/Styles";

const server = EnvSettings.server;

const DEVICE_SEARCH_ID = "device_search";

export default function Devices(props) {
  const classes = useStyles();
  const [devicesList, setDevicesList] = useState();
  const [searchList, setSearchList] = useState();
  const [searchValue, setSearchValue] = useState();
  const [checkedValues, setCheckedValues] = useState({
    cryocabin: true,
    vetcryo: true,
    xcryo: true,
    eqcryo: true,
    ecabin: true,
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [defSearchVal, setDefSearchVal] = useState();
  const [sortMethod, setSortMethod] = useState({
    key: "serialNumber",
    direction: 1,
  });

  const sortingMethods = [
    {
      label: t("serialNumber"),
      key: "serialNumber",
      direction: 1,
    },
    {
      label: t("companyName"),
      key: "Company.name",
      direction: 1,
    },
  ];

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  const filterResults = () => {
    if (!devicesList || (!searchValue && !checkedValues)) {
      return;
    }
    setSearchList(
      devicesList.filter((device) => {
        if (
          !checkedValues[
            getDeviceType(device.serialNumber).replace("°", "").toLowerCase()
          ]
        ) {
          return false;
        }
        return (
          findValueInText(device.serialNumber, searchValue) ||
          findValueInText(device.deviceName, searchValue) ||
          findValueInText(device.Company.name, searchValue)
        );
      })
    );
  };

  const handleChecked = (event) => {
    setCheckedValues({
      ...checkedValues,
      [event.target.name]: event.target.checked,
    });
  };

  useEffect(() => {
    const getDevicesData = () => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ token: getToken() }),
      };
      fetch(server + "/get_devices", requestOptions)
        .then((res) => res.json())
        .then((result) => {
          requestErrorHandler(result, dispatch);
          if (!result.error) {
            var arr = result.company.Devices;
            if (result.company.EndCustomer) {
              result.company.EndCustomer.forEach((element) => {
                arr.push(...element.Devices);
                if (element.EndCustomer) {
                  element.EndCustomer.forEach((el) => {
                    arr.push(...el.Devices);
                  });
                }
              });
            }
            setSearchList(arr);
            setDevicesList(arr);
          }
        });
    };
    getDevicesData();
  }, [dispatch]);

  useEffect(filterResults, [searchValue, devicesList, checkedValues]);

  useEffect(() => {
    var x = getFromStorage(DEVICE_SEARCH_ID);
    setDefSearchVal(x);
    if (x !== null) {
      setSearchValue(x);
    }
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedValues.cryocabin}
                  onChange={handleChecked}
                  name="cryocabin"
                  color="primary"
                />
              }
              label={CRYOCABIN}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedValues.xcryo}
                  onChange={handleChecked}
                  name="xcryo"
                  color="primary"
                />
              }
              label={XCRYO}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedValues.ecabin}
                  onChange={handleChecked}
                  name="ecabin"
                  color="primary"
                />
              }
              label={ECABIN}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedValues.vetcryo}
                  onChange={handleChecked}
                  name="vetcryo"
                  color="primary"
                />
              }
              label={VETCRYO}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkedValues.eqcryo}
                  onChange={handleChecked}
                  name="eqcryo"
                  color="primary"
                />
              }
              label={EQCRYO}
            />
          </FormGroup>
          <Typography>{t("sort")}</Typography>
          <FormGroup row>
            <TextField
              size="small"
              variant="outlined"
              select
              value={sortMethod.key}
              onChange={(e, v) => {
                setSortMethod(
                  sortingMethods.find((o) => o.key === e.target.value)
                );
              }}
            >
              {sortingMethods.map((s) => (
                <MenuItem value={s.key} key={s.key}>
                  {s.label}
                </MenuItem>
              ))}
            </TextField>

            <IconButton
              variant="outlined"
              onClick={() => {
                setSortMethod({
                  ...sortMethod,
                  direction: sortMethod.direction * -1,
                });
              }}
            >
              {sortMethod.direction > 0 ? (
                <ArrowDownwardIcon />
              ) : (
                <ArrowUpwardIcon />
              )}
            </IconButton>
          </FormGroup>
          {defSearchVal === undefined ? null : (
            <TextField
              autoFocus
              label={t("devices.search")}
              margin="dense"
              type="text"
              fullWidth
              size="medium"
              defaultValue={defSearchVal}
              onChange={(e) => {
                setSearchValue(e.target.value.toLowerCase());
                saveToStorage(DEVICE_SEARCH_ID, e.target.value.toLowerCase());
              }}
            />
          )}
        </Paper>
      </Grid>
      {devicesList && sortMethod
        ? searchList
            .sort((a, b) => {
              var arr = sortMethod.key.split(".");
              if (arr.length > 1) {
                var a_value = a[arr[0]];
                var b_value = b[arr[0]];
                for (let index = 1; index < arr.length; index++) {
                  a_value = a_value[arr[index]];
                  b_value = b_value[arr[index]];
                }
                return a_value > b_value
                  ? -1 * sortMethod.direction
                  : sortMethod.direction;
              }
              return a[sortMethod.key] > b[sortMethod.key]
                ? -1 * sortMethod.direction
                : sortMethod.direction;
            })
            .map((row) => (
              <Grid key={row.id} item xs={12} sm={6} md={3}>
                <Paper
                  onClick={() => {
                    history.push("/devices/" + row.id);
                  }}
                  className={fixedHeightPaper}
                >
                  <Typography component="h1" variant="h6">
                    {getDeviceType(row.serialNumber)}
                  </Typography>
                  <Typography>{row.Company.name}</Typography>
                  <Typography>{row.serialNumber}</Typography>
                  <Typography>{row.deviceName}</Typography>
                  <img
                    alt="Device"
                    src={getLogoImage(row.serialNumber)}
                    style={{
                      maxWidth: 100,
                      maxHeight: 100,
                      objectFit: "contain",
                    }}
                  />
                </Paper>
              </Grid>
            ))
        : undefined}
    </Grid>
  );
}

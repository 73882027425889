import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import EN from "./translations/en.json";
import FI from "./translations/fi.json";
import { getUserLanguage, setUserLanguage } from "./Utils/Common";

const formatLanguage = (lang) => {
  if (lang) {
    if (lang.includes("-")) {
      var l = lang.split("-");
      return l[0];
    }
  }
  return lang;
};

const languageDetector = {
  type: "languageDetector",
  async: true,
  detect: (callback) => {
    callback(
      formatLanguage(
        getUserLanguage() || navigator.language || navigator.userLanguage
      )
    );
  },
  init: () => {},
  cacheUserLanguage: (lng, options) => {
    setUserLanguage(lng);
  },
};

const resources = {
  fi: {
    translation: FI, // import('./translations/fi.json')
  },
  en: {
    translation: EN, // import('./translations/en.json')
  },
};

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    resources,
    // lng: 'en',
    fallbackLng: "en",
    debug: process.env.NODE_ENV !== "production" ? true : false,
    //    keySeparator: true,
    interpolation: {
      escapeValue: false,
    },
    //    detection: detectorOptions
  });

export default i18n;

import {
  CircularProgress,
  Dialog,
  DialogContent,
  Link,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ControlledSelect from "../../Components/ControlledSelect";
import DeviceSearchAutocomplete from "../../Components/DeviceSearchAutocomplete";
import DialogAppBar from "../../Components/DialogAppBar";
import SortableTable from "../../Components/SortableTable";
import Title from "../../Components/Title";
import {
  CRYOCABIN,
  ECABIN,
  XCRYO,
  XTONE,
  checkAdmin,
  checkNewSerialNumber,
  formatClientInfoString,
  formatTimeWithTZOffset,
  getDeviceType,
  getLCLocation,
  getLCState,
  getLatestLifeCycleState,
  getToken,
  lifeCycleStates,
  readClientDataFromLCS,
  requestErrorHandler,
  warehouseLocation,
} from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import { useStyles } from "../../Utils/Styles";
import { showPopupSnackbar } from "../../redux/actions/snackbarActions";
import Checklist from "../DeviceDetails/Checklist";
import LifeCycleState from "../DeviceDetails/LifeCycleState";
import DeviceDataForm from "./DeviceDataForm";
import ProductionPhotos from "./ProductionPhotos";
const server = EnvSettings.server;

function SelectedDeviceDialog({
  selectedDevice,
  setSelectedDevice,
  searchList,
  setSearchList,
}) {
  const { t } = useTranslation();
  return selectedDevice ? (
    <Dialog
      open={!!selectedDevice}
      onClose={() => {
        setSelectedDevice(undefined);
      }}
      fullScreen
    >
      <DialogContent>
        <DialogAppBar
          header={
            <>
              {" "}
              {selectedDevice.serialNumber}{" "}
              <Link
                style={{ color: "white" }}
                href={
                  (checkAdmin() ? "/cpanel" : "") +
                  "/devices/" +
                  selectedDevice.id
                }
              >
                {"- " + t("viewDevicePage")}
              </Link>
            </>
          }
          onClose={() => {
            setSelectedDevice(undefined);
          }}
        />
        <Grid
          container
          spacing={3}
          style={{
            marginTop: 60,
            marginBottom: 20,
            paddingBottom: 30,
            position: "relative",
          }}
        >
          <Grid item xs={12}>
            <DeviceDataForm
              deviceData={selectedDevice}
              updateLocalDevice={(lcs) => {
                var a = searchList.findIndex(
                  (obj) => obj.id === selectedDevice.id
                );
                var arr = [...searchList];
                setSearchList();
                arr[a] = { ...lcs };
                setSearchList([...arr]);
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ borderTop: "1px solid #c5c5c5", marginTop: 12 }}
          >
            <Checklist device_id={selectedDevice.id} />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ borderTop: "1px solid #c5c5c5", marginTop: 12 }}
          >
            <ProductionPhotos device_id={selectedDevice.id} />
          </Grid>
          <Grid
            item
            xs={12}
            style={{ borderTop: "1px solid #c5c5c5", marginTop: 12 }}
          >
            <LifeCycleState
              deviceData={selectedDevice}
              updateLocalDevice={(lcs) => {
                var a = searchList.findIndex(
                  (obj) => obj.id === selectedDevice.id
                );
                var arr = [...searchList];
                arr[a] = { ...selectedDevice, LifeCycleStates: lcs };
                setSearchList(arr);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  ) : null;
}

function formatDeviceData(data) {
  var arr = [];
  data.forEach((element) => {
    var lc =
      element.LifeCycleStates && element.LifeCycleStates.length
        ? getLatestLifeCycleState(element.LifeCycleStates)
        : undefined;
    arr.push({
      id: element.id,
      serialNumber: element.serialNumber ?? "",
      chassisNumber: element.chassisNumber ?? "",
      plugType: element.plugType ?? "",
      color: element.color ?? "",
      model: element.model ?? "",
      version: element.version ?? "",
      deviceName: element.deviceName ?? "",
      location: element.location ?? "",
      macAddress: element.macAddress ?? "",
      teamviewerId: element.teamviewerId ?? "",
      deviceType: getDeviceType(element.serialNumber),
      companyName: element.Company ? element.Company.name : "",
      user: lc?.User?.fullName ?? "",
      latestTimestamp: lc ? formatTimeWithTZOffset(lc?.createdAt, ECABIN) : "",
      currentLCState: lc ? getLCState(lc?.state) : "",
      currentLCLocation: lc ? getLCLocation(lc?.location) : "",
      billNumber:
        element.Order?.number ??
        (element.Order?.clientInfo ? "TBD" : "Unassigned"),
      clientInfo: lc
        ? formatClientInfoString(readClientDataFromLCS(lc), [
            "location",
            "contactName",
            "companyName",
          ])
        : "",
      note: lc?.Notes?.length ? lc?.Notes[0].text : "",
      LifeCycleStates: element.LifeCycleStates,
    });
  });
  return arr;
}

export default function Production(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [searchList, setSearchList] = useState();
  const [searchValue, setSearchValue] = useState();
  const [lcWarehouse, setLCWarehouse] = useState();
  const [lcState, setLCState] = useState();
  const [selectedDevice, setSelectedDevice] = useState();
  const [currentSearchTerms, setCurrentSearchTerms] = useState();
  const [lcClient, setLCClient] = useState();
  const [loading, setLoading] = useState(false);
  const [acKey, setACKey] = useState("sdfdsfsd");
  const [add, setAdd] = useState(false);
  const [note, setNote] = useState();
  const dispatch = useDispatch();

  const editBoxRef = useRef(null);

  const showDevice = (dev) => {
    setSelectedDevice(dev);
  };

  const submitTransfer = () => {
    if (checkNewSerialNumber(searchValue)) {
      return;
    }
    var transferData = {
      newDevice: searchValue,
      state: lcState,
      location: lcWarehouse,
    };
    if (
      !transferData.newDevice ||
      !transferData.state ||
      !transferData.location
    ) {
      alert("Fill required information");
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        transferData: transferData,
        noteText: note,
      }),
    };
    fetch(server + "/add_transfer", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        // Reload on success
        if (!result.error) {
          dispatch(showPopupSnackbar(result));
          setTimeout(() => {
            window.location.reload();
          }, 1200);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  // eslint-disable-next-line
  const getDevices = (ser_val) => {
    if (!ser_val && !searchValue && !lcWarehouse && !lcState && !lcClient) {
      return;
    }
    setLoading(true);
    setSearchList();
    setSelectedDevice();
    setCurrentSearchTerms({
      SerialNumber: searchValue || ser_val,
      LCState: lcState,
      LCLocation: lcWarehouse,
      LCClient: lcClient,
    });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        search_value: searchValue || ser_val,
        lcState: lcState,
        lcLocation: lcWarehouse,
        lcClient: lcClient,
        requireLCS: false,
      }),
    };
    fetch(server + "/get_admin_devices", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          var a = formatDeviceData(result.devices);
          setSearchList(a);
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    const handleEnterPress = (e) => {
      if (e.key === "Enter") {
        getDevices();
      }
    };

    window.addEventListener("keyup", handleEnterPress);
    return () => {
      window.removeEventListener("keyup", handleEnterPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDevices]);

  return (
    <Grid container spacing={3}>
      <SelectedDeviceDialog
        selectedDevice={selectedDevice}
        setSelectedDevice={setSelectedDevice}
        searchList={searchList}
        setSearchList={setSearchList}
      />
      <Grid item xs={12}>
        <Paper className={classes.paper} style={{ overflow: "hidden" }}>
          <Grid container spacing={3} alignItems="center">
            <Grid item container spacing={3} xs={12}>
              <Grid item xs={12}>
                <Title>{t("mainSideBar.production")}</Title>
              </Grid>
              <Grid item container xs={12}>
                <Grid
                  item
                  style={{
                    border: "1px solid lightgrey",
                    borderRadius: 5,
                    padding: 5,
                    minWidth: 295,
                  }}
                  xs={12}
                  container
                >
                  <Grid item xs={12}>
                    <Typography style={{ color: "grey" }}>
                      {t("quickSelection")}
                    </Typography>
                  </Grid>
                  <Grid item style={{ margin: 5 }}>
                    <Button
                      onClick={() => {
                        setSearchValue();
                        getDevices("ec2");
                      }}
                      variant="outlined"
                    >
                      {ECABIN}
                    </Button>
                  </Grid>
                  <Grid item style={{ margin: 5 }}>
                    <Button
                      onClick={() => {
                        setSearchValue();
                        getDevices("xc");
                      }}
                      variant="outlined"
                    >
                      {XCRYO}
                    </Button>
                  </Grid>
                  <Grid item style={{ margin: 5 }}>
                    <Button
                      onClick={() => {
                        setSearchValue();
                        getDevices("xt");
                      }}
                      variant="outlined"
                    >
                      {XTONE}
                    </Button>
                  </Grid>
                  <Grid item style={{ margin: 5 }}>
                    <Button
                      onClick={() => {
                        setSearchValue();
                        getDevices("ctn");
                      }}
                      variant="outlined"
                    >
                      {CRYOCABIN}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={3} ref={editBoxRef}>
              <DeviceSearchAutocomplete
                onChange={(e, v) => {
                  if (v && typeof v !== "string") {
                    if (v.inputValue && v.inputValue.includes("Add Device")) {
                      var x = checkNewSerialNumber(v.serialNumber);
                      if (x) {
                        alert(x);
                        setACKey(Date.now());
                        return;
                      }
                      setAdd(true);
                      setSearchValue(v.serialNumber);
                      return;
                    }
                    setAdd(false);
                    setSearchValue(v.serialNumber);
                    return;
                  }
                }}
                freeSearchFunction={setSearchValue}
                allowNewDevices={true}
                // This key hack allows to reset the element after bad input
                key={acKey}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <ControlledSelect
                itemlist={warehouseLocation}
                label={t("deviceList.lcLocation")}
                onChange={(e, v) => {
                  if (e) {
                    setLCWarehouse(e.target.value);
                    return;
                  }
                  setLCWarehouse();
                }}
                deleteButton={true}
              />
            </Grid>
            <Grid item container xs={12} sm={3}>
              <ControlledSelect
                itemlist={lifeCycleStates}
                label={t("deviceList.lcState")}
                onChange={(e, v) => {
                  if (e) {
                    setLCState(e.target.value);
                    return;
                  }
                  setLCState();
                }}
                deleteButton={true}
              />
            </Grid>
            <Grid item container xs={12} sm={3}>
              <TextField
                fullWidth
                label={t("clientInfo")}
                variant="outlined"
                onChange={(e) => {
                  if (e.target.value) {
                    setLCClient(e.target.value);
                    return;
                  }
                  setLCClient();
                }}
              ></TextField>
            </Grid>
            {add ? (
              <Grid item container xs={12} sm={3}>
                <TextField
                  fullWidth
                  label={t("note")}
                  variant="outlined"
                  onChange={(e) => {
                    if (e.target.value) {
                      setNote(e.target.value);
                      return;
                    }
                    setNote();
                  }}
                ></TextField>
              </Grid>
            ) : null}
            <Grid item xs={6}>
              {add ? (
                <Button
                  variant="outlined"
                  onClick={() => {
                    submitTransfer();
                  }}
                >
                  {t("add")}
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  onClick={() => {
                    getDevices();
                  }}
                >
                  {t("search")}
                </Button>
              )}
            </Grid>
            <Grid item container spacing={3} xs={12} alignItems="center">
              {currentSearchTerms ? (
                <Grid item xs={12}>
                  <Typography>{t("currentSearchTerms")}:</Typography>
                </Grid>
              ) : null}
              {currentSearchTerms
                ? Object.keys(currentSearchTerms).map((k, i) =>
                    currentSearchTerms[k] ? (
                      <Grid item key={i}>
                        <Typography color="primary">{k}</Typography>
                        <Typography>{currentSearchTerms[k]}</Typography>
                      </Grid>
                    ) : null
                  )
                : null}
              {currentSearchTerms ? (
                <Grid item>
                  {" "}
                  <Button
                    color="secondary"
                    onClick={() => {
                      window.location.reload();
                    }}
                  >
                    {t("clearAll")}
                  </Button>{" "}
                </Grid>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              <Typography color="primary">
                {searchList ? t("foundDevices") : ""}
              </Typography>
              <Typography>{searchList?.length}</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Title>{t("deviceList.header")}</Title>
          {searchList ? (
            <SortableTable
              headCells={[
                {
                  id: "serialNumber",
                  numeric: false,
                  disablePadding: false,
                  label: t("deviceList.serial"),
                },
                {
                  id: "chassisNumber",
                  numeric: false,
                  disablePadding: false,
                  label: t("deviceList.chassisNumber"),
                },
                {
                  id: "plugType",
                  numeric: false,
                  disablePadding: false,
                  label: t("deviceList.plugType"),
                },
                {
                  id: "color",
                  numeric: false,
                  disablePadding: false,
                  label: t("deviceList.color"),
                },
                {
                  id: "model",
                  numeric: false,
                  disablePadding: false,
                  label: t("deviceList.model"),
                },
                {
                  id: "location",
                  numeric: false,
                  disablePadding: false,
                  label: t("deviceList.location"),
                },
                {
                  id: "deviceType",
                  numeric: false,
                  disablePadding: false,
                  label: t("deviceList.type"),
                },
                {
                  id: "user",
                  numeric: false,
                  disablePadding: false,
                  label: t("deviceList.user"),
                },
                {
                  id: "latestTimestamp",
                  numeric: false,
                  disablePadding: false,
                  label: t("deviceList.timeStamp"),
                },
                {
                  id: "currentLCState",
                  numeric: false,
                  disablePadding: false,
                  label: t("deviceList.lcState"),
                },
                {
                  id: "currentLCLocation",
                  numeric: false,
                  disablePadding: false,
                  label: t("deviceList.lcLocation"),
                },
                {
                  id: "clientInfo",
                  numeric: false,
                  disablePadding: false,
                  label: t("clientInfo"),
                },
                {
                  id: "note",
                  numeric: false,
                  disablePadding: false,
                  label: t("note"),
                },
              ]}
              // disablePagination: true,
              defaultSortRow={"serialNumber"}
              handleRowClick={showDevice}
              // handleScrollClick = {showDeviceNewTab,
              header={
                t("mainSideBar.production") +
                "-" +
                new Date().toISOString().substring(0, 10)
              }
              tableData={searchList}
              downloadButton={true}
              rowsPerPage={50}
            />
          ) : loading ? (
            <CircularProgress />
          ) : undefined}
        </Paper>
      </Grid>
    </Grid>
  );
}

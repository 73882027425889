import { Paper } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getToken, requestErrorHandler } from "../Utils/Common";
import EnvSettings from "../Utils/EnvSettings";

const server = EnvSettings.server;

export default function UserSearchAutocomplete({
  onChange,
  userGroup,
  required,
  defaultValue,
}) {
  const dispatch = useDispatch();
  const [usersList, setUsersList] = useState([]);
  const [loadingUsersList, setLoadingUsersList] = useState(false);
  const { t } = useTranslation();

  const getUsers = (ser_val) => {
    if (!ser_val) {
      return;
    }
    setLoadingUsersList(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        search_value: ser_val,
        userGroup: userGroup,
      }),
    };
    fetch(server + "/get_user_list", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          setUsersList(result.users);
        }
        setLoadingUsersList(false);
      });
  };
  return (
    <Grid item xs={12}>
      <Autocomplete
        id="users"
        options={[...usersList]}
        fullWidth
        getOptionSelected={(option, value) =>
          option.fullName === value.fullName
        }
        PaperComponent={(props) => (
          <Paper style={{ border: "2px solid #3d57a5" }} {...props} />
        )}
        getOptionLabel={(option) => option.fullName ?? ""}
        loading={loadingUsersList}
        defaultValue={{ fullName: defaultValue }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("name")}
            variant="outlined"
            required={required}
          />
        )}
        onChange={onChange}
        onChangeCapture={(e) => {
          var v;
          if (e.target.value) {
            v = e.target.value.toLowerCase();
          }
          getUsers(v);
          // getClientInfo(v);
        }}
      />
    </Grid>
  );
}

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  withWidth,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import AcceptIcon from "@material-ui/icons/Done";
import EditIcon from "@material-ui/icons/Edit";
import SearchIcon from "@material-ui/icons/Search";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getToken, requestErrorHandler } from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import CountrySearch from "../CountrySearch";
import ContactSearchAutocomplete from "./ContactSearchAutocomplete";

const server = EnvSettings.server;

const contactFields = ["companyName", "contactName", "location"];

function BrowseContactsDialog({ setClientInfoObject, activeObject, width }) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [newContact, setNewContact] = useState(false);
  const [contactSelected, setContactSelected] = useState();
  const [editing, setEditing] = useState();
  const [editedValue, setEditedValue] = useState();
  const [newContactInfo, setNewContactInfo] = useState();
  const dispatch = useDispatch();

  const handleClose = () => {
    setNewContact(false);
    setContactSelected();
    setEditedValue();
    setEditing();
    setNewContactInfo();
    setOpen(false);
  };

  const submit = () => {
    if (editing) {
      alert("Accept changes first");
      return;
    }
    var obj = contactSelected ? contactSelected : newContactInfo;
    if (Object.keys(obj).length === 1 && obj.location) {
      alert("Company Name Required");
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        contactData: contactSelected || newContactInfo,
      }),
    };
    fetch(server + "/edit_contact", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          setClientInfoObject(contactSelected || newContactInfo);
          handleClose();
        }
      });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          handleClose();
        }}
        maxWidth={"lg"}
        fullScreen={width === "xs"}
        fullWidth
      >
        <DialogTitle>{t("clientInfo")}</DialogTitle>
        <DialogContent style={{ minHeight: 500 }}>
          {!newContact && !contactSelected ? (
            <ContactSearchAutocomplete
              field={"name"}
              onChange={(e, v) => {
                if (!v) {
                  return;
                }
                if (v.companyId || v.contactId) {
                  // Contact selected
                  setContactSelected(v);
                } else {
                  setNewContact(true);
                }
              }}
              allowNewContacts={true}
            />
          ) : (
            <Button
              variant="outlined"
              onClick={() => {
                setNewContact();
                setContactSelected();
              }}
              startIcon={<SearchIcon />}
            >
              {t("newSearch")}
            </Button>
          )}
          <Grid container>
            {contactSelected
              ? contactFields.map((c) =>
                  c !== "location" ? (
                    <Grid
                      item
                      sm={6}
                      xs={12}
                      style={{ marginTop: 10 }}
                      key={c}
                      container
                      direction="row"
                    >
                      <Grid item xs={10}>
                        <TextField
                          fullWidth
                          label={t("contactInfo." + c)}
                          variant="outlined"
                          disabled={!(editing === c)}
                          value={
                            editing === c
                              ? editedValue
                              : contactSelected[c] ?? ""
                          }
                          onChange={(e) => {
                            setEditedValue(e.target.value);
                          }}
                          focused={editing === c}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        {editing !== c ? (
                          <IconButton
                            onClick={() => {
                              setEditedValue();
                              setEditing(c);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        ) : (
                          <Grid
                            container
                            style={width === "xs" ? { marginTop: -20 } : {}}
                          >
                            <IconButton
                              onClick={() => {
                                if (editedValue) {
                                  var obj = {
                                    ...contactSelected,
                                    [c]: editedValue,
                                  };
                                  setContactSelected(obj);
                                }
                                setEditing();
                                setEditedValue();
                              }}
                            >
                              <AcceptIcon />
                            </IconButton>{" "}
                            <IconButton
                              onClick={() => {
                                setEditedValue();
                                setEditing();
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  ) : c === "location" && contactSelected?.companyName ? (
                    <Grid
                      item
                      sm={6}
                      xs={12}
                      style={{ marginTop: 10 }}
                      key={c}
                      container
                      direction="row"
                    >
                      <Grid item xs={10}>
                        <CountrySearch
                          disabled={!(editing === c)}
                          onChange={(e, v) => {
                            if (v) {
                              setEditedValue(v.country);
                            }
                          }}
                          defaultValue={contactSelected?.location}
                        ></CountrySearch>
                      </Grid>
                      <Grid item xs={2}>
                        {editing !== c ? (
                          <IconButton
                            onClick={() => {
                              setEditedValue();
                              setEditing(c);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        ) : (
                          <Grid
                            container
                            style={width === "xs" ? { marginTop: -20 } : {}}
                          >
                            <IconButton
                              onClick={() => {
                                if (editedValue) {
                                  var obj = {
                                    ...contactSelected,
                                    [c]: editedValue,
                                  };
                                  setContactSelected(obj);
                                }
                                setEditing();
                                setEditedValue();
                              }}
                            >
                              <AcceptIcon />
                            </IconButton>{" "}
                            <IconButton
                              onClick={() => {
                                setEditedValue();
                                setEditing();
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  ) : null
                )
              : null}
            {newContact ? (
              <>
                <Typography>
                  Lisää vain oikeita henkilöitä ja yrityksiä. Jos haluat jättään
                  vain huomiomerkinnän esim. demolaitteen viennin yhteydessä,
                  tee se lisätiedot-kenttään
                </Typography>
                {contactFields.map((c) =>
                  c !== "location" ? (
                    <Grid item sm={6} xs={12} style={{ marginTop: 10 }} key={c}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        label={t("contactInfo." + c)}
                        onChange={(e) => {
                          var obj = {};
                          if (newContactInfo) {
                            obj = { ...newContactInfo, [c]: e.target.value };
                            if (!e.target.value) {
                              delete obj[c];
                            }
                          } else {
                            obj = { [c]: e.target.value };
                          }
                          if (Object.keys(obj).length) {
                            setNewContactInfo(obj);
                          } else {
                            setNewContactInfo();
                          }
                        }}
                      ></TextField>
                    </Grid>
                  ) : c === "location" && newContactInfo?.companyName ? (
                    <Grid item sm={6} xs={12} style={{ marginTop: 10 }} key={c}>
                      <CountrySearch
                        onChange={(e, v) => {
                          if (!v) {
                            return;
                          }
                          var obj = {};
                          if (newContactInfo) {
                            obj = { ...newContactInfo, [c]: v.country };
                            if (!v) {
                              delete obj[c];
                            }
                          } else {
                            obj = { [c]: v.country };
                          }
                          if (Object.keys(obj).length) {
                            setNewContactInfo(obj);
                          } else {
                            setNewContactInfo();
                          }
                        }}
                      ></CountrySearch>
                    </Grid>
                  ) : null
                )}
              </>
            ) : null}
            <Grid item xs={12} style={{ marginTop: 10 }}>
              {newContactInfo || contactSelected ? (
                <Button variant="contained" color="primary" onClick={submit}>
                  OK
                </Button>
              ) : null}
              <Button
                onClick={() => {
                  handleClose();
                }}
                variant="outlined"
              >
                {t("cancel")}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <Button
        fullWidth
        variant="outlined"
        style={{ padding: 14, color: "dimgray" }}
        onClick={() => {
          setOpen(true);
        }}
      >
        {t(activeObject ? "change" : "add") + " " + t("clientInfo")}
      </Button>
    </>
  );
}

export default withWidth()(BrowseContactsDialog);

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { requestErrorHandler } from "../Utils/Common";
import EnvSettings from "../Utils/EnvSettings";
import { showPopupSnackbar } from "../redux/actions/snackbarActions";

const server = EnvSettings.server;

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function validatePassword(pass, newpass, old_pass_req, old_pass) {
  if (!pass || !newpass || (old_pass_req && !old_pass)) {
    return {
      error: true,
      message: "Incomplete form",
    };
  }
  if (pass.length < 8) {
    return {
      error: true,
      message: "Password has to be atleast 8 characters long",
    };
  }
  if (pass !== newpass) {
    return { error: true, message: "Passwords do not match" };
  }
  if (old_pass_req) {
    if (!old_pass) {
      return { error: true, message: "Old password not set" };
    }
  }
  return { error: false, message: "OK" };
}

//
export default function ChangePasswordComponent({ data }) {
  const classes = useStyles();
  const [password, setPassword] = useState();
  const [retype, setRetype] = useState();
  const [oldPassword, setOldPassword] = useState();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const changePassword = (e) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        password: password,
        token: data.token,
        old_password: data.old_password ? oldPassword : undefined,
      }),
    };
    fetch(server + "/change_password", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        dispatch(showPopupSnackbar(result));
        if (!result.error && data.handlePositiveOutcome) {
          data.handlePositiveOutcome();
        }
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    var validation = validatePassword(
      password,
      retype,
      data.old_password,
      oldPassword
    );

    if (validation.error) {
      dispatch(showPopupSnackbar(validation));
      return;
    }
    changePassword();
  };

  return (
    <React.Fragment>
      {data.old_password ? (
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="OldPassword"
          label={t("changePasswordForm.oldPassword")}
          name="OldPassword"
          type="password"
          onChange={(evt) => setOldPassword(evt.target.value)}
        />
      ) : undefined}

      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="Password"
        label={t("changePasswordForm.newPassword")}
        name="Password"
        type="password"
        onChange={(evt) => setPassword(evt.target.value)}
      />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        id="Retype"
        type="password"
        label={t("changePasswordForm.retypePassword")}
        name="Retype"
        onChange={(evt) => setRetype(evt.target.value)}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        onClick={(e) => handleSubmit(e)}
      >
        {t("changePasswordForm.submit")}
      </Button>
    </React.Fragment>
  );
}

import { CircularProgress } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import SortableTable from "../../Components/SortableTable";
import Title from "../../Components/Title";
import { checkAdmin, getToken, requestErrorHandler } from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import { useStyles } from "../../Utils/Styles";

const server = EnvSettings.server;

export default function ThisWeekActivity(props) {
  const [weekActivity, setWeekActivity] = useState();
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const fixedHeightPaper = clsx(classes.paper, classes.dashboardPaper);

  const getThisWeekActivity = (endcustomer) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token: getToken(), endcustomer: endcustomer }),
    };
    fetch(server + "/get_week_activity", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          setWeekActivity(result.sessions);
        }
      });
  };

  useEffect(getThisWeekActivity, []);

  return (
    <Paper className={fixedHeightPaper}>
      <Title>{t("dashboard.sessionsThisWeek")}</Title>
      <Grid container>
        {weekActivity ? (
          <SortableTable
            headCells={[
              {
                id: "Device.serialNumber",
                numeric: false,
                disablePadding: true,
                label: t("sessionList.serialNumber"),
              },
              {
                id: "count",
                numeric: false,
                disablePadding: true,
                label: "Count",
              },
            ]}
            defaultSortRow={"count"}
            handleRowClick={(data) => {
              weekActivity.filter((activity) => {
                if (activity.id === data.id) {
                  history.push(
                    (checkAdmin() ? "/cpanel" : "") +
                      "/devices/" +
                      activity.Device.id
                  );
                }
                return null;
              });
            }}
            tableData={weekActivity}
            rowsPerPage={5}
            disablePagination={true}
            tableSize={"small"}
          />
        ) : (
          <CircularProgress />
        )}
      </Grid>
    </Paper>
  );
}

import React from "react";
import * as ListItems from "./ListItems";

export default function MainSidebarList() {
  return (
    <div>
      {Object.keys(ListItems).map((k, i) =>
        !k.includes("Logout") && !k.includes("Settings")
          ? React.createElement(ListItems[k], { key: i })
          : null
      )}
      {ListItems.Settings()}
      {ListItems.Logout()}
    </div>
  );
}

import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import clsx from "clsx";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkAdmin, getUser } from "../../Utils/Common";
import { useStyles } from "../../Utils/Styles";
import { showSidebar } from "../../redux/actions/sidebarActions";
import AdminSidebarList from "./AdminSidebarList";
import MainListItems from "./MainSidebarList";

export default function Sidebar(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { sidebarOpen } = useSelector((state) => state.sideBar);
  const handleDrawerClose = () => {
    dispatch(showSidebar(false));
    //setOpen(false);
  };
  return getUser() ? (
    <Drawer
      anchor={"left"}
      classes={{
        paper: clsx(
          classes.drawerPaper,
          !sidebarOpen && classes.drawerPaperClose
        ),
      }}
      open={sidebarOpen}
    >
      <div className={classes.toolbarIcon}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>{checkAdmin() ? <AdminSidebarList /> : <MainListItems />}</List>
    </Drawer>
  ) : null;
}

import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import React from "react";
import { useTranslation } from "react-i18next";

import { formatGoogleDriveMediaLink } from "../../Utils/Common";

export default function OrderLine({
  item,
  selectedProducts,
  setSelectedProducts,
  onlyPrint,
  serialNumberAllocation,
  width,
}) {
  const { t } = useTranslation();
  width = onlyPrint ? "" : width;

  const calculatePrice = (value, label, item) => {
    var num = Number(value);
    var it;
    if (label === "count") {
      it = { ...item, count: num };
      setSelectedProducts(
        selectedProducts.map((m) => (m.id === it.id ? it : m))
      );
    }
  };

  return (
    <Grid
      container
      style={{
        borderRadius: 5,
        backgroundColor: "#eeeeee",
        marginTop: 2,
        padding: 5,
      }}
      alignItems="center"
    >
      {onlyPrint || width === "xs" ? null : (
        <>
          <Grid item xs={1} style={{ padding: 5 }}>
            <img
              alt="Item"
              src={formatGoogleDriveMediaLink(item.imageUrl)}
              width={50}
            />
          </Grid>
          <Grid item xs={3} style={{ padding: 5 }}>
            <Typography>{item.sku}</Typography>{" "}
          </Grid>
        </>
      )}
      <Grid
        item
        sm={onlyPrint ? 3 - (serialNumberAllocation ? 1 : 0) : 3}
        xs={12}
        style={width === "xs" ? { marginBottom: 5 } : { padding: 5 }}
      >
        <Typography>{item.name}</Typography>
      </Grid>
      <Grid
        item
        sm={onlyPrint ? 2 : 1}
        xs={4}
        style={width === "xs" ? {} : { padding: 5 }}
      >
        {onlyPrint ? (
          <Typography style={{ textAlign: "right" }}>{item.count}</Typography>
        ) : (
          <TextField
            label={t("count")}
            type="number"
            key={item.id}
            defaultValue={Number(item.count)}
            inputProps={{ min: 0 }}
            variant="outlined"
            onChange={(e) => {
              calculatePrice(e.target.value, "count", item);
            }}
          />
        )}
      </Grid>
      <Grid item sm={1} xs={3} style={{ padding: 5 }}>
        {onlyPrint ? null : (
          <Button
            color="secondary"
            variant="contained"
            size="small"
            onClick={() => {
              calculatePrice(0, "count", item);
              setSelectedProducts([
                ...selectedProducts.filter((d) => d.id !== item.id),
              ]);
            }}
          >
            {t("remove")}
          </Button>
        )}
      </Grid>
    </Grid>
  );
}

import { CircularProgress } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import Title from "../../Components/Title";
import {
  ECABIN,
  XCRYO,
  getDeviceType,
  getToken,
  requestErrorHandler,
} from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import { useStyles } from "../../Utils/Styles";

const server = EnvSettings.server;

function formatConsumptionData(data) {
  var arr = [];
  data.forEach((element) => {
    if (
      getDeviceType(element.Device.serialNumber) !== ECABIN &&
      getDeviceType(element.Device.serialNumber) !== XCRYO
    ) {
      arr.push({
        name: element.Device.serialNumber,
        last: element.last_month_avg ? element.last_month_avg.toFixed(1) : 0,
        this: element.this_month_avg ? element.this_month_avg.toFixed(1) : 0,
      });
    }
  });
  return arr;
}

export default function DeviceConsumtions() {
  const classes = useStyles();
  const [consumptionData, setConsumptionData] = useState();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const fixedHeightPaper = clsx(classes.paper, classes.dashboardPaper);

  let getMonthlyConsumptionAverage = (endcustomer) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token: getToken(), endcustomer: endcustomer }),
    };
    fetch(server + "/get_monthly_consumption", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          setConsumptionData(formatConsumptionData(result.sessions));
        }
      });
  };

  useEffect(getMonthlyConsumptionAverage, []);

  return (
    <Paper className={fixedHeightPaper}>
      <Title>{t("dashboard.deviceConsumptions")}</Title>
      {consumptionData ? (
        <ResponsiveContainer>
          <ComposedChart data={consumptionData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis width={20} />
            <Tooltip
              formatter={(value, name, props) => {
                return [
                  `${value} kg`,
                  name === "this"
                    ? t("deviceConsumptions.thisMonth")
                    : t("deviceConsumptions.lastMonth"),
                ];
              }}
            />
            <Legend
              formatter={(value, entry, index) => {
                if (value === "this") {
                  return (
                    <span style={{ color: entry.color }}>
                      {t("deviceConsumptions.thisMonth")}
                    </span>
                  );
                } else
                  return (
                    <span style={{ color: entry.color }}>
                      {t("deviceConsumptions.lastMonth")}
                    </span>
                  );
              }}
            />
            <Bar dataKey="this" fill="#3d57a5" />
            <Bar dataKey="last" fill="#2b7ab7" />
          </ComposedChart>
        </ResponsiveContainer>
      ) : (
        <CircularProgress />
      )}
    </Paper>
  );
}

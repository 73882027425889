import { Box, CssBaseline } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Copyright from "../../Components/Copyright";
import {
  USER_GROUPS,
  requestErrorHandler,
  setUserSession,
} from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import i18n from "../../i18n";
import cabin_man from "../../images/cabin_man.jpeg";
import cabin_woman from "../../images/cabin_woman.jpeg";
import mask from "../../images/mask.jpeg";
import xcryo_new from "../../images/xcryo_new.jpeg";

const server = EnvSettings.server;

const getRandomImage = () => {
  switch (Math.floor(Math.random() * 3)) {
    case 0:
      return `url(${cabin_man})`;
    case 1:
      return `url(${cabin_woman})`;
    case 2:
      return `url(${mask})`;
    default:
      return `url(${xcryo_new})`;
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: getRandomImage(),
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignInSide() {
  const classes = useStyles();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const redirection = window.location.href.split("login")[1];

  const handleSubmit = (e) => {
    e.preventDefault();
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ username: username, password: password }),
    };
    fetch(server + "/login_verification", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch, history);
        if (!result.error) {
          setUserSession();
          setUserSession(result.token, result.user);
          if (redirection) {
            history.push(redirection);
          }
          // Default cpanel for admins
          else if (result.user.permissionLevel === USER_GROUPS.admin) {
            history.push("/cpanel");
          } else {
            history.push("/dashboard");
          }
        }
      });
  };

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {t("login.signIn")}
          </Typography>
          <form className={classes.form} noValidate>
            <React.Fragment>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="Username"
                label={t("login.username")}
                name="Username"
                autoComplete="Username"
                onChange={(evt) => setUsername(evt.target.value)}
                autoFocus
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label={t("login.password")}
                type="password"
                id="password"
                onChange={(evt) => setPassword(evt.target.value)}
                autoComplete="current-password"
              />
              {/*<FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label="Remember me"
              /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={(e) => handleSubmit(e)}
              >
                {t("login.signIn")}
              </Button>
            </React.Fragment>

            <Grid container>
              <Grid item xs>
                <Link href="/reset_password" variant="body2">
                  {t("login.forgotPassword")}
                </Link>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={() => {
                    i18n.changeLanguage(i18n.language === "en" ? "fi" : "en");
                  }}
                >
                  {i18n.language === "en" ? "Suomeksi" : "In English"}
                </Button>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import React from "react";
import Chart from "../../Components/TemperatureChart";
import { useStyles } from "../../Utils/Styles";

export default function ChartContainer({ chartData }) {
  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  return (
    <Grid item xs={12} style={{ overflow: "hidden" }}>
      <Paper
        className={fixedHeightPaper}
        style={{ height: 300, overflow: "hidden" }}
      >
        {chartData ? <Chart chartData={chartData} /> : undefined}
      </Paper>
    </Grid>
  );
}

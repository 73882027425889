import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import React from "react";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { getMeasurementName } from "../Utils/Common";

export default function DropdownMenu({ callback, header, device, data }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [checked, setChecked] = React.useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
    callback(newChecked);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const formatData = (data) => {
    var obj = {};
    if (!data) {
      return obj;
    }
    Object.entries(data).map(([k, v]) => {
      if (v !== null) {
        if (
          k.includes("temp") ||
          k.includes("pressure") ||
          k.includes("active")
        ) {
          obj[k] = v;
        }
      }
      return null;
    });
    return obj;
  };

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="outlined"
        color="primary"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        {header}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {Object.keys(formatData(data)).map(
          (d, i) =>
            (d.includes("temp") || d.includes("pressure")) && (
              <ListItem
                role={undefined}
                dense
                button
                onClick={handleToggle(d)}
                key={i}
              >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(d) === -1}
                    tabIndex={-1}
                    disableRipple
                  />
                </ListItemIcon>
                <ListItemText primary={getMeasurementName(d, device)} />
              </ListItem>
            )
        )}
      </Menu>
    </div>
  );
}

import { ListItemIcon } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { USER_GROUPS, checkRequiredPermissions } from "../../../Utils/Common";
import { showSidebar } from "../../../redux/actions/sidebarActions";

export default function Contacts() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  // Allowed Uswer groups
  return checkRequiredPermissions([
    USER_GROUPS.ADMIN,
    USER_GROUPS.SALES,
    USER_GROUPS.SUPER_USER,
  ]) ? (
    <ListItem
      button
      onClick={() => {
        history.push("/contacts");
        dispatch(showSidebar(false));
      }}
    >
      <ListItemIcon>
        <AccountCircleIcon />
      </ListItemIcon>
      <ListItemText primary={t("mainSideBar.contacts")} />
    </ListItem>
  ) : null;
}

import { Container, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import generatePDF from "react-to-pdf";
import CTN_logo from "../../images/ctnfi.png";

import {
  getToken,
  getUser,
  invoiceIssuers,
  requestErrorHandler,
} from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import i18n from "../../i18n";
import OrderLine from "./OrderLine";

const server = EnvSettings.server;

export default function InvoicePreviewWindow({
  show,
  submit,
  setSubmit,
  setShow,
  selectedProducts,
  currency,
  totalItems,
  totalPrice,
  clientInfoObject,
  shippingInfo,
  issuer,
  saveContact,
  invoiceLang,
  details,
  noteText,
  vat,
  width,
  orderNumber,
  editedOrder,
}) {
  const componentRef = useRef();
  const { t } = useTranslation();
  const imageRef = useRef();
  const dispatch = useDispatch();
  const downloadPdf = () =>
    generatePDF(
      () => {
        return componentRef.current;
      },
      {
        method: submit ? "build" : "open",
      }
    );

  const submitOrder = (file) => {
    // if (!orderData) {
    //   return;
    // }
    const data = new FormData();
    data.append("token", getToken());
    if (!editedOrder) {
      data.append("clientInfo", JSON.stringify(clientInfoObject));
      data.append("deviceList", JSON.stringify(selectedProducts));
      if (shippingInfo) {
        data.append("shippingInfo", JSON.stringify(shippingInfo));
      }
      data.append("totalPrice", totalPrice);
      data.append("currency", currency);
      data.append("invoiceIssuer", issuer);
      data.append("saveContact", saveContact);
      data.append("noteText", noteText || "");
      data.append("details", details || "");
      data.append("vat", vat);
    } else {
      data.append("orderData", JSON.stringify(editedOrder));
    }
    var v = file.output("blob");
    v = new File([v], (orderNumber || crypto.randomUUID()) + ".pdf", {
      type: "application/pdf",
    });
    data.append("invoice", v);
    const requestOptions = {
      method: "POST",
      body: data,
    };
    fetch(server + (editedOrder ? "/edit_order" : "/add_order"), requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          window.location.reload();
        }
        setSubmit(false);
      });
  };

  useEffect(() => {
    if (show && componentRef.current && imageRef.current) {
      var oldlang = i18n.language;
      if (invoiceLang && invoiceLang !== i18n.language) {
        i18n.changeLanguage(invoiceLang).then(() => {
          downloadPdf().then((pdf) => {
            if (submit) {
              submitOrder(pdf);
            }
            if (invoiceLang !== oldlang) {
              i18n.changeLanguage(oldlang);
            }
          });
          setShow(false);
        });
      } else {
        downloadPdf().then((pdf) => {
          if (submit) {
            submitOrder(pdf);
          }
        });
        setShow(false);
      }
    }
    // eslint-disable-next-line
  }, [show, componentRef, imageRef]);

  return show ? (
    <div
      ref={componentRef}
      style={{ position: "absolute", top: 2000, width: 1280 }}
    >
      <Container>
        <Grid container spacing={3} style={{ paddingTop: 20 }}>
          <Grid item xs={6}>
            <Typography color="primary" style={{ fontSize: 60 }}>
              {t("orders.purchaseOrder")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={6}
            container
            justify="flex-end"
            alignItems="flex-end"
            style={{ maxHeight: 90 }}
          >
            <img
              alt="CTN logo"
              ref={imageRef}
              src={CTN_logo}
              style={{
                height: 45,
                filter:
                  "invert(1) sepia(0.1) saturate(1000) brightness(0.33) hue-rotate(150deg)",
              }}
            ></img>
          </Grid>
          <Grid item xs={12} style={{ borderTop: "1px solid #000" }}>
            <Typography style={{ fontWeight: "bold" }}>ID:</Typography>
            <Typography>{orderNumber}</Typography>
          </Grid>
          <Grid item xs={6}>
            {issuer ? (
              <>
                <Typography style={{ fontWeight: "bold" }}>
                  {t("orders.invoiceIssuer")}:
                </Typography>
                <Typography>{invoiceIssuers[issuer].name}</Typography>{" "}
                <Typography> {invoiceIssuers[issuer].street}</Typography>
                <Typography> {invoiceIssuers[issuer].location}</Typography>
                <Typography> {invoiceIssuers[issuer].vat}</Typography>
                <Typography style={{ whiteSpace: "pre-wrap" }}>
                  {"\n"}
                </Typography>
                <Typography style={{ fontWeight: "bold" }}>
                  {t("createdBy")}:
                </Typography>
                <Typography>{getUser().email}</Typography>
              </>
            ) : null}
          </Grid>
          <Grid item xs={6}>
            <Typography style={{ fontWeight: "bold" }}>
              {t("orders.invoiceTo")}:
            </Typography>
            <Typography>{clientInfoObject.companyName}</Typography>{" "}
            {clientInfoObject.companyAddress
              ? clientInfoObject.companyAddress
                  .split("\n")
                  .map((o, i) =>
                    o.includes(",") ? (
                      o
                        .split(",")
                        .map((v, ii) => <Typography key={ii}>{v}</Typography>)
                    ) : (
                      <Typography key={i}>{o}</Typography>
                    )
                  )
              : null}
            <Typography>VAT ID: {clientInfoObject.companyVatId}</Typography>
            <Typography> </Typography>
            <Typography>{clientInfoObject.contactName}</Typography>{" "}
            <Typography>{clientInfoObject.contactPhone}</Typography>
            <Typography> {clientInfoObject.contactEmail}</Typography>{" "}
            {shippingInfo ? (
              <>
                <Typography style={{ marginTop: 20, fontWeight: "bold" }}>
                  {t("orders.shippingInfo")}:
                </Typography>
                <Typography>{shippingInfo.companyName}</Typography>{" "}
                {shippingInfo.companyAddress
                  ? shippingInfo.companyAddress
                      .split("\n")
                      .map((o, i) =>
                        o.includes(",") ? (
                          o
                            .split(",")
                            .map((v, ii) => (
                              <Typography key={ii}>{v}</Typography>
                            ))
                        ) : (
                          <Typography key={i}>{o}</Typography>
                        )
                      )
                  : null}
                <Typography>
                  {shippingInfo.companyVatId
                    ? "VAT ID:" + shippingInfo.companyVatId
                    : ""}
                </Typography>
                <Typography> </Typography>
                <Typography>{shippingInfo.contactName}</Typography>{" "}
                <Typography>{shippingInfo.contactPhone}</Typography>
                <Typography> {shippingInfo.contactEmail}</Typography>{" "}
              </>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            {details ? (
              <>
                <Typography style={{ fontWeight: "bold" }}>
                  {t("details")}:
                </Typography>
                <Typography>{details}</Typography>
              </>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            {selectedProducts && selectedProducts.length ? (
              <>
                <Grid
                  container
                  style={{ borderTop: "1px solid #000", padding: 5 }}
                >
                  <Grid item xs={3} style={{ padding: 5 }}>
                    {t("description")}
                  </Grid>
                  <Grid item xs={2} style={{ padding: 5, textAlign: "right" }}>
                    {t("orders.numberItems")}
                  </Grid>
                  <Grid item xs={2} style={{ padding: 5, textAlign: "right" }}>
                    {t("orders.unitPrice")}
                  </Grid>
                  <Grid item xs={2} style={{ padding: 5, textAlign: "right" }}>
                    {t("orders.discount") + " %"}
                  </Grid>
                  <Grid item xs={2} style={{ padding: 5, textAlign: "right" }}>
                    {t("orders.unitTotal")}
                  </Grid>
                  <Grid item xs={1}></Grid>
                </Grid>
                {selectedProducts.map((dev, i) => (
                  <OrderLine
                    key={i}
                    item={dev}
                    selectedProducts={selectedProducts}
                    totalItems={totalItems}
                    totalPrice={totalPrice}
                    onlyPrint={true}
                    width={width}
                  />
                ))}
                <Grid
                  container
                  style={{
                    padding: 5,
                    borderTop: "1px solid #000",
                    marginTop: 5,
                  }}
                >
                  <Grid item lg={3} md={1} sm={2} xs={2} style={{ padding: 5 }}>
                    <Typography>{t("orders.totalItems")}</Typography>
                  </Grid>
                  <Grid item xs={2} style={{ padding: 5 }}>
                    <Typography style={{ textAlign: "right" }}>
                      {totalItems}
                    </Typography>
                  </Grid>
                  <Grid item lg={1} md={3} sm={2} xs={2}></Grid>
                  <Grid item md={2} xs={2} style={{ padding: 5 }}>
                    <Typography>{t("orders.totalPrice")}</Typography>
                  </Grid>
                  <Grid item lg={3} xs={2} style={{ padding: 5 }}>
                    <Typography style={{ textAlign: "right" }}>
                      {Number(totalPrice).toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item xs={1} style={{ padding: 5 }}>
                    <Typography>{currency}</Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{
                    padding: 5,
                    borderTop: "1px solid #000",
                    marginTop: 5,
                  }}
                >
                  <Grid item lg={3} md={2} sm={2} xs={2} style={{ padding: 5 }}>
                    <Typography>{t("orders.vat") + " %"}</Typography>
                  </Grid>
                  <Grid item xs={2} style={{ padding: 5 }}>
                    <Typography style={{ textAlign: "right" }}>
                      {vat}%
                    </Typography>
                  </Grid>
                  <Grid item lg={1} md={3} sm={2} xs={2}></Grid>

                  <Grid item md={3} xs={2} style={{ padding: 5 }}>
                    <Typography>
                      {t("orders.totalPrice") + " + " + t("orders.vat")}
                    </Typography>
                  </Grid>
                  <Grid item lg={2} xs={2} style={{ padding: 5 }}>
                    <Typography
                      style={{ textAlign: "right", fontWeight: "bold" }}
                    >
                      {Number(totalPrice * (1 + vat / 100)).toFixed(2)}
                    </Typography>
                  </Grid>
                  <Grid item xs={1} style={{ padding: 5 }}>
                    <Typography>{currency}</Typography>
                  </Grid>
                </Grid>
              </>
            ) : null}
          </Grid>
        </Grid>
      </Container>
    </div>
  ) : null;
}

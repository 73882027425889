import { Breadcrumbs } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import ArrowBack from "@material-ui/icons/ArrowBackIos";
import ArrowForward from "@material-ui/icons/ArrowForwardIos";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import NoteSection from "../../Components/NoteSection";
import {
  checkAdmin,
  formatTimeWithTZOffset,
  getDeviceType,
  getLastParameter,
  getSessionType,
  getToken,
  requestErrorHandler,
} from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import ChartContainer from "./ChartContainer";
import DetailContainer from "./DetailContainer";
const server = EnvSettings.server;

export default function SessionDetails(props) {
  const [chartData, setChartData] = useState();
  const [nextPrev, setNextPrev] = useState();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const session_id = getLastParameter();
  const history = useHistory();

  const getChartData = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        sessionid: session_id,
      }),
    };
    fetch(server + "/get_session_data", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          setChartData(result.session);
        }
      });
  };

  useEffect(getChartData, []);

  useEffect(() => {
    const getNextPrevious = () => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: getToken(),
          sessionid: session_id,
          deviceid: chartData.Device.id,
        }),
      };
      fetch(server + "/get_adjacent_sessions", requestOptions)
        .then((res) => res.json())
        .then((result) => {
          requestErrorHandler(result, dispatch);
          if (!result.error) {
            setNextPrev(result.sessions);
          }
        });
    };

    if (chartData) {
      getNextPrevious();
    }
  }, [chartData, dispatch, session_id]);

  return (
    <Grid container spacing={3}>
      <Grid item xs>
        {chartData ? (
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              color="inherit"
              href={checkAdmin() ? "/cpanel/devices" : "/devices"}
            >
              {t("sessionDetails.devices")}
            </Link>
            <Link
              color="inherit"
              href={`${checkAdmin() ? "/cpanel" : ""}/devices/${
                chartData.Device.id
              }`}
            >
              {chartData ? chartData.Device.serialNumber : undefined}
            </Link>
            <Typography color="textPrimary">{`${
              getSessionType(
                getDeviceType(chartData.Device.serialNumber),
                chartData.type
              ) +
              " " +
              formatTimeWithTZOffset(
                chartData.startTime,
                getDeviceType(chartData.Device.serialNumber)
              )
            }`}</Typography>
          </Breadcrumbs>
        ) : undefined}
      </Grid>
      {nextPrev ? (
        <>
          {nextPrev.session_new ? (
            <Button
              style={{ position: "fixed", top: "40%", left: "0%" }}
              onClick={() => {
                history.push(
                  (checkAdmin() ? "/cpanel" : "") +
                    "/sessions/" +
                    nextPrev.session_new.id
                );
                window.location.reload();
              }}
            >
              <ArrowBack style={{ fontSize: 50 }} />
            </Button>
          ) : null}
          {nextPrev.session_old ? (
            <Button
              style={{
                position: "fixed",
                top: "40%",
                left: "calc(100% - 70px)",
              }}
              onClick={() => {
                history.push(
                  (checkAdmin() ? "/cpanel" : "") +
                    "/sessions/" +
                    nextPrev.session_old.id
                );
                window.location.reload();
              }}
            >
              <ArrowForward style={{ fontSize: 50 }} />
            </Button>
          ) : null}
        </>
      ) : null}
      <ChartContainer chartData={chartData} />
      <DetailContainer chartData={chartData} />
      <NoteSection
        notes={chartData?.Notes}
        reload={() => {
          getChartData();
        }}
        parent={{ sessionid: session_id }}
      />
    </Grid>
  );
}

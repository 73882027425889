import React from "react";
import { USER_GROUPS, getUser } from "../../Utils/Common";
import Client from "./Client";
import Sales from "./Sales";

export default function Dashboard() {
  // return <Client />;
  switch (getUser().permissionlevel) {
    case USER_GROUPS.SALES:
      return <Sales />;
    default:
      return <Client />;
  }
}

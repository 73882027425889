import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch } from "react-redux";
import { getToken, requestErrorHandler } from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import { showPopupSnackbar } from "../../redux/actions/snackbarActions";

const server = EnvSettings.server;

export default function DeviceDataForm({ deviceData, updateLocalDevice }) {
  const [edit, setEdit] = useState(false);
  const [editedData, setEditedData] = useState(deviceData);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const submitChanges = () => {
    if (!deviceData || !editedData || shallowEqual(editedData, deviceData)) {
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        deviceData: editedData,
      }),
    };
    fetch(server + "/edit_device", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          dispatch(showPopupSnackbar(result));
          setEdit(false);
          if (updateLocalDevice) {
            updateLocalDevice(editedData);
          }
        }
      });
  };

  return editedData ? (
    <Grid container spacing={3}>
      {Object.entries(deviceData).map((a, b) =>
        a[0] === "id" ? null : [
            "serialNumber",
            "LifeCycleStates",
            "billNumber",
            "currentLCLocation",
            "currentLCState",
            "latestTimestamp",
            "clientInfo",
            "note",
            "user",
            "companyName",
          ].findIndex((c) => c === a[0]) !== -1 ? null : [
            "plugType",
            "color",
            "chassisNumber",
          ].findIndex((c) => c === a[0]) !== -1 ? (
          <Grid item xs={12} sm={3} key={b}>
            <TextField
              fullWidth
              label={t("deviceList." + a[0])}
              defaultValue={a[1]}
              variant="outlined"
              color="primary"
              disabled={!edit}
              focused={edit}
              onChange={(e) => {
                var obj;
                if (e.target.value && e.target.value !== "") {
                  obj = { ...editedData, [a[0]]: e.target.value };
                } else {
                  obj = { ...editedData, [a[0]]: null };
                }
                setEditedData(obj);
              }}
            />
          </Grid>
        ) : (
          <Grid item xs={12} sm={3} key={b}>
            <TextField
              fullWidth
              label={t("deviceList." + a[0])}
              defaultValue={a[1]}
              variant="outlined"
              disabled
            />
          </Grid>
        )
      )}
      <Grid item xs={12}>
        <Button
          variant="outlined"
          onClick={() => {
            if (edit) {
              setEditedData({ ...deviceData });
            }
            setEdit(!edit);
          }}
        >
          {edit ? t("cancel") : t("edit")}
        </Button>
        {!edit || shallowEqual(editedData, deviceData) ? null : (
          <Button variant="contained" color="primary" onClick={submitChanges}>
            {t("save")}
          </Button>
        )}
      </Grid>
    </Grid>
  ) : null;
}

import { CircularProgress } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  CartesianGrid,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import Title from "../../Components/Title";
import { getToken, hslToHex } from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import { useStyles } from "../../Utils/Styles";

const server = EnvSettings.server;

function formatChartData(data, number_of_lines = 20) {
  var arr = [];
  var months = [];
  data.forEach((element, index) => {
    if (
      months.filter((r) => {
        return r === element.st;
      }).length === 0
    ) {
      var obj = { month: element.st };
      var arr2 = data.filter((run) => {
        return run.st === obj.month;
      });
      arr2.forEach((el) => {
        if (!obj[el.Device.serialNumber]) {
          obj = { ...obj, [el.Device.serialNumber]: el.count };
        }
      });
      months.push(element.st);
      var sortable = Object.fromEntries(
        Object.entries(obj).sort(([, a], [, b]) => b - a)
      );
      Object.keys(sortable).forEach((k, i) => {
        if (i > number_of_lines) {
          sortable[k] = undefined;
        }
      });
      // console.log(JSON.stringify(sortable));
      arr.push(sortable);
      obj = {};
    }
  });
  return arr;
}

export default function DeviceActivityRate(props) {
  const classes = useStyles();
  const [chartData, setChartData] = useState();
  // const [numberOfLines, setNumberOfLines] = useState(30);
  const numberOfLines = 30;
  const { t } = useTranslation();
  const ref = useRef();

  const getData = (endcustomer) => {
    var date = new Date().setMonth(new Date().getMonth() - 5);
    date = new Date(date).setDate(1);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        endcustomer: endcustomer,
        date: { startDate: new Date(date).toISOString().substring(0, 11) },
        types: [2, 3, 4, 5, 6],
        groupby: "device",
        duration: 60,
      }),
    };
    fetch(server + "/get_sessions", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        if (!result.error) {
          setChartData(formatChartData(result.sessions, numberOfLines));
        }
      });
  };

  useEffect(getData, [numberOfLines]);

  const getLines = () => {
    var arr = [];
    var obj = {};
    chartData.forEach((data) => {
      obj = { ...obj, ...data };
    });
    Object.keys(obj).map((key, index) =>
      key !== "month"
        ? arr.push(
            <Line
              key={index}
              dataKey={key}
              stroke={hslToHex((360 / chartData.length) * index, 100, 30)}
            />
          )
        : null
    );
    return arr;
  };
  const getTooltipX = () => {
    if (window.innerHeight < 500) {
      return 200;
    }
    return Math.round(window.innerWidth / 2);
  };
  const getTooltipY = (obj) => {
    if (!obj || !obj.length) {
      return 0;
    }
    var objects_number = Object.keys(obj[0]).length;
    var val =
      window.innerHeight -
      (Math.round(window.innerHeight / 2) + 50 + objects_number * 15);
    return val > 10 ? val : 10;
  };

  const fixedHeightPaper = clsx(classes.paper, classes.dashboardPaper);
  return (
    <Paper className={fixedHeightPaper}>
      <Title>
        {t("dashboard.deviceActivityRate") + " - TOP " + numberOfLines}
      </Title>
      {chartData ? (
        <ResponsiveContainer ref={ref}>
          <ComposedChart data={chartData} margin={{ left: 20 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <Tooltip
              isAnimationActive={false}
              position={{
                x: getTooltipX(),
                y: getTooltipY(chartData),
              }}
              itemSorter={(item) => {
                return parseInt(item.value) * -1;
              }}
              wrapperStyle={{ position: "fixed", zIndex: 9999 }}
            />
            <XAxis dataKey="month" />
            <YAxis width={20} />
            {getLines()}
          </ComposedChart>
        </ResponsiveContainer>
      ) : (
        <CircularProgress />
      )}
    </Paper>
  );
}

import { useTheme } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  USER_GROUPS,
  checkAdmin,
  checkRequiredPermissions,
  getUser,
  removeUserSession,
} from "../Utils/Common";
import { useStyles } from "../Utils/Styles";
import CTN_logo from "../images/ctnfi.png";
import { showSidebar } from "../redux/actions/sidebarActions";
import AlertMenu from "./AlertMenu";

export default function Topbar() {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const { sidebarOpen } = useSelector((state) => state.sideBar);
  const dispatch = useDispatch();
  const theme = useTheme();

  const handleLogout = () => {
    removeUserSession();
    history.push("/login");
  };

  const getHeader = () => {
    return;
  };

  return getUser() ? (
    <AppBar
      position="absolute"
      className={clsx(classes.appBar, sidebarOpen && classes.appBarShift)}
      style={{ backgroundColor: theme.palette.primary.dark }}
    >
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={() => {
            dispatch(showSidebar(!sidebarOpen));
          }}
          className={clsx(
            classes.menuButton,
            sidebarOpen && classes.menuButtonHidden
          )}
        >
          <MenuIcon />
        </IconButton>
        <img
          alt="CTN logo"
          src={CTN_logo}
          style={{
            filter: "invert(1)",
            width: 150,
            padding: 10,
            marginRight: 10,
            cursor: "pointer",
          }}
          onClick={() => {
            history.push(checkAdmin() ? "/cpanel" : "/dashboard");
          }}
        ></img>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        >
          {getHeader()}
        </Typography>
        {checkRequiredPermissions([USER_GROUPS.ADMIN]) ? (
          <Button
            className={classes.topBarButton}
            style={{ marginRight: 10 }}
            onClick={() => {
              history.push(checkAdmin() ? "/dashboard" : "/cpanel");
            }}
          >
            <Typography style={{ color: "white" }}>
              {checkAdmin()
                ? t("topbar.linkToDashboard")
                : t("topbar.linkToCpanel")}
            </Typography>
          </Button>
        ) : undefined}
        <Typography
          className={classes.topBarButton}
          style={{ marginRight: 10 }}
        >
          {t("topbar.welcome") + " " + getUser().username}
        </Typography>
        {checkAdmin() ? <AlertMenu /> : null}
        <Button className={classes.topBarButton} onClick={handleLogout}>
          <Typography style={{ color: "white" }}>
            {t("topbar.logout")}
          </Typography>
        </Button>
      </Toolbar>
    </AppBar>
  ) : null;
}

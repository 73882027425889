import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import SortableTable from "../../../Components/SortableTable";
import {
  ECABIN,
  USER_GROUPS,
  formatTimeWithTZOffset,
  getToken,
  requestErrorHandler,
} from "../../../Utils/Common";
import EnvSettings from "../../../Utils/EnvSettings";
import { useStyles } from "../../../Utils/Styles";

const server = EnvSettings.server;

export default function Users(props) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [users, setUsers] = useState();
  const dispatch = useDispatch();
  let getUsers = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token: getToken() }),
    };
    fetch(server + "/get_user_list", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          setUsers(result.users);
        }
      });
  };

  let showUser = (id) => {
    history.push("/cpanel/users/" + id.id);
  };

  const handleScrollClick = (event, id) => {
    if (window.location.href.includes("/cpanel/")) {
      window.open("/cpanel/users/" + id.id, "_blank");
    }
  };

  useEffect(getUsers, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Button
            onClick={() => {
              history.push("/cpanel/users/new");
            }}
          >
            {t("users.newUser")}
          </Button>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          {users ? (
            <SortableTable
              headCells={[
                {
                  id: "userName",
                  numeric: false,
                  disablePadding: false,
                  label: t("userList.username"),
                },
                {
                  id: "emailAddress",
                  numeric: false,
                  disablePadding: false,
                  label: t("userList.email"),
                },
                {
                  id: "permissionLevel",
                  numeric: false,
                  disablePadding: false,
                  label: t("userList.permissionLevel"),
                },
                {
                  id: "fullName",
                  numeric: false,
                  disablePadding: false,
                  label: t("userList.name"),
                },
                {
                  id: "Company.name",
                  numeric: false,
                  disablePadding: false,
                  label: t("userList.company"),
                },
                {
                  id: "lastLogin",
                  numeric: false,
                  disablePadding: false,
                  label: "Last Login",
                },
                {
                  id: "disabled",
                  numeric: false,
                  disablePadding: false,
                  label: "Disabled",
                },
              ]}
              defaultSortRow={"userName"}
              handleRowClick={showUser}
              handleScrollClick={handleScrollClick}
              specialDataFormatting={[
                {
                  id: "lastLogin",
                  format: (data) => {
                    if (!data) {
                      return "";
                    }
                    return formatTimeWithTZOffset(data, ECABIN);
                  },
                },
                {
                  id: "permissionLevel",
                  format: (data) => {
                    return Object.keys(USER_GROUPS).find(
                      (k) => USER_GROUPS[k] === data
                    );
                  },
                },
                {
                  id: "disabled",
                  format: (data) => {
                    return data ? t("yes") : "";
                  },
                },
              ]}
              tableData={users}
              rowsPerPage={25}
            />
          ) : undefined}
        </Paper>
      </Grid>
    </Grid>
  );
}

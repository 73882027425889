import { Grid, Link, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import { useTranslation } from "react-i18next";

export default function AlertDialog({ alertData, handleAnswer, setNote }) {
  const { alert, index } = alertData;
  const { t } = useTranslation();

  return (
    <Dialog
      open={alert ? true : false}
      onClose={() => {
        handleAnswer(alert, index, false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{alert.message}</DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12}>
            {alert.SessionId ? (
              <Link href={"/cpanel/sessions/" + alert.SessionId}>
                {t("showSession")}
              </Link>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={t("alertDialog.addNote")}
              onChange={(e) => setNote(e.target.value)}
            ></TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleAnswer(alert, index, false);
          }}
          color="primary"
        >
          {t("alertDialog.disagree")}
        </Button>
        <Button
          onClick={() => {
            handleAnswer(alert, index, true);
          }}
          color="primary"
          autoFocus
        >
          {t("alertDialog.agree")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import AddComment from "@material-ui/icons/AddComment";
import SendIcon from "@material-ui/icons/Send";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  ECABIN,
  formatTimeWithTZOffset,
  getToken,
  requestErrorHandler,
} from "../Utils/Common";
import EnvSettings from "../Utils/EnvSettings";
import { useStyles } from "../Utils/Styles";
import Title from "./Title";
const server = EnvSettings.server;

export default function NoteSection({ notes, parent, reload, paper }) {
  const { t } = useTranslation();
  const [noteText, setNoteText] = useState();
  const [showAddNote, setShowAddNote] = useState();
  const dispatch = useDispatch();
  const classes = useStyles();
  const addNote = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        note: noteText,
        ...parent,
      }),
    };
    fetch(server + "/add_note", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          setNoteText("");
          if (reload) {
            reload();
          }
        }
      });
  };
  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Title>{t("notes")}</Title>
        <Grid container direction={"column"}>
          {notes &&
            notes.map((row, i) => (
              <Grid
                key={i}
                container
                direction={"row"}
                style={{
                  padding: 5,
                  marginBottom: 10,
                }}
              >
                <Grid item xs={2}>
                  <Typography>{row?.User?.fullName || "System"}</Typography>
                  <Typography>
                    {formatTimeWithTZOffset(row.createdAt, ECABIN)}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs
                  style={{ borderLeft: "1px solid #d4d4d4", paddingLeft: 5 }}
                >
                  <Typography>{row.text}</Typography>
                </Grid>
              </Grid>
            ))}
        </Grid>
        <Button
          onClick={() => {
            setShowAddNote(!showAddNote);
          }}
        >
          <AddComment></AddComment>
          <Typography>{t("sessionDetails.addNote")}</Typography>
        </Button>
        {showAddNote ? (
          <Grid container style={{ flexDirection: "row" }}>
            <Grid item xs={11}>
              <TextField
                name="note"
                variant="outlined"
                required
                value={noteText}
                fullWidth
                id="note"
                label={t("sessionDetails.note")}
                onChange={(e) => setNoteText(e.target.value)}
              />
            </Grid>
            <Grid
              item
              xs={1}
              style={{ alignItems: "center", alignContent: "center" }}
            >
              <Button
                onClick={() => {
                  addNote();
                }}
              >
                <SendIcon></SendIcon>
              </Button>
            </Grid>
          </Grid>
        ) : undefined}
      </Paper>
    </Grid>
  );
}

import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import React from "react";
import { useTranslation } from "react-i18next";
import ChangePasswordForm from "../../Components/ChangePasswordForm";
import Title from "../../Components/Title";
import i18n from "../../i18n";
import { getToken, getUser } from "../../Utils/Common";
import { useStyles } from "../../Utils/Styles";

export default function Settings(props) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Grid container spacing={3} style={{ flexDirection: "row" }}>
                  <Grid item>
                    <Title>{t("settings.userInfo")}</Title>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="userName"
                      variant="outlined"
                      fullWidth
                      id="userName"
                      label={t("settings.username")}
                      disabled
                      value={getUser().username}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="fullName"
                      variant="outlined"
                      fullWidth
                      id="fullName"
                      label={t("settings.fullname")}
                      disabled
                      value={getUser().name}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="companyName"
                      variant="outlined"
                      fullWidth
                      id="companyName"
                      label={t("settings.company")}
                      disabled
                      value={getUser().company}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      name="emailAddress"
                      variant="outlined"
                      fullWidth
                      id="emailAddress"
                      label={t("settings.email")}
                      disabled
                      value={getUser().email}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Grid container spacing={3} style={{ flexDirection: "row" }}>
                  <Grid item>
                    <Title>{t("settings.changePassword")}</Title>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs style={{ padding: 50 }}>
                    <ChangePasswordForm
                      data={{
                        old_password: true,
                        token: getToken(),
                      }}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Grid container spacing={3} style={{ flexDirection: "row" }}>
                  <Grid item>
                    <Title>{t("settings.language")}</Title>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item xs style={{ padding: 50 }}>
                    <Button
                      variant="outlined"
                      onClick={() => {
                        i18n.changeLanguage(
                          i18n.language === "en" ? "fi" : "en"
                        );
                      }}
                    >
                      {i18n.language === "en" ? "Suomeksi" : "In English"}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
  );
}

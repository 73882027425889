import { CircularProgress, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import SortableTable from "../../Components/SortableTable";
import Title from "../../Components/Title";
import {
  ECABIN,
  checkAdmin,
  formatTimeWithTZOffset,
  getDeviceType,
  getLogoImage,
  getSessionType,
  getToken,
  requestErrorHandler,
} from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import { useStyles } from "../../Utils/Styles";
import DeviceActivityRate from "./DeviceActivityRate";
import DeviceConsumptions from "./DeviceConsumptions";
import ThisWeekActivity from "./ThisWeekActivity";

const server = EnvSettings.server;

export default function Dashboard(props) {
  const classes = useStyles();
  const [recentSessions, setRecentSessions] = useState();
  const [deviceData, setDeviceData] = useState();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const fixedHeightPaper = clsx(classes.paper, classes.dashboardPaper);

  let getRecentSessions = (endcustomer) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        limit: 5,
        token: getToken(),
        endcustomer: endcustomer,
      }),
    };
    fetch(server + "/get_sessions", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          setRecentSessions(result.sessions);
          //getLatestDevice(result.sessions)
          setDeviceData(result.sessions[0]);
        }
      });
  };

  let handleRowClick = (data) => {
    history.push((checkAdmin() ? "/cpanel" : "") + "/sessions/" + data.id);
  };

  const handleScrollClick = (event, id) => {
    window.open(
      (checkAdmin() ? "/cpanel" : "") + "/sessions/" + id.id,
      "_blank"
    );
  };

  let onRowHover = (data) => {
    var sess = recentSessions.filter((session) => {
      return session.id === data.id;
    })[0];
    setDeviceData(sess);
  };

  useEffect(getRecentSessions, []);

  return (
    <Grid container spacing={3}>
      {/* Main paper with pie charts*/}

      <Grid item md={3} sm={6} xs={12}>
        <ThisWeekActivity />
      </Grid>
      <Grid item md={3} sm={6} xs={12}>
        <Paper className={fixedHeightPaper}>
          <Title>{t("dashboard.latestSession")}</Title>
          {deviceData ? (
            <Grid container style={{ flexDirection: "row" }} spacing={3}>
              {/* Image */}
              <Grid item xs={4}>
                <img
                  alt="Device"
                  src={getLogoImage(deviceData.Device.serialNumber)}
                  style={{ maxWidth: 115, maxHeight: 115 }}
                />
              </Grid>
              {/* Device definition */}
              <Grid item xs={8}>
                <Title>{getDeviceType(deviceData.Device.serialNumber)}</Title>
                <Typography>{deviceData.Device.serialNumber}</Typography>
                <Typography>{deviceData.Device.model}</Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {getSessionType(
                    getDeviceType(deviceData.Device.serialNumber),
                    deviceData.type
                  )}
                </Typography>
                <Typography>
                  {t("sessionDetails.duration") + " " + deviceData.duration}
                </Typography>
              </Grid>
              <Grid item>
                <Typography>
                  {t("sessionDetails.targetTemperature") +
                    " " +
                    deviceData.targetTemperature}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <CircularProgress></CircularProgress>
          )}
        </Paper>
      </Grid>
      {/* Recent Sessions */}
      <Grid item md={6} sm={12} xs={12}>
        <Paper className={fixedHeightPaper}>
          <Title>{t("dashboard.recentSessions")}</Title>
          {recentSessions ? (
            <SortableTable
              headCells={[
                {
                  id: "startTime",
                  numeric: false,
                  disablePadding: true,
                  label: t("sessionList.startTime"),
                },
                {
                  id: "Device.serialNumber",
                  numeric: false,
                  disablePadding: true,
                  label: t("sessionList.serialNumber"),
                },
                {
                  id: "type",
                  numeric: false,
                  disablePadding: true,
                  label: t("sessionList.type"),
                },
                {
                  id: "duration",
                  numeric: false,
                  disablePadding: true,
                  label: t("sessionList.duration"),
                },
                {
                  id: "consumption",
                  numeric: false,
                  disablePadding: true,
                  label: t("sessionList.consumption"),
                },
              ]}
              defaultSortRow={"startTime"}
              handleRowClick={handleRowClick}
              handleScrollClick={handleScrollClick}
              tableData={recentSessions}
              rowsPerPage={5}
              disablePagination={true}
              tableSize={"small"}
              onRowHover={onRowHover}
              specialDataFormatting={[
                {
                  id: "startTime",
                  format: (data) => {
                    return formatTimeWithTZOffset(data, ECABIN);
                  },
                },
                {
                  id: "type",
                  format: (data, dd, r) => {
                    return getSessionType(
                      getDeviceType(r.Device.serialNumber),
                      data
                    );
                  },
                },
              ]}
            />
          ) : (
            <CircularProgress></CircularProgress>
          )}
        </Paper>
      </Grid>
      <Grid item md={6} sm={6} xs={12}>
        <DeviceActivityRate />
      </Grid>
      <Grid item md={6} sm={6} xs={12}>
        <DeviceConsumptions />
      </Grid>
    </Grid>
  );
}

import { AppBar, Toolbar } from "@material-ui/core";
import React from "react";
import RedExitButton from "./RedExitButton";
import Title from "./Title";

export default function DialogAppBar({ header, onClose }) {
  return (
    <AppBar style={{ position: "fixed" }}>
      <Toolbar>
        <Title style={{ color: "white" }}>{header ? header : null}</Title>
        <RedExitButton
          onClick={onClose}
          style={{
            right: 10,
            backgroundColor: "red",
            position: "absolute",
            padding: 0,
            minWidth: 0,
          }}
        />
      </Toolbar>
    </AppBar>
  );
}

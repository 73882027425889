import { Breadcrumbs } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  checkAdmin,
  getLastParameter,
  getToken,
  requestErrorHandler,
} from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import DetailTabs from "./DetailTabs";
import DeviceInformation from "./DeviceInformation";
import SessionsTable from "./SessionsTable";
const server = EnvSettings.server;

export default function DeviceDetails() {
  const [deviceData, setDeviceData] = useState();
  const device_id = getLastParameter();
  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    const getDevicedata = () => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: getToken(),
          deviceid: device_id,
        }),
      };
      fetch(server + "/get_device_data", requestOptions)
        .then((res) => res.json())
        .then((result) => {
          requestErrorHandler(result, dispatch);
          if (!result.error) {
            setDeviceData(result.device);
          }
        });
    };
    getDevicedata();
  }, [device_id, dispatch]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        {deviceData ? (
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              color="inherit"
              href={checkAdmin() ? "/cpanel/devices/dashboard" : "/devices"}
            >
              {t("deviceDetails.devices")}
            </Link>
            {checkAdmin() ? (
              <Link color="inherit" href={"/cpanel/devices"}>
                {t("search")}
              </Link>
            ) : null}
            <Typography color="textPrimary">
              {deviceData ? deviceData.serialNumber : undefined}
            </Typography>
          </Breadcrumbs>
        ) : undefined}

        <DeviceInformation deviceData={deviceData} />
        <DetailTabs deviceData={deviceData} />
        <SessionsTable />
      </Grid>
    </Grid>
  );
}

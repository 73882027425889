import { Paper } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import React from "react";
import { useTranslation } from "react-i18next";
import CountryData from "./country_data.json";
import CountryFlag from "./CountryFlag";

export default function CountrySearch({
  onChange,
  required,
  defaultValue,
  disabled,
}) {
  const { t } = useTranslation();
  const data = CountryData;

  return (
    <Grid item xs={12}>
      <Autocomplete
        disabled={disabled}
        id="countries"
        options={data}
        fullWidth
        getOptionSelected={(option, value) => option.country === value.country}
        getOptionLabel={(option) => option.country ?? ""}
        defaultValue={{ country: defaultValue }}
        PaperComponent={(props) => (
          <Paper style={{ border: "2px solid #3d57a5" }} {...props} />
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("country")}
            variant="outlined"
            required={required}
          />
        )}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <CountryFlag country_name={option.country} />
            {option.country}
          </React.Fragment>
        )}
        onChange={onChange}
      />
    </Grid>
  );
}

import {
  Checkbox,
  FormControlLabel,
  MenuItem,
  withWidth,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import StockItemSearch from "../../Components/StockItems/StockItemSearch";
import Title from "../../Components/Title";

import { useDispatch } from "react-redux";
import {
  getToken,
  paymentStates,
  requestErrorHandler,
  shippingMethods,
} from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import { useStyles } from "../../Utils/Styles";
import OrderLine from "./OrderLine";

const server = EnvSettings.server;

function NewOrder({ width }) {
  const classes = useStyles();
  const [showForm, setShowForm] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [currency, setCurrency] = useState("EUR");
  const [issuer, setIssuer] = useState();
  const [productFilter, setProductFilter] = useState(false);
  const [details, setDetails] = useState();
  const { t } = useTranslation();
  const [orderNumber, setOrderNumber] = useState();
  const [eta, setEta] = useState();
  const [shippingMethod, setShippingMethod] = useState();
  const [newProduct, setNewProduct] = useState(false);
  const [newProductCount, setNewProductCount] = useState();
  const [newProductSku, setNewProductSku] = useState();
  const [newProductName, setNewProductName] = useState();
  const [orderStatus, setOrderStatus] = useState();
  const [destination, setDestination] = useState("mailhouse");
  const [gridRef, setGridRef] = useState("ssdafasfasd");
  const dispatch = useDispatch();

  const submitOrder = (file) => {
    if (!selectedProducts || !selectedProducts.length || !issuer) {
      return;
    }
    var sp = selectedProducts;
    if (newProduct && (newProductSku || newProductName) && newProductCount) {
      sp = [
        ...sp,
        { sku: newProductSku, name: newProductName, count: newProductCount },
      ];
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        deviceList: sp,
        totalPrice: totalPrice,
        currency: currency,
        invoiceIssuer: issuer,
        details: details,
        orderNumber: orderNumber,
        shippingMethod: shippingMethod,
        destination: destination,
        eta: eta,
        orderStatus: orderStatus,
      }),
    };
    fetch(server + "/add_resupply_order", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          window.location.reload();
        }
      });
  };

  const resetForm = () => {
    setShowForm(false);
    setSelectedProducts([]);
    setTotalPrice(0);
    setCurrency("EUR");
    setIssuer();
  };

  useState(() => {
    setOrderNumber(crypto.randomUUID());
  }, []);

  return (
    <>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            {!showForm ? (
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setShowForm(!showForm);
                  }}
                >
                  {t("orders.addNewOrder")} +
                </Button>
              </Grid>
            ) : null}
            {showForm ? (
              <>
                <Grid item xs={12}>
                  <Title>{t("resupplyOrders.newResupplyOrder")}</Title>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <TextField
                    label={t("resupplyOrders.orderFrom")}
                    variant="outlined"
                    fullWidth
                    onChange={(e, v) => {
                      if (!e || !e.target) {
                        return;
                      }
                      setIssuer(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <TextField
                    label={t("orders.paymentStatus")}
                    variant="outlined"
                    fullWidth
                    defaultValue={"invoiced"}
                    select
                    onChange={(e, v) => {
                      if (e && e.target.value) {
                        setOrderStatus(e.target.value);
                      }
                    }}
                  >
                    {paymentStates
                      .filter(
                        (p) =>
                          p.value !== "cancelled" && p.value !== "delivered"
                      )
                      .map((p) => (
                        <MenuItem value={p.value}>
                          {t("paymentStates." + p.value)}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                {orderStatus === "completed" ? (
                  <>
                    <Grid item xs={6} sm={3}>
                      <TextField
                        label={t("resupplyOrders.shippingMethod")}
                        variant="outlined"
                        fullWidth
                        select
                        onChange={(e, v) => {
                          if (e && e.target) {
                            setShippingMethod(e.target.value);
                            var d = new Date().getTime();
                            var time = 1000 * 60 * 60 * 24;
                            if (e.target.value === "air") {
                              d = d + time * 14;
                            } else if (e.target.value === "sea") {
                              d = d + time * 70;
                            } else if (e.target.value === "rail") {
                              d = d + time * 60;
                            } else {
                              return;
                            }
                            setEta(new Date(d).toISOString().substring(0, 10));
                          }
                        }}
                      >
                        {shippingMethods.map((s) => (
                          <MenuItem value={s.value}>
                            {t("shippingMethods." + s.value)}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <TextField
                        label={"ETA"}
                        variant="outlined"
                        fullWidth
                        type="date"
                        value={eta}
                        onChange={(e, v) => {
                          if (!e || !e.target.value) {
                            return;
                          }
                          setEta(e.target.value);
                        }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <TextField
                        label={t("resupplyOrders.deliveryTo")}
                        defaultValue={"mailhouse"}
                        variant="outlined"
                        onChange={(e, v) => {
                          if (!e || !e.target.value) {
                            return;
                          }
                          setDestination(e.target.value);
                        }}
                        fullWidth
                        select
                      >
                        <MenuItem value={"estonia"}>Estonia</MenuItem>
                        <MenuItem value={"vantaa"}>Vantaa</MenuItem>
                        <MenuItem value={"mailhouse"}>Mailhouse</MenuItem>
                        <MenuItem value={"reimax"}>Reimax</MenuItem>
                      </TextField>
                    </Grid>
                  </>
                ) : null}
                <Grid item container xs={12} spacing={width === "xs" ? "" : 3}>
                  <Grid item xs={12}>
                    <Title>{t("orders.itemSearch")}</Title>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      defaultChecked={false}
                      control={
                        <Checkbox
                          onChange={(e, v) => {
                            setProductFilter(!productFilter);
                          }}
                          defaultChecked={false}
                          color="primary"
                        />
                      }
                      label={t("orders.showOnlyDevices")}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <StockItemSearch
                      allowNewItems={false}
                      onChange={(e, v) => {
                        if (v && v.sku) {
                          setSelectedProducts([
                            ...selectedProducts,
                            { ...v, count: 1 },
                          ]);
                        }
                      }}
                      clearOnSelect={true}
                      includeQuantity={true}
                      includeTags={true}
                      includePacking={true}
                      placeholder={t("orders.startSearchingProducts")}
                      filters={
                        productFilter
                          ? {
                              Tags: [
                                "eCabin",
                                "CryoCabin",
                                "XCryo",
                                "XTone",
                                "OxyPro",
                                "LedPro",
                                "XGun",
                              ],
                            }
                          : undefined
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        setNewProduct(true);
                      }}
                    >
                      {t("resupplyOrders.itemNotInList")}
                    </Button>
                  </Grid>
                  {selectedProducts.length || newProduct ? (
                    <>
                      {width === "xs" ? null : (
                        <Grid
                          container
                          style={{ borderTop: "1px solid #000", padding: 5 }}
                        >
                          {width === "xs" ? null : (
                            <Grid item sm={1} xs={1}></Grid>
                          )}
                          <Grid item sm={3} xs={3} style={{ padding: 5 }}>
                            SKU
                          </Grid>
                          {width === "xs" ? null : (
                            <Grid item sm={3} xs={3} style={{ padding: 5 }}>
                              {t("description")}
                            </Grid>
                          )}
                          <Grid item sm={1} xs={3} style={{ padding: 5 }}>
                            {t("orders.numberItems")}
                          </Grid>
                          {width === "xs" ? null : (
                            <Grid item sm={1} xs={1}></Grid>
                          )}
                        </Grid>
                      )}
                      {newProduct ? (
                        <Grid container key={gridRef}>
                          <Grid item xs={3} style={{ padding: 5 }}>
                            <TextField
                              variant="outlined"
                              fullWidth
                              size="small"
                              label="SKU"
                              onChange={(e, v) => {
                                if (!e || !e.target.value) {
                                  return;
                                }
                                setNewProductSku(e.target.value);
                              }}
                            ></TextField>
                          </Grid>
                          <Grid item xs={3} style={{ padding: 5 }}>
                            <TextField
                              variant="outlined"
                              fullWidth
                              size="small"
                              label="Name"
                              onChange={(e, v) => {
                                if (!e || !e.target.value) {
                                  return;
                                }
                                setNewProductName(e.target.value);
                              }}
                            ></TextField>
                          </Grid>
                          <Grid item xs={1}></Grid>
                          <Grid item xs={1} style={{ padding: 5 }}>
                            <TextField
                              variant="outlined"
                              type="number"
                              inputProps={{ min: 1 }}
                              label={t("count")}
                              size="small"
                              onChange={(e, v) => {
                                if (!e || !e.target.value) {
                                  return;
                                }
                                setNewProductCount(Number(e.target.value));
                              }}
                            ></TextField>
                          </Grid>
                          <Grid item xs={2} style={{ padding: 5 }}>
                            <Button
                              color="primary"
                              variant="contained"
                              size="small"
                              onClick={() => {
                                if (!newProductSku && !newProductName) {
                                  return;
                                }
                                setSelectedProducts([
                                  ...selectedProducts,
                                  {
                                    sku: newProductSku,
                                    name: newProductName,
                                    count: newProductCount,
                                    id: "new" + Math.round(Math.random() * 100),
                                  },
                                ]);
                                setNewProductCount();
                                setNewProductName();
                                setNewProductSku();
                                setGridRef(Math.random());
                              }}
                            >
                              OK
                            </Button>
                          </Grid>
                          <Grid item xs={1} style={{ padding: 5 }}>
                            <Button
                              color="secondary"
                              variant="contained"
                              size="small"
                              onClick={() => {
                                setNewProduct(false);
                              }}
                            >
                              {t("cancel")}
                            </Button>
                          </Grid>
                        </Grid>
                      ) : null}
                      {selectedProducts.map((dev, i) => (
                        <OrderLine
                          key={i}
                          item={dev}
                          selectedProducts={selectedProducts}
                          setSelectedProducts={setSelectedProducts}
                          totalPrice={totalPrice}
                          setTotalPrice={setTotalPrice}
                          width={width}
                        />
                      ))}
                      <Grid
                        container
                        style={{
                          padding: 5,
                          borderTop: "1px solid #000",
                          marginTop: 10,
                        }}
                      >
                        <Grid item sm={2} xs={2}>
                          <TextField
                            type="number"
                            size="small"
                            inputProps={{ min: 0 }}
                            variant="outlined"
                            label={t("orders.totalPrice")}
                            onChange={(e, v) => {
                              if (!e || !e.target) {
                                return;
                              }
                              setTotalPrice(e.target.value);
                            }}
                          />
                        </Grid>
                        <Grid item sm={1} xs={2}>
                          <TextField
                            select
                            value={currency}
                            onChange={(e, v) => {
                              setCurrency(e.target.value);
                            }}
                          >
                            <MenuItem key={"USD"} value={"USD"}>
                              USD
                            </MenuItem>
                            <MenuItem key={"EUR"} value={"EUR"}>
                              EUR
                            </MenuItem>
                          </TextField>
                        </Grid>
                      </Grid>
                    </>
                  ) : null}
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      rows={2}
                      multiline
                      label={t("details")}
                      variant="outlined"
                      onChange={(e, v) => {
                        if (e.target && e.target.value) {
                          setDetails(e.target.value);
                          return;
                        }
                        setDetails();
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item sm={6} xs={12} container spacing={1}>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={submitOrder}
                    >
                      {t("submit")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={resetForm}
                    >
                      {t("cancel")}
                    </Button>
                  </Grid>
                </Grid>
              </>
            ) : null}
          </Grid>
        </Paper>
      </Grid>
    </>
  );
}

export default withWidth()(NewOrder);

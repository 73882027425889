import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import React from "react";
import { useTranslation } from "react-i18next";
import { useStyles } from "../Utils/Styles";

export default function DateSelect(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid item xs={12}>
      <Paper
        className={classes.paper}
        style={{
          alignItems: "center",
          justifyContent: "space-evenly",
          flexDirection: "row",
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <TextField
              type="number"
              defaultValue={500}
              label={t("dateSelect.limit")}
              onChange={(e) => {
                props.setLimit(e.target.value);
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="rangeStartDate"
              label={t("dateSelect.rangeStartDate")}
              type="date"
              defaultValue={props.selectedDate?.substring(0, 10)}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                props.setDate({ ...props.date, startDate: e.target.value });
              }}
              fullWidth
            />
            <TextField
              id="rangeStartTime"
              label={t("dateSelect.rangeStartTime")}
              type="time"
              className={classes.textField}
              style={{ marginTop: 5 }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                props.setDate({ ...props.date, startTime: e.target.value });
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              id="rangeEndDate"
              label={t("dateSelect.rangeEndDate")}
              type="date"
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                props.setDate({ ...props.date, endDate: e.target.value });
              }}
              fullWidth
            />
            <TextField
              id="rangeEndTime"
              label={t("dateSelect.rangeEndTime")}
              type="time"
              className={classes.textField}
              style={{ marginTop: 5 }}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(e) => {
                props.setDate({ ...props.date, endTime: e.target.value });
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}

import {
  Dialog,
  DialogContent,
  MenuItem,
  TextField,
  Typography,
  withWidth,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ControlledSelect from "../../../Components/ControlledSelect";
import DialogAppBar from "../../../Components/DialogAppBar";
import NoteSection from "../../../Components/NoteSection";
import {
  ECABIN,
  formatTimeWithTZOffset,
  getToken,
  paymentStates,
  requestErrorHandler,
  shippingMethods,
} from "../../../Utils/Common";
import EnvSettings from "../../../Utils/EnvSettings";
import OrderLine from "../OrderLine";
import ConfirmDeleteDialog from "./ConfirmDeleteDialog";

const server = EnvSettings.server;

function EditOrder({ selectedOrder, width }) {
  const [editable, setEditable] = useState(false);
  const [editedOrder, setEditedOrder] = useState();
  const [showDialog, setShowDialog] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const deleteOrder = () => {
    if (!editedOrder) {
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        orderData: editedOrder,
      }),
    };
    fetch(server + "/delete_resupply_order", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          window.location.reload();
        }
      });
  };

  const editOrder = () => {
    if (!editedOrder) {
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        orderData: editedOrder,
      }),
    };
    fetch(server + "/edit_resupply_order", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          window.location.reload();
        }
      });
  };

  useEffect(() => {
    if (selectedOrder) {
      setEditedOrder(JSON.parse(JSON.stringify(selectedOrder)));
    }
    // eslint-disable-next-line
  }, [selectedOrder, setEditedOrder]);

  const handleExit = () => {
    setEditable(false);
    setEditedOrder();
  };

  return editedOrder ? (
    <Dialog open={!!editedOrder} fullScreen onClose={handleExit}>
      <DialogContent>
        <DialogAppBar header={t("orders.orderDetails")} onClose={handleExit} />
        <Grid
          container
          spacing={1}
          style={{
            marginTop: 60,
            marginBottom: 20,
            paddingBottom: 30,
            position: "relative",
          }}
        >
          <ConfirmDeleteDialog
            onClose={() => {
              setShowDialog(false);
            }}
            showDialog={showDialog}
            deleteOrder={deleteOrder}
          />
          <Grid item container sm={6} xs={12}>
            <Grid item xs={12}>
              <Typography>
                {t("orders.orderNumber")}: {editedOrder.number}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                {t("orders.invoiceIssuer")}: {editedOrder.invoiceIssuer}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                {t("createdBy")}: {editedOrder.User.fullName}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography>
                {t("createdAt")}:{" "}
                {formatTimeWithTZOffset(editedOrder.createdAt, ECABIN)}
              </Typography>
            </Grid>
            {editedOrder.paymentDate ? (
              <Grid item xs={12}>
                <Typography>
                  {t("orders.paymentDate")}:{" "}
                  {formatTimeWithTZOffset(editedOrder.paymentDate, ECABIN)}
                </Typography>
              </Grid>
            ) : null}
            {editedOrder.deliveryDate ? (
              <Grid item xs={12}>
                <Typography>
                  {t("orders.deliveryDate")}:{" "}
                  {formatTimeWithTZOffset(editedOrder.deliveryDate, ECABIN)}
                </Typography>
              </Grid>
            ) : null}
            <Grid item xs={12}>
              <ControlledSelect
                itemlist={paymentStates}
                label={t("orders.paymentStatus")}
                defaultValue={editedOrder.paymentStatus}
                onChange={(e, v) => {
                  if (!e.target?.value) {
                    setEditedOrder({
                      ...editedOrder,
                      paymentStatus: selectedOrder.paymentStatus,
                    });
                    return;
                  }
                  setEditedOrder({
                    ...editedOrder,
                    paymentStatus: e.target.value,
                  });
                }}
                deleteButton={true}
                disabled={!editable}
                usingTranslation={(data) => {
                  return t("paymentStates." + data);
                }}
              />
            </Grid>
            {editedOrder.paymentStatus === "deposit" ? (
              <Grid item xs={12} style={{ marginTop: 5 }}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label={t("orders.depositAmount")}
                  type="number"
                  defaultValue={editedOrder.depositAmount || ""}
                  disabled={!editable}
                  inputProps={{ min: 0, max: editedOrder.totalPrice }}
                  onChange={(e, v) => {
                    if (!e.target?.value) {
                      setEditedOrder({ ...editedOrder, depositAmount: null });
                      return;
                    }
                    setEditedOrder({
                      ...editedOrder,
                      depositAmount: e.target.value,
                    });
                  }}
                ></TextField>
              </Grid>
            ) : null}
            {editedOrder.paymentStatus === "completed" ? (
              <Grid container spacing={1} style={{ marginTop: 10 }}>
                <Grid item xs={6} sm={3}>
                  <TextField
                    label={t("resupplyOrders.shippingMethod")}
                    variant="outlined"
                    fullWidth
                    select
                    disabled={!editable}
                    value={editedOrder?.shippingMethod || ""}
                    onChange={(e, v) => {
                      if (e && e.target) {
                        var o = { ...editedOrder };
                        o.shippingMethod = e.target.value;
                        if (!o.destination) {
                          o.destination = "mailhouse";
                        }

                        var d = new Date().getTime();
                        var time = 1000 * 60 * 60 * 24;
                        if (e.target.value === "air") {
                          d = d + time * 14;
                        } else if (e.target.value === "sea") {
                          d = d + time * 70;
                        } else if (e.target.value === "rail") {
                          d = d + time * 60;
                        } else {
                          setEditedOrder(o);
                          return;
                        }
                        setEditedOrder({
                          ...o,
                          eta: new Date(d).toISOString().substring(0, 10),
                        });
                      } else {
                        setEditedOrder({
                          ...editedOrder,
                          shippingMethod: null,
                        });
                      }
                    }}
                  >
                    {shippingMethods.map((s) => (
                      <MenuItem value={s.value}>
                        {t("shippingMethods." + s.value)}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <TextField
                    label={"ETA"}
                    variant="outlined"
                    fullWidth
                    type="date"
                    disabled={!editable}
                    value={editedOrder?.eta || ""}
                    onChange={(e, v) => {
                      if (!e || !e.target.value) {
                        setEditedOrder({ ...editedOrder, eta: null });
                        return;
                      }
                      setEditedOrder({ ...editedOrder, eta: e.target.value });
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <TextField
                    label={t("resupplyOrders.deliveryTo")}
                    value={editedOrder?.destination || ""}
                    variant="outlined"
                    disabled={!editable}
                    onChange={(e, v) => {
                      if (!e || !e.target.value) {
                        setEditedOrder({ ...editedOrder, destination: null });
                        return;
                      }
                      setEditedOrder({
                        ...editedOrder,
                        destination: e.target.value,
                      });
                    }}
                    fullWidth
                    select
                  >
                    <MenuItem value={"estonia"}>Estonia</MenuItem>
                    <MenuItem value={"vantaa"}>Vantaa</MenuItem>
                    <MenuItem value={"mailhouse"}>Mailhouse</MenuItem>
                    <MenuItem value={"reimax"}>Reimax</MenuItem>
                  </TextField>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <Typography style={{ fontWeight: "bold" }}>
              {t("details")}
            </Typography>
            <Typography>{editedOrder.details}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            container
            style={{ borderTop: "1px solid #000", marginTop: 10 }}
          >
            <Grid item sm={3} xs={3}>
              <Typography>{t("description")}</Typography>
            </Grid>
            <Grid item sm={2} xs={3}>
              <Typography style={{ textAlign: "right" }}>
                {t("orders.numberItems")}
              </Typography>
            </Grid>
            {width === "xs" ? null : <Grid item xs={1}></Grid>}
          </Grid>
          {editedOrder.genericDevices &&
            editedOrder.genericDevices.map((dev, i) => (
              <OrderLine
                key={i}
                item={dev}
                selectedProducts={editedOrder.genericDevices}
                // totalItems={totalItems}
                // totalPrice={totalPrice}
                onlyPrint={true}
                editedOrder={editedOrder}
                setEditedOrder={setEditedOrder}
                editable={editable}
                width={width}
              />
            ))}
          <Grid item container xs={12} style={{ borderTop: "1px solid #000" }}>
            {width === "xs" ? null : <Grid item xs={2}></Grid>}
            <Grid item sm={2} xs={3}>
              <Typography style={{ textAlign: "right", padding: 5 }}>
                {t("orders.totalItems")}
              </Typography>
            </Grid>
            <Grid item sm={1} xs={3}>
              <Typography style={{ textAlign: "right", padding: 5 }}>
                {editedOrder.genericDevices.reduce(
                  (partialSum, a) => partialSum + a.count,
                  0
                )}
              </Typography>
            </Grid>
            {width === "xs" ? null : <Grid item xs={4}></Grid>}
            <Grid item sm={1} xs={3}>
              <Typography style={{ textAlign: "right", padding: 5 }}>
                {t("orders.totalPrice")}
              </Typography>
            </Grid>
            <Grid item sm={1} xs={3}>
              <Typography style={{ textAlign: "right", padding: 5 }}>
                {editedOrder.totalPrice.toFixed(2)} {editedOrder.currency}
              </Typography>
            </Grid>
            {width === "xs" ? null : <Grid item xs={1}></Grid>}
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              onClick={() => {
                if (editable) {
                  setEditedOrder(JSON.parse(JSON.stringify(selectedOrder)));
                }
                setEditable(!editable);
              }}
            >
              {!editable ? t("edit") : t("cancel")}
            </Button>
            {editable ? (
              <>
                <Button
                  variant="contained"
                  disabled={
                    JSON.stringify(editedOrder) ===
                    JSON.stringify(selectedOrder)
                  }
                  color="primary"
                  onClick={editOrder}
                >
                  {t("save")}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setShowDialog(true);
                  }}
                >
                  {t("delete")}
                </Button>
              </>
            ) : null}
          </Grid>
          <NoteSection
            parent={{ OrderId: editedOrder.id }}
            notes={editedOrder.Notes}
            reload={() => {
              window.location.reload();
            }}
          />
        </Grid>
      </DialogContent>
    </Dialog>
  ) : null;
}

export default withWidth()(EditOrder);

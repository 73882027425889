import Box from "@material-ui/core/Box";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ChangePasswordForm from "../Components/ChangePasswordForm";
import Copyright from "../Components/Copyright";
import { getLastParameter, requestErrorHandler } from "../Utils/Common";
import EnvSettings from "../Utils/EnvSettings";

const server = EnvSettings.server;

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function ChangePassword(props) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const token = getLastParameter();
  const dispatch = useDispatch();

  const validateSession = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token: token }),
    };
    fetch(server + "/validate_token", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        history.push("/login");
      });
  };

  const handleSuccess = () => {
    history.push("/login");
  };

  useEffect(validateSession, []);

  return (
    <Grid
      container
      component="main"
      className={classes.root}
      style={{ justifyContent: "center" }}
    >
      <CssBaseline />
      <Grid item xs={6} square>
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            {t("changePassword.header")}
          </Typography>
          <form className={classes.form} noValidate>
            <ChangePasswordForm
              data={{
                handlePositiveOutcome: handleSuccess,
                token: token,
              }}
            />
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

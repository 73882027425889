import { Breadcrumbs, Link } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import NoteSection from "../../../Components/NoteSection";
import Title from "../../../Components/Title";
import {
  getLastParameter,
  getToken,
  requestErrorHandler,
} from "../../../Utils/Common";
import EnvSettings from "../../../Utils/EnvSettings";
import { useStyles } from "../../../Utils/Styles";
const server = EnvSettings.server;

export default function CrashlogDetails(props) {
  const classes = useStyles();
  const [crashlogData, setCrashlogData] = useState();
  const dispatch = useDispatch();
  const crashlog_id = getLastParameter();

  const formatText = (data) => {
    if (data) {
      var arr = [];
      data.split("\n").forEach((element) => {
        arr.push(
          <>
            {element} <br />{" "}
          </>
        );
      });
      return arr;
    }
    return data;
  };

  const getCrashlog = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        crashlogid: crashlog_id,
      }),
    };
    fetch(server + "/get_crashlog_data", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          setCrashlogData(result.crashlog);
        }
      });
  };

  useEffect(getCrashlog, []);

  return crashlogData ? (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Breadcrumbs>
          <Link href="/cpanel/crashlogs" color="inherit">
            Crashlogs
          </Link>
          <Typography>{crashlogData.id}</Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Title>{crashlogData.date}</Title>
          <Grid container spacing={3}>
            <Grid item>
              <Typography>{"Device"}</Typography>
              <Link
                style={{ fontSize: 16 }}
                href={"/cpanel/devices/" + crashlogData.Device.id}
              >
                {crashlogData.Device.serialNumber}
              </Link>
            </Grid>
            <Grid item>
              <Typography>{"Software version"}</Typography>
              <Typography>{crashlogData.version}</Typography>
            </Grid>
            <Grid item>
              <Typography>{"Type"}</Typography>
              <Typography>
                {crashlogData.type === 1 ? "Crashlog" : "Updatelog"}
              </Typography>
            </Grid>
            <Grid item>
              <Typography>{"Reason"}</Typography>
              <Typography>{crashlogData.reason}</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography style={{ maxHeight: 500 }}>
                {formatText(crashlogData.data)}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <NoteSection
        parent={{ crashlogid: crashlog_id }}
        notes={crashlogData.Notes}
        reload={() => {
          getCrashlog();
        }}
      />
    </Grid>
  ) : null;
}

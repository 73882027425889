import uiReducer from './uiReducer'
import {combineReducers} from 'redux'

const sideBar = (state = {}, action) => {
	switch (action.type) {
	  case "SIDEBAR_OPEN":
		return {
		  ...state,
		  sidebarOpen: action.data,
		};
	  default:
		return {
			...state,
			sidebarOpen:false,
		};
	}
  };


const rootReducer = combineReducers({
    uiReducer,
    sideBar
})

export default rootReducer
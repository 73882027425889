import { IconButton, Typography, withWidth } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import BusinessIcon from "@material-ui/icons/Business";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";
import { useStyles } from "../../Utils/Styles";
import CountryFlag from "../CountrySearch/CountryFlag";
function ContactInfoBadge({
  width,
  clientInfoObject,
  noPaper,
  showBrief,
  clearClientInfo,
  containerStyles,
  onClick,
}) {
  const classes = useStyles();
  const style = width === "xs" ? { fontSize: 15 } : {};
  const otherElements = () => {
    return (
      <Grid
        container
        style={{
          ...(noPaper
            ? { padding: 5, ...(containerStyles ? containerStyles : {}) }
            : {}),
          ...(onClick ? { cursor: "pointer", ...{} } : {}),
        }}
        // This negation is added so that the onClick wont fire if the thrash icon is available
        onClick={onClick && !clearClientInfo ? onClick : undefined}
      >
        {showBrief ? (
          <Grid item xs={10} container alignItems="center">
            {clientInfoObject.companyName ? (
              <>
                <Typography style={{ color: "gray" }}>
                  <BusinessIcon />
                </Typography>
                <Typography
                  style={{
                    ...style,
                    ...(clientInfoObject.companyName.length > 30
                      ? { fontSize: 10 }
                      : clientInfoObject.companyName.length > 20
                      ? { fontSize: 12 }
                      : {}),
                  }}
                >
                  {clientInfoObject.companyName}
                </Typography>
              </>
            ) : clientInfoObject.contactName ? (
              <>
                <Typography style={{ color: "gray" }}>
                  <AccountCircleIcon />
                </Typography>
                <Typography
                  style={{
                    ...style,
                    ...(clientInfoObject.contactName.length > 30
                      ? { fontSize: 10 }
                      : clientInfoObject.contactName.length > 20
                      ? { fontSize: 12 }
                      : {}),
                  }}
                >
                  {clientInfoObject.contactName}
                </Typography>
              </>
            ) : clientInfoObject.location ? (
              <>
                <Typography style={{ color: "gray" }}>
                  <CountryFlag country_name={clientInfoObject.location} />
                </Typography>
                <Typography style={style}>
                  {clientInfoObject.location}
                </Typography>
              </>
            ) : null}
          </Grid>
        ) : (
          Object.keys(clientInfoObject).map((c) =>
            !c.toLowerCase().includes("id") && clientInfoObject[c] ? (
              <Grid xs={10} item key={c} container alignItems="center">
                <Typography style={{ color: "gray" }}>
                  {c === "companyName" && clientInfoObject[c] ? (
                    <BusinessIcon />
                  ) : c === "contactName" && clientInfoObject[c] ? (
                    <AccountCircleIcon />
                  ) : (
                    <CountryFlag country_name={clientInfoObject.location} />
                  )}
                </Typography>
                <Typography
                  style={{
                    ...style,
                    ...(clientInfoObject[c].length > 20
                      ? { fontSize: 12 }
                      : {}),
                  }}
                >
                  {clientInfoObject[c]}
                </Typography>
              </Grid>
            ) : null
          )
        )}
        {clientInfoObject && clearClientInfo ? (
          <Grid item xs={1}>
            <IconButton onClick={clearClientInfo}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        ) : null}
      </Grid>
    );
  };
  return clientInfoObject &&
    Object.keys(clientInfoObject).length &&
    Object.keys(clientInfoObject).some((v) => !!clientInfoObject[v]) ? (
    <>
      {noPaper ? (
        otherElements()
      ) : (
        <Paper
          className={classes.paper}
          style={{ ...(containerStyles ? containerStyles : {}) }}
        >
          {otherElements()}
        </Paper>
      )}
    </>
  ) : null;
}

export default withWidth()(ContactInfoBadge);

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import SortableTable from "../../../Components/SortableTable";
import { getToken, requestErrorHandler } from "../../../Utils/Common";
import EnvSettings from "../../../Utils/EnvSettings";
import { useStyles } from "../../../Utils/Styles";

const server = EnvSettings.server;

export default function Crashlogs(props) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [crashlogs, setCrashlogs] = useState();
  const dispatch = useDispatch();
  let getCrashlogs = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token: getToken() }),
    };
    fetch(server + "/get_crashlogs", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          setCrashlogs(result.crashlogs);
        }
      });
  };

  let showUser = (id) => {
    history.push("/cpanel/crashlogs/" + id.id);
  };

  const handleScrollClick = (event, id) => {
    if (window.location.href.includes("/cpanel/")) {
      window.open("/cpanel/crashlogs/" + id.id, "_blank");
    }
  };

  useEffect(getCrashlogs, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          {crashlogs ? (
            <SortableTable
              headCells={[
                {
                  id: "data",
                  numeric: false,
                  disablePadding: false,
                  label: "Data",
                },
                {
                  id: "date",
                  numeric: false,
                  disablePadding: false,
                  label: t("date"),
                },
                {
                  id: "Device.serialNumber",
                  numeric: false,
                  disablePadding: false,
                  label: t("serialNumber"),
                },
              ]}
              defaultSortRow={"date"}
              handleRowClick={showUser}
              handleScrollClick={handleScrollClick}
              tableData={crashlogs}
              rowsPerPage={25}
              specialDataFormatting={[
                {
                  id: "data",
                  format: (data) => {
                    return data.substring(0, Math.min(data.length, 100));
                  },
                },
              ]}
            />
          ) : undefined}
        </Paper>
      </Grid>
    </Grid>
  );
}

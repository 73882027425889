import {
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Link,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  checkNewSerialNumber,
  formatGoogleDriveMediaLink,
  getToken,
  requestErrorHandler,
  ticketStates,
} from "../Utils/Common";
import EnvSettings from "../Utils/EnvSettings";
import { showPopupSnackbar } from "../redux/actions/snackbarActions";
import DeviceSearchAutocomplete from "./DeviceSearchAutocomplete";
import DialogAppBar from "./DialogAppBar";
const server = EnvSettings.server;

export default function TicketEntry({
  device_id,
  selectedTicket,
  setSelectedTicket,
  showTicketEntry: show,
  setShowTicketEntry: hide,
  setRefreshList,
  showSearch,
  showHeader,
  ...rest
}) {
  const [addMedia, setAddMedia] = useState();
  const [ticketData, setTicketData] = useState();
  const [confirmDelete, setConfirmDelete] = useState();
  const [acKey, setACKey] = useState("sdfsdfsd");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const submitTicket = () => {
    if (selectedTicket) {
      return;
    }
    if (!ticketData) {
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        deviceid: device_id,
        // Initial state is new unless ticketData overrides it
        ticket_data: { state: "new", ...ticketData },
      }),
    };
    fetch(server + "/add_ticket", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);

        if (!result.error) {
          dispatch(showPopupSnackbar(result));
          setTicketData();
          hide(false);
          if (setRefreshList) {
            setRefreshList(new Date().toISOString());
          }
        }
      });
  };

  const handleClose = () => {
    setSelectedTicket();
    setTicketData();
    setAddMedia();
    setConfirmDelete();
    hide(false);
  };

  const editTicket = (deleteTicket) => {
    if (!selectedTicket) {
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        deviceid: device_id ?? selectedTicket.DeviceId,
        ticket_data: { ...selectedTicket, ...ticketData },
        deleteTicket: deleteTicket ?? undefined,
      }),
    };
    fetch(server + "/edit_ticket", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);

        if (!result.error) {
          dispatch(showPopupSnackbar(result));
          setTicketData();
          hide(false);
          if (setRefreshList) {
            setRefreshList(new Date().toISOString());
          }
        }
      });
  };
  return show ? (
    <Dialog open={!!show} fullScreen onClose={handleClose}>
      <DialogContent>
        <DialogAppBar
          onClose={handleClose}
          header={
            !selectedTicket && showSearch ? (
              t("newTicket")
            ) : selectedTicket?.Device && showHeader ? (
              <>
                {" "}
                {selectedTicket?.Device.serialNumber} -{" "}
                <Link
                  href={"/cpanel/devices/" + selectedTicket?.Device.id}
                  style={{ color: "white" }}
                >
                  {t("viewDevicePage")}
                </Link>
              </>
            ) : null
          }
        />
        <Grid
          style={{ marginTop: 60 }}
          container
          spacing={3}
          justifyContent="space-around"
        >
          {!selectedTicket && showSearch ? (
            <Grid item xs={12}>
              <DeviceSearchAutocomplete
                onChange={(e, v) => {
                  if (v && typeof v !== "string") {
                    if (v.inputValue) {
                      var x = checkNewSerialNumber(v.serialNumber);
                      if (x) {
                        alert(x);
                        setTicketData({
                          ...ticketData,
                          DeviceId: undefined,
                        });
                        setACKey(Date.now());
                        return;
                      }
                      setTicketData({
                        ...ticketData,
                        newDevice: v.serialNumber,
                      });
                      return;
                    }

                    setTicketData({
                      ...ticketData,
                      DeviceId: v.id,
                    });
                    return;
                  }
                  setTicketData({ ...ticketData, device: undefined });
                }}
                allowNewDevices={true}
                // This key hack allows to reset the element after bad input
                key={acKey}
              />
              {ticketData?.newDevice ? (
                <Typography>
                  {t("addingNewDevice")}: {ticketData.newDevice.toUpperCase()}
                </Typography>
              ) : null}
            </Grid>
          ) : null}
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              multiline
              defaultValue={selectedTicket?.description}
              rows={4}
              label={t("ticketList.description")}
              onChange={(e) => {
                if (e.target.value) {
                  setTicketData({ ...ticketData, description: e.target.value });
                  return;
                }
                setTicketData({ ...ticketData, description: undefined });
              }}
            ></TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              multiline
              defaultValue={selectedTicket?.action}
              rows={4}
              label={t("ticketList.action")}
              onChange={(e) => {
                if (e.target.value) {
                  setTicketData({ ...ticketData, action: e.target.value });
                  return;
                }
                setTicketData({ ...ticketData, action: undefined });
              }}
            ></TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              fullWidth
              multiline
              defaultValue={selectedTicket?.details}
              rows={4}
              label={t("ticketList.details")}
              onChange={(e) => {
                if (e.target.value) {
                  setTicketData({ ...ticketData, details: e.target.value });
                  return;
                }
                setTicketData({ ...ticketData, details: undefined });
              }}
            ></TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              variant="outlined"
              select
              fullWidth
              defaultValue={selectedTicket?.state ?? "new"}
              label={t("ticketList.state")}
              onChange={(e, v) => {
                setTicketData({ ...ticketData, state: e.target.value });
              }}
            >
              {ticketStates.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {t("ticketList." + option.value)}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item={6}>
            {addMedia ? (
              <>
                <TextField
                  label={t("type")}
                  defaultValue={"video"}
                  variant="outlined"
                  select
                  onChange={(e, v) => {
                    setAddMedia({ ...addMedia, type: e.target.value });
                  }}
                >
                  <MenuItem key={"video"} value={"video"}>
                    {t("video")}
                  </MenuItem>
                  <MenuItem key={"image"} value={"image"}>
                    {t("image")}
                  </MenuItem>
                </TextField>
                <TextField
                  variant="outlined"
                  label={"Google Drive Link"}
                  onChange={(e, v) => {
                    setAddMedia({ ...addMedia, url: e.target.value });
                  }}
                />
                <Button
                  variant="outlined"
                  onClick={() => {
                    var arr = [
                      ...(selectedTicket?.mediaLinks
                        ? selectedTicket.mediaLinks
                        : []),
                      ...(ticketData?.mediaLinks ? ticketData.mediaLinks : []),
                    ];
                    arr.push(addMedia);
                    setTicketData({ ...ticketData, mediaLinks: arr });
                    setAddMedia();
                  }}
                >
                  {t("add")}
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setAddMedia();
                  }}
                >
                  {t("cancel")}
                </Button>
              </>
            ) : (
              <Button
                onClick={() => {
                  setAddMedia({ type: "video" });
                }}
                variant="outlined"
              >
                {t("ticketList.addDriveMedia")}
              </Button>
            )}
          </Grid>
          <Grid item={12} container>
            {ticketData?.mediaLinks
              ? ticketData.mediaLinks.map((k, i) =>
                  k.type === "video" ? (
                    <Grid item key={i}>
                      <iframe
                        src={formatGoogleDriveMediaLink(k.url, k.type)}
                        width={100}
                        height={100}
                        title="MediaLink"
                      />
                      <IconButton
                        onClick={() => {
                          setTicketData({
                            ...ticketData,
                            mediaLinks: ticketData.mediaLinks.filter(
                              (m) => m.url !== k.url
                            ),
                          });
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Grid>
                  ) : k.type === "image" ? (
                    <Grid item key={i}>
                      <a
                        href={formatGoogleDriveMediaLink(k.url)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={formatGoogleDriveMediaLink(k.url, k.type)}
                          width={100}
                          alt="MediaImage"
                        />
                      </a>
                      <IconButton
                        onClick={() => {
                          setTicketData({
                            ...ticketData,
                            mediaLinks: ticketData.mediaLinks.filter(
                              (m) => m.url !== k.url
                            ),
                          });
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Grid>
                  ) : null
                )
              : null}
            {selectedTicket?.mediaLinks && !ticketData?.mediaLinks
              ? selectedTicket.mediaLinks.map((k, i) =>
                  k.type === "video" ? (
                    <Grid item key={i}>
                      <iframe
                        src={formatGoogleDriveMediaLink(k.url, k.type)}
                        width={100}
                        height={100}
                        title="MediaVideo"
                      />
                      <IconButton
                        onClick={() => {
                          setTicketData({
                            ...ticketData,
                            mediaLinks: selectedTicket.mediaLinks.filter(
                              (m) => m.url !== k.url
                            ),
                          });
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Grid>
                  ) : k.type === "image" ? (
                    <Grid item key={i}>
                      <a
                        href={formatGoogleDriveMediaLink(k.url)}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={formatGoogleDriveMediaLink(k.url, k.type)}
                          width={100}
                          alt="MediaImage"
                        />
                      </a>
                      <IconButton
                        onClick={() => {
                          setTicketData({
                            ...ticketData,
                            mediaLinks: selectedTicket.mediaLinks.filter(
                              (m) => m.url !== k.url
                            ),
                          });
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Grid>
                  ) : null
                )
              : null}
          </Grid>
          <Grid container item={6} spacing={3}>
            <Grid item>
              <Button
                variant="outlined"
                onClick={() => {
                  if (selectedTicket) {
                    editTicket();
                    return;
                  }
                  submitTicket();
                }}
              >
                {t("submit")}
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={handleClose}>
                {t("cancel")}
              </Button>
            </Grid>
            {selectedTicket && !confirmDelete ? (
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setConfirmDelete(true);
                  }}
                >
                  {t("delete")}
                </Button>
              </Grid>
            ) : null}
            {confirmDelete ? (
              <Grid item>
                <Typography>{t("areYouSure")}?</Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    editTicket(true);
                    setConfirmDelete(false);
                  }}
                >
                  {t("confirmDelete")}
                </Button>
                <Button
                  variant="outlined"
                  onClick={() => {
                    setConfirmDelete(false);
                  }}
                >
                  {t("cancel")}
                </Button>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  ) : null;
}

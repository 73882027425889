import { Grid, Paper, Typography } from "@material-ui/core";
import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import Title from "../../Components/Title";
import { ECABIN, getToken, requestErrorHandler } from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import { useStyles } from "../../Utils/Styles";
import lonlat from "./country-codes-lat-lon.json";
import gg from "./features.json";

const server = EnvSettings.server;

const MapChart = ({ setTooltipContent }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const [deviceLocations, setDeviceLocations] = useState([]);
  const [twoCharacterCodes, setTwoCharacterCodes] = useState([]);
  const [tooltipVals, setTooltipVals] = useState([]);
  const getLocations = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
      }),
    };
    fetch(server + "/get_locations", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          setDeviceLocations(result.locations);
        }
      });
  };

  const getMarker = (loc) => {
    if (!loc.location.substring(3)) {
      return null;
    }
    var x = lonlat.ref_country_codes.find(
      (obj) => obj.state && obj?.state.includes(loc.location.substring(3))
    );
    if (x) {
      return (
        <Marker coordinates={[x.longitude, x.latitude]} key={x.latitude}>
          <circle r={2} fill="lightblue" />
        </Marker>
      );
    }
    return null;
  };

  useEffect(() => {
    if (twoCharacterCodes) {
      getLocations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [twoCharacterCodes]);

  useEffect(() => {
    const getCodes = () => {
      gg.objects.world.geometries.forEach((el, i) => {
        if (
          lonlat.ref_country_codes.find(
            (obj) => obj.country === el.properties.name
          )?.alpha2
        ) {
          gg.objects.world.geometries[i].id = lonlat.ref_country_codes.find(
            (obj) => obj.country === el.properties.name
          ).alpha2;
        }
      });
      setTwoCharacterCodes(gg);
    };
    getCodes();
  }, []);

  return deviceLocations.length && twoCharacterCodes ? (
    <Grid container spacing={3}>
      <Grid item container justify="center" xs={12}>
        <Grid item xl={6} lg={5} md={4} sm={4} xs={7}>
          <Title>
            {ECABIN} {t("locations")}
          </Title>
        </Grid>
      </Grid>
      <Grid item xl={6} lg={8} md={10} sm={12} xs={12}>
        <ComposableMap>
          <Geographies geography={twoCharacterCodes}>
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onMouseEnter={() => {
                    setTooltipVals(
                      deviceLocations.filter((obj) => {
                        return obj.location.substring(0, 2).includes(geo.id);
                      })
                    );
                  }}
                  onMouseLeave={() => {
                    setTooltipVals([]);
                  }}
                  style={{
                    default: {
                      fill: deviceLocations.find((obj) =>
                        obj.location.substring(0, 2).includes(geo.id)
                      )
                        ? "#2b7ab7"
                        : "#D6D6DA",
                      outline: "none",
                    },
                    hover: {
                      fill: "#3d57a5",
                      outline: "none",
                    },
                    pressed: {
                      fill: "#E42",
                      outline: "none",
                    },
                  }}
                />
              ))
            }
          </Geographies>
          {deviceLocations.map((loc) => getMarker(loc))}
        </ComposableMap>
      </Grid>
      {tooltipVals.length ? (
        <Grid item sm={2} xs={12}>
          <Paper className={classes.paper}>
            <Title>
              {
                twoCharacterCodes.objects.world.geometries.find((obj) =>
                  tooltipVals[0].location.substring(0, 2).includes(obj.id)
                ).properties.name
              }
            </Title>
            {tooltipVals.map((val) => (
              <Typography key={val.location}>
                {val.location.substring(3) !== ""
                  ? val.location.substring(3)
                  : val.location}{" "}
                {val.cnt}
              </Typography>
            ))}
          </Paper>
        </Grid>
      ) : null}
    </Grid>
  ) : null;
};

export default memo(MapChart);

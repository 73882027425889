import { Collapse, IconButton } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ReportProblem from "@material-ui/icons/ReportProblem";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import DeviceDialog from "../../Components/DeviceDialog";
import DeviceStatusBadge from "../../Components/DeviceStatusBadge";
import SortableTable from "../../Components/SortableTable";
import Title from "../../Components/Title";
import {
  ECABIN,
  USER_GROUPS,
  checkRequiredPermissions,
  formatTimeWithTZOffset,
  getDeviceType,
  getLCLocation,
  getLCState,
  getLatestLifeCycleState,
  getToken,
  requestErrorHandler,
} from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import { useStyles } from "../../Utils/Styles";

const server = EnvSettings.server;

const formatClientInfo = (data) => {
  if (!data) return "";
  if (Object.keys(data).length) {
    return Object.values(data).join("/");
  }
  return Object.values(JSON.parse(data)).join(" / ");
};

function formatDeviceData(data) {
  var arr = [];
  data.forEach((element) => {
    var lc =
      element.LifeCycleStates && element.LifeCycleStates.length
        ? getLatestLifeCycleState(element.LifeCycleStates)
        : undefined;
    arr.push({
      id: element.id,
      serialNumber: element.serialNumber,
      createdAt: lc ? formatTimeWithTZOffset(lc?.createdAt, ECABIN) : "",
      note: lc?.Notes?.length ? lc?.Notes.map((n) => n.text).join("/") : "",
      model: element.model,
      location: lc?.location || element.location,
      deviceType: getDeviceType(element.serialNumber),
      user: lc?.User?.fullName,

      currentLCState: lc ? getLCState(lc?.state) : "",
      currentLCLocation: lc ? getLCLocation(lc?.location) : "",
      clientInfo: lc ? formatClientInfo(lc?.clientInfo) : "",

      LifeCycleStates: element.LifeCycleStates,
    });
  });
  return arr;
}

export default function MissingDevices(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [collapse, setCollapse] = useState(false);
  const [searchList, setSearchList] = useState();
  const [selectedTransfer, setSelectedTransfer] = useState();
  const handleClose = () => {
    setSelectedTransfer();
  };
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const classes = useStyles();

  useEffect(() => {
    const getDevices = () => {
      setSearchList();
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: getToken(),
          lcState: "missing",
          lcLocation: "unknown",
          requireLCS: false,
        }),
      };
      fetch(server + "/get_admin_devices", requestOptions)
        .then((res) => res.json())
        .then((result) => {
          requestErrorHandler(result, dispatch);
          if (!result.error) {
            var a = formatDeviceData(result.devices);
            setSearchList(a);
            setRowsPerPage(a.length);
          }
        });
    };
    getDevices();
  }, [dispatch]);

  return searchList && searchList.length ? (
    <Paper className={classes.paper}>
      <DeviceDialog
        data={selectedTransfer}
        handleClose={handleClose}
        header={t("edit")}
        quickEditOptions={["return", "demo", "sold"]}
      />
      <Title>
        <ReportProblem
          style={{
            color: "red",
            verticalAlign: "sub",
            marginRight: 5,
          }}
        />
        Kateissa olevat laitteet - {searchList?.length} kpl
        <IconButton
          onClick={() => {
            setCollapse(!collapse);
          }}
          style={{ position: "absolute", right: 15 }}
        >
          {collapse ? (
            <ExpandLessIcon></ExpandLessIcon>
          ) : (
            <ExpandMoreIcon></ExpandMoreIcon>
          )}
        </IconButton>
      </Title>

      <Collapse in={collapse}>
        {props.width === "xs" && searchList && searchList.length ? (
          searchList
            .sort((a, b) =>
              a.createdAt > b.createdAt ? -1 : b.createdAt > a.createdAt ? 1 : 0
            )
            .map((val, i) => (
              <DeviceStatusBadge
                onClick={setSelectedTransfer}
                data={val}
                key={i}
                noClientInfo={true}
              />
            ))
        ) : props.width !== "xs" && searchList ? (
          <SortableTable
            headCells={[
              {
                id: "serialNumber",
                numeric: false,
                disablePadding: false,
                label: t("serialNumber"),
              },
              {
                id: "createdAt",
                numeric: false,
                disablePadding: false,
                label: "Timestamp",
              },
              {
                id: "note",
                numeric: false,
                disablePadding: false,
                label: t("sessionDetails.notes"),
              },
            ]}
            disablePagination={true}
            defaultSortRow={"serialNumber"}
            handleRowClick={(e, v) => {
              setSelectedTransfer(e);
            }}
            // handleScrollClick = {showDeviceNewTab,
            tableData={searchList}
            rowsPerPage={rowsPerPage}
            checkBox={checkRequiredPermissions([USER_GROUPS.ADMIN])}
          />
        ) : undefined}
      </Collapse>
    </Paper>
  ) : null;
}

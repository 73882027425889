import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  withWidth,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import EditIcon from "@material-ui/icons/Edit";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import BrowseContactsDialog from "../../Components/Contacts/BrowseContactsDialog";
import ContactInfoBadge from "../../Components/Contacts/ContactInfoBadge";
import RedExitButton from "../../Components/RedExitButton";
import Title from "../../Components/Title";
import {
  ECABIN,
  USER_GROUPS,
  checkAdmin,
  checkRequiredPermissions,
  formatClientInfoString,
  formatTimeWithTZOffset,
  getLCLocation,
  getLCState,
  getLatestLifeCycleState,
  getToken,
  lifeCycleStates,
  readClientDataFromLCS,
  requestErrorHandler,
  warehouseLocation,
} from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import { showPopupSnackbar } from "../../redux/actions/snackbarActions";
const server = EnvSettings.server;

function EditDialog({ editExisting, setEditExisting }) {
  const { t } = useTranslation();
  const [comment, setComment] = useState();
  const [clientInfoObject, setClientInfoObject] = useState({});
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [original, setOriginal] = useState();
  const dispatch = useDispatch();

  const deleteTransfer = () => {
    setSubmitting(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        transferData: editExisting,
      }),
    };
    fetch(server + "/delete_transfer", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);

        // Reload on success
        if (!result.error) {
          dispatch(showPopupSnackbar(result));
          setTimeout(() => {
            window.location.reload();
          }, 1200);
        } else {
          setSubmitting(false);
        }
      })
      .catch((e) => {});
  };

  const handleClose = () => {
    setEditExisting();
    setOriginal();
    setClientInfoObject({});
    setConfirmDelete(false);
    setComment();
  };
  /**
   * If the data has been edited return true
   * @param {} orig
   * @param {} data
   * @param {} co
   * @returns {boolean}
   */
  const checkForEdits = (orig, data, co) => {
    if (orig && data && JSON.stringify(orig) !== JSON.stringify(data)) {
      return true;
    }
    if (!orig.clientInfo && Object.keys(co).length) {
      return true;
    }
    if (
      co &&
      JSON.stringify(readClientDataFromLCS(data)) !== JSON.stringify(co)
    ) {
      return true;
    }
    return false;
  };

  const editLCS = () => {
    if (!editExisting) {
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        deviceid: editExisting.DeviceId,
        lifecyclestate: {
          ...editExisting,
          clientInfo: Object.keys(clientInfoObject).length
            ? clientInfoObject
            : undefined,
        },
        note: comment,
        update_existing: true,
      }),
    };
    fetch(server + "/edit_lifecyclestate", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);

        if (!result.error) {
          dispatch(showPopupSnackbar(result));
          setTimeout(() => {
            window.location.reload();
          }, 1200);
        } else {
          setSubmitting(false);
        }
      });
  };

  useEffect(() => {
    if (
      editExisting &&
      (editExisting.clientInfo ||
        (editExisting.LifeCycleStates && editExisting.LifeCycleStates.length))
    ) {
      if (editExisting.LifeCycleStates && editExisting.LifeCycleStates.length) {
        var lcs = getLatestLifeCycleState(editExisting.LifeCycleStates);
        setClientInfoObject(readClientDataFromLCS(lcs));
      } else {
        setClientInfoObject(readClientDataFromLCS(editExisting));
      }
    }
    if (editExisting && !original) {
      setOriginal(JSON.parse(JSON.stringify(editExisting)));
    }
  }, [editExisting, original]);

  return original && editExisting ? (
    <Dialog
      open={!!editExisting}
      onClose={handleClose}
      maxWidth={"lg"}
      fullWidth
    >
      <DialogTitle>
        {t("lifeCycleManagement.editLifeCycleState")}{" "}
        {formatTimeWithTZOffset(editExisting?.createdAt, ECABIN)}
        <RedExitButton onClick={handleClose} />
      </DialogTitle>
      <DialogContent style={{ overflow: "hidden" }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography>
              {t("createdBy")} : {editExisting.User?.fullName || "Automatic"}
            </Typography>
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              variant="outlined"
              select
              fullWidth
              label={t("deviceList.lcState")}
              onChange={(e, v) => {
                var obj = { ...editExisting, state: e.target.value };
                if (
                  e.target.value === "dispatched" ||
                  e.target.value === "demo"
                ) {
                  obj = { ...obj, location: "client" };
                } else if (e.target.value === "missing") {
                  obj = { ...obj, location: "unknown" };
                }
                setEditExisting(JSON.parse(JSON.stringify(obj)));
              }}
              defaultValue={editExisting ? editExisting.state : ""}
            >
              {lifeCycleStates.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item sm={4} xs={12}>
            {editExisting &&
            (editExisting.state === "dispatched" ||
              editExisting.state === "demo") ? (
              <TextField
                variant="outlined"
                fullWidth
                disabled
                label={t("deviceList.lcLocation")}
                value={"client"}
              ></TextField>
            ) : editExisting && editExisting.state === "missing" ? (
              <TextField
                variant="outlined"
                fullWidth
                disabled
                label={t("deviceList.lcLocation")}
                value={"unknown"}
              ></TextField>
            ) : editExisting ? (
              <TextField
                variant="outlined"
                select
                fullWidth
                defaultValue={
                  editExisting &&
                  editExisting.location !== "client" &&
                  editExisting !== "unknown"
                    ? editExisting.location
                    : ""
                }
                label={t("deviceList.lcLocation")}
                onChange={(e, v) => {
                  var obj = { ...editExisting, location: e.target.value };
                  setEditExisting(JSON.parse(JSON.stringify(obj)));
                }}
              >
                {warehouseLocation.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            ) : null}
          </Grid>
          <Grid item xs={12} sm={4} style={{ position: "relative" }}>
            {editExisting?.state === "dispatched" ||
            editExisting?.state === "demo" ? (
              <BrowseContactsDialog
                setClientInfoObject={setClientInfoObject}
                activeObject={clientInfoObject}
              />
            ) : null}
            {editExisting?.state === "dispatched" ||
            editExisting?.state === "demo" ? (
              <ContactInfoBadge
                clientInfoObject={clientInfoObject}
                clearClientInfo={() => {
                  setClientInfoObject({});
                }}
                // Min width attribute prevents the trashicon to be cut in half in the view
                containerStyles={{ position: "absolute", minWidth: 230 }}
              />
            ) : null}
          </Grid>
          <Grid item sm={4} xs={12}>
            <TextField
              fullWidth
              variant="outlined"
              select
              defaultValue={editExisting.quality || 1}
              label={"Quality"}
              onChange={(e) => {
                if (e.target && e.target.value) {
                  setEditExisting({ ...editExisting, quality: e.target.value });
                  return;
                }
                setEditExisting({ ...editExisting, quality: undefined });
              }}
            >
              <MenuItem value={1}>1. Class - A</MenuItem>
              <MenuItem value={2}>2. Class - B</MenuItem>
            </TextField>
          </Grid>
          <Grid item sm={4} xs={12}>
            {editExisting.Notes
              ? editExisting.Notes.map((n, i) => (
                  <Typography key={i}>
                    {formatTimeWithTZOffset(n.createdAt, ECABIN)} - {n.text}
                  </Typography>
                ))
              : null}
            <TextField
              fullWidth
              variant="outlined"
              label={t("note")}
              onChange={(e) => {
                setComment(e.target.value);
              }}
            ></TextField>
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              disabled={
                !checkForEdits(original, editExisting, clientInfoObject)
              }
              onClick={editLCS}
            >
              {t("submit")}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                setConfirmDelete(true);
              }}
            >
              {t("delete")}
            </Button>
          </Grid>
          {confirmDelete ? (
            <Grid item xs={12}>
              <Typography>{t("areYouSure")}?</Typography>
              <Button
                variant="contained"
                color="secondary"
                onClick={deleteTransfer}
                endIcon={
                  submitting ? (
                    <CircularProgress style={{ width: 20, height: 20 }} />
                  ) : null
                }
              >
                {t("confirmDelete")}
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </DialogContent>
    </Dialog>
  ) : null;
}

function LifeCycleState({ deviceData, updateLocalDevice, width }) {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [hovering, setHovering] = useState();
  const [editExisting, setEditExisting] = useState();
  const [comment, setComment] = useState();
  const [currentLCState, setCurrentLCState] = useState(
    deviceData && deviceData.LifeCycleStates.length
      ? getLatestLifeCycleState(deviceData?.LifeCycleStates)
      : undefined
  );
  const [devLifeCycleStates, setDevLifeCycleStates] = useState(
    deviceData && deviceData.LifeCycleStates ? deviceData.LifeCycleStates : []
  );
  const [editActive, setEditActive] = useState(false);
  const [clientInfoObject, setClientInfoObject] = useState(
    deviceData && deviceData.LifeCycleStates.length
      ? readClientDataFromLCS(
          getLatestLifeCycleState(deviceData?.LifeCycleStates)
        ) ?? {}
      : {}
  );
  const getLabelString = (data) => {
    if (!data) {
      return;
    }
    var st = formatTimeWithTZOffset(data.createdAt, ECABIN);

    if (data.state) {
      st = st + " - " + getLCState(data.state);
    }
    if (data.location === "client" && data.clientInfo) {
      st = st + " - " + formatClientInfoString(readClientDataFromLCS(data));
    }
    if (data.location !== "client") {
      st = st + " - " + getLCLocation(data.location);
    }
    if (data?.Notes?.length) {
      data.Notes.forEach((n) => {
        st = st + " - " + n.text;
      });
    }
    return st;
  };

  const saveChanges = () => {
    if (!currentLCState || !deviceData.id) {
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        deviceid: deviceData.id,
        lifecyclestate: {
          ...currentLCState,
          clientInfo: Object.keys(clientInfoObject).length
            ? clientInfoObject
            : undefined,
        },
        note: comment,
      }),
    };
    fetch(server + "/edit_lifecyclestate", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          dispatch(showPopupSnackbar(result));
        }

        if (result.lcs) {
          if (comment) {
            result.lcs = { ...result.lcs, Notes: [{ text: comment }] };
          }
          setDevLifeCycleStates([...devLifeCycleStates, result.lcs]);
          setCurrentLCState(result.lcs);
          if (updateLocalDevice) {
            updateLocalDevice([...devLifeCycleStates, result.lcs]);
          }
        }
      });
  };

  return currentLCState === false ? null : (
    <Grid container spacing={3}>
      {editExisting ? (
        <EditDialog
          editExisting={editExisting}
          setEditExisting={setEditExisting}
        />
      ) : null}
      <Grid item xs={12}>
        <Title>{t("deviceList.lcState")}</Title>
      </Grid>
      <Grid item sm={4} xs={12}>
        <TextField
          variant="outlined"
          select
          fullWidth
          disabled={!editActive}
          label={t("deviceList.lcState")}
          onChange={(e, v) => {
            var obj = { ...currentLCState, state: e.target.value };
            if (e.target.value === "dispatched" || e.target.value === "demo") {
              obj = { ...obj, location: "client" };
            } else if (e.target.value === "missing") {
              obj = { ...obj, location: "unknown" };
            }
            setCurrentLCState(JSON.parse(JSON.stringify(obj)));
          }}
          defaultValue={currentLCState ? currentLCState.state : ""}
        >
          {lifeCycleStates.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item sm={4} xs={12}>
        {currentLCState &&
        (currentLCState.state === "dispatched" ||
          currentLCState.state === "demo") ? (
          <TextField
            variant="outlined"
            fullWidth
            disabled
            label={t("deviceList.lcLocation")}
            value={"client"}
          ></TextField>
        ) : currentLCState && currentLCState.state === "missing" ? (
          <TextField
            variant="outlined"
            fullWidth
            disabled
            label={t("deviceList.lcLocation")}
            value={"unknown"}
          ></TextField>
        ) : (
          <TextField
            variant="outlined"
            select
            fullWidth
            disabled={!editActive}
            defaultValue={currentLCState ? currentLCState.location : ""}
            label={t("deviceList.lcLocation")}
            onChange={(e, v) => {
              var obj = { ...currentLCState, location: e.target.value };
              setCurrentLCState(JSON.parse(JSON.stringify(obj)));
            }}
          >
            {warehouseLocation.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Grid>
      <Grid item xs={12} sm={4} style={{ position: "relative" }}>
        {currentLCState?.state === "dispatched" ||
        currentLCState?.state === "demo" ? (
          <BrowseContactsDialog
            setClientInfoObject={setClientInfoObject}
            activeObject={clientInfoObject}
          />
        ) : null}
        {currentLCState?.state === "dispatched" ||
        currentLCState?.state === "demo" ? (
          <ContactInfoBadge
            clientInfoObject={clientInfoObject}
            clearClientInfo={
              editActive
                ? () => {
                    setClientInfoObject({});
                  }
                : undefined
            }
            containerStyles={{ position: "absolute" }}
            onClick={() => {
              history.push(
                (checkAdmin() ? "/cpanel" : "") +
                  "/contacts/" +
                  (clientInfoObject.companyId
                    ? "company/" + clientInfoObject.companyId
                    : "contact/" + clientInfoObject.contactId)
              );
            }}
          />
        ) : null}
      </Grid>
      <Grid
        item
        sm={4}
        xs={12}
        // Add style attribute to prevent the Comment bar to be overlayed by the relative contactinfo badge
        style={width === "xs" ? { marginTop: 90 } : {}}
      >
        <TextField
          fullWidth
          disabled={!editActive}
          variant="outlined"
          label={t("note")}
          onChange={(e) => {
            setComment(e.target.value);
          }}
        ></TextField>
      </Grid>
      <Grid item>
        {!editActive ? (
          <Button
            variant="outlined"
            onClick={() => {
              setEditActive(true);
            }}
          >
            {t("edit")}
          </Button>
        ) : (
          <Button variant="outlined" onClick={saveChanges}>
            {t("save")}
          </Button>
        )}
      </Grid>
      <Grid item>
        <Button
          variant="outlined"
          disabled={!editActive}
          onClick={() => {
            setEditActive(false);
          }}
        >
          {t("cancel")}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Title>{t("history")}</Title>
        <Stepper nonLinear activeStep={0} orientation="vertical">
          {deviceData
            ? devLifeCycleStates
                .sort((a, b) => (a.id > b.id ? -1 : 1))
                .map((s, i) => (
                  <Step
                    key={s.createdAt}
                    onMouseOver={() => {
                      setHovering(s.createdAt);
                    }}
                    onMouseOut={() => {
                      setHovering();
                    }}
                  >
                    <StepLabel
                      icon={devLifeCycleStates.length - i}
                      // This relative style enables the icon button absolute value to be correctly interpreted
                      style={{ position: "relative" }}
                    >
                      {getLabelString(s)}
                      {checkRequiredPermissions([USER_GROUPS.ADMIN]) ? (
                        <IconButton
                          style={{
                            display: hovering === s.createdAt ? "" : "none",
                            position: "absolute",
                            marginTop: -10,
                          }}
                          onClick={() => {
                            setEditExisting(s);
                          }}
                        >
                          <EditIcon style={{ fontSize: 20 }} />
                        </IconButton>
                      ) : null}
                    </StepLabel>
                  </Step>
                ))
            : null}
        </Stepper>
      </Grid>
    </Grid>
  );
}

export default withWidth()(LifeCycleState);

import { Paper, Typography, withWidth } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import BusinessIcon from "@material-ui/icons/Business";
import LanguageIcon from "@material-ui/icons/Language";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  formatContacts,
  getToken,
  requestErrorHandler,
} from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";

const server = EnvSettings.server;

const contactFields = ["contactName", "companyName", "location"];

function ContactSearchAutocomplete({
  onChange,
  allowNewContacts,
  key,
  scrollIntoView,
  freeSearchFunction,
  field,
  width,
  onlyCompanies,
  onlyContacts,
  noDescriptionText,
}) {
  const dispatch = useDispatch();
  const [contactsList, setContactsList] = useState([]);
  const [loadingContactsList, setLoadingContactsList] = useState(false);
  const [lastType, setLastType] = useState();
  const { t } = useTranslation();

  const getContacts = (ser_val) => {
    if (!ser_val) {
      return;
    }
    setLoadingContactsList(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        search_value: ser_val,
        only_companies: onlyCompanies,
        only_contacts: onlyContacts,
      }),
    };
    fetch(server + "/get_contacts", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          setContactsList(formatContacts(result));
        }
        setLoadingContactsList(false);
      });
  };
  return (
    <Grid item xs={12}>
      <Typography>
        {noDescriptionText
          ? null
          : t("contactSearchAutocomplete.searchDescription")}
      </Typography>
      <Autocomplete
        key={key}
        id="Contacts"
        options={[...contactsList]}
        fullWidth
        getOptionLabel={(option) => Object.values(option)[0]}
        PaperComponent={(props) => (
          <Paper style={{ border: "2px solid #3d57a5" }} {...props} />
        )}
        renderOption={(option, { selected }) =>
          contactFields.find((c) => option[c]) ? (
            <Grid
              container
              direction="column"
              style={{ borderBottom: "1px solid gray" }}
            >
              {contactFields.map((p, k) =>
                option[p] ? (
                  <Grid key={k} item container spacing={1} alignItems="center">
                    <Grid item>
                      <Typography style={{ color: "gray" }}>
                        {p === "companyName" ? (
                          <BusinessIcon />
                        ) : p === "contactName" ? (
                          <AccountCircleIcon />
                        ) : (
                          <LanguageIcon />
                        )}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography
                        style={{
                          verticalAlign: "text-top",
                          fontSize:
                            width === "xs" && option[p].length > 20 ? 12 : 15,
                        }}
                      >
                        {option[p]}
                      </Typography>
                    </Grid>
                  </Grid>
                ) : null
              )}
            </Grid>
          ) : (
            <Typography>
              + {t("contactSearchAutocomplete.addNewContact")}
            </Typography>
          )
        }
        filterOptions={(options, params) => {
          // console.log(options);
          // const filtered = filter(options, {
          //   ...params,
          //   inputValue: params.inputValue.replace(" ", ""),
          // });
          // console.log(filtered);
          const filtered = [...options];
          // Suggest the creation of a new value
          if (params.inputValue !== "" && !filtered.length) {
            var v = params.inputValue.replace(" ", "");
            filtered.push({
              inputValue: "Add Contact " + v,
              [field]: v,
            });
          }

          return filtered;
        }}
        loading={loadingContactsList}
        freeSolo={allowNewContacts}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t("search")}
            placeholder={
              t("name") + "/" + t("companyName") + "/" + t("country")
            }
            variant="outlined"
          />
        )}
        onFocus={() => {
          if (scrollIntoView) {
            scrollIntoView();
          }
        }}
        onChange={onChange}
        onChangeCapture={(e) => {
          var v;
          if (e.target.value) {
            v = e.target.value.toLowerCase().replace(/\s/g, "");
          }
          setLastType(Date.now());
          setTimeout(() => {
            if (lastType && Date.now() - lastType > 200) {
              getContacts(v);
            }
          }, 200);
          if (freeSearchFunction) {
            freeSearchFunction(v);
          }
          // getClientInfo(v);
        }}
        onBlurCapture={allowNewContacts ? onChange : () => {}}
      />
    </Grid>
  );
}

export default withWidth()(ContactSearchAutocomplete);

import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearSnackbar } from "../redux/actions/snackbarActions";

export default function PopupSnackbar() {
  const dispatch = useDispatch();

  const { snackbarObject, snackbarOpen } = useSelector(
    (state) => state.uiReducer
  );

  function handleClose() {
    dispatch(clearSnackbar());
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={snackbarObject.error ? "error" : "success"}
      >
        {typeof snackbarObject.message === "object"
          ? JSON.stringify(snackbarObject.message)
          : snackbarObject.message}
      </Alert>
    </Snackbar>
  );
}

import { Grid, Link, Paper, Typography } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import { useTheme } from "@material-ui/core/styles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  Bar,
  ComposedChart,
  Label,
  Line,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  checkAdmin,
  formatChartData,
  formatTimeWithTZOffset,
  getDeviceType,
  getMeasurementName,
  getSessionType,
  hslToHex,
} from "../Utils/Common";
import { useStyles } from "../Utils/Styles";
import DropdownMenu from "./DropdownMenu";
import Title from "./Title";

function CustomTooltip(props) {
  const classes = useStyles();
  const device = props?.device;
  const tooltipData =
    props?.payload && props?.payload[0] && props?.payload[0]?.payload
      ? props.payload[0].payload
      : undefined;
  if (props.active && tooltipData) {
    return (
      <Paper
        className={classes.paper}
        style={{
          width:
            window.innerWidth > 1000
              ? Math.round(window.innerWidth * 0.65)
              : "100%",
          border: "1px solid #000",
        }}
      >
        <Grid container item spacing={3} xs={12}>
          {Object.keys(tooltipData).map((d, ii) =>
            d === "activeDevices" ? (
              Object.keys(tooltipData[d]).map((ad, i) => (
                <Grid item key={i} xs={3}>
                  <Typography>{`${ad}: ${tooltipData[d][ad]}`}</Typography>
                </Grid>
              ))
            ) : d === "pressure" ? (
              <Grid item key={ii} xs={3}>
                <Typography>{`${getMeasurementName(d, device)}: ${
                  tooltipData[d] / 10
                }`}</Typography>
              </Grid>
            ) : (
              <Grid item key={ii} xs={3}>
                <Typography>{`${getMeasurementName(d, device)}: ${
                  tooltipData[d]
                }`}</Typography>
              </Grid>
            )
          )}
        </Grid>
      </Paper>
    );
  }
  return null;
}

function TemperatureChart({ chartData }) {
  const [exclude, setExclude] = useState([]);

  const theme = useTheme();
  const { t } = useTranslation();
  const history = useHistory();
  let cpanel = checkAdmin();

  const getLines = () => {
    var arr = [];
    var obj = {};
    formatChartData(
      chartData.Measurements,
      getDeviceType(chartData.Device.serialNumber)
    ).forEach((data) => {
      obj = { ...obj, ...data };
    });
    Object.keys(obj).map((key, index) =>
      key !== "timeStamp" && key !== "pressure"
        ? arr.push(
            <Line
              key={index}
              dot={false}
              dataKey={key}
              stroke={hslToHex((360 / 10) * index, 100, 30)}
            />
          )
        : null
    );
    return arr;
  };

  const excludeCallback = (newExclude) => {
    setExclude(newExclude);
  };

  return chartData ? (
    <React.Fragment>
      <Hidden xsDown>
        <Grid container direction="row" justify="space-between">
          <Grid item>
            <Link
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (cpanel) {
                  history.push("/cpanel/devices/" + chartData.Device.id);
                } else {
                  history.push("/devices/" + chartData.Device.id);
                }
                //
              }}
            >
              {" "}
              <Title>{chartData.Device.serialNumber}</Title>{" "}
            </Link>
          </Grid>
          <Grid item>
            <DropdownMenu
              data={chartData.Measurements[0]}
              device={getDeviceType(chartData.Device.serialNumber)}
              callback={excludeCallback}
              header={t("sessionDetails.selectLines")}
            />
          </Grid>
        </Grid>
        <Title>
          {getSessionType(
            getDeviceType(chartData.Device.serialNumber),
            chartData.type
          ) +
            " " +
            formatTimeWithTZOffset(
              chartData.startTime,
              getDeviceType(chartData.Device.serialNumber)
            )}
        </Title>
        <ResponsiveContainer>
          <ComposedChart
            data={formatChartData(
              chartData.Measurements,
              getDeviceType(chartData.Device.serialNumber),
              chartData.type,
              exclude
            )}
            margin={{
              top: 16,
              right: 16,
              bottom: 0,
              left: 24,
            }}
          >
            <XAxis dataKey="timeStamp" stroke={theme.palette.text.secondary} />
            <YAxis stroke={theme.palette.text.secondary}>
              <Label
                angle={270}
                position="left"
                style={{
                  textAnchor: "middle",
                  fill: theme.palette.text.primary,
                }}
              >
                {t("temperatureChart.temperature") + " (°C)"}
              </Label>
            </YAxis>
            <Tooltip
              active={true}
              position={{
                x:
                  window.innerWidth > 1280
                    ? Math.round(window.innerWidth * 0.18)
                    : 25,
                y: 470,
              }}
              wrapperStyle={{
                position: "fixed",
                // This ensures that the tooltip is always on top of every element
                zIndex: 9999,
              }}
              content={
                <CustomTooltip
                  device={getDeviceType(chartData.Device.serialNumber)}
                />
              }
            />
            <Bar
              type="monotone"
              dataKey="pressure"
              fill="rgba(0,0,0,0.2)"
              dot={false}
            />
            {getLines()}
            <ReferenceLine
              y={chartData.targetTemperature}
              label="Target"
              stroke="blue"
              strokeDasharray="3 3"
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Hidden>
      <Hidden smUp>
        <Title>{t("temperatureChart.landscape")}</Title>
      </Hidden>
    </React.Fragment>
  ) : null;
}

export default TemperatureChart;

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React from "react";
import { useTranslation } from "react-i18next";

export default function FindSerialNumbers({
  editedOrder,
  getDevices,
  setAutomaticSerNo,
  automaticSerNo,
}) {
  const { t } = useTranslation();
  return (
    <Dialog
      open={automaticSerNo}
      onClose={() => {
        setAutomaticSerNo(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t("orders.findSerial")}
      </DialogTitle>
      <DialogContent>
        <Typography>{t("orders.bClassNote")}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setAutomaticSerNo(false);
          }}
          color="primary"
          variant="outlined"
          autoFocus
        >
          {t("no")}
        </Button>
        <Button
          onClick={() => {
            editedOrder.genericDevices.forEach((e) => {
              if (e.sku.includes("_EC_W_")) {
                getDevices(
                  "ec",
                  {
                    lcState: "ready",
                    color: "White",
                    OrderId: null,
                    lcLocation: "mailhouse",
                    hideSecondClass: true,
                    hide3Module: true,
                  },
                  e.count -
                    (editedOrder.Devices
                      ? editedOrder.Devices.reduce(
                          (sum, d) =>
                            d.color.includes("White") ? sum + 1 : sum + 0,
                          0
                        )
                      : 0)
                );
              } else if (e.sku.includes("_EC_G_")) {
                getDevices(
                  "ec",
                  {
                    lcState: "ready",
                    color: "Grey",
                    OrderId: null,
                    lcLocation: "mailhouse",
                    hideSecondClass: true,
                    hide3Module: true,
                  },
                  e.count -
                    (editedOrder.Devices
                      ? editedOrder.Devices.reduce(
                          (sum, d) =>
                            d.color.includes("Grey") ? sum + 1 : sum + 0,
                          0
                        )
                      : 0)
                );
              }
            });
            setTimeout(() => {
              setAutomaticSerNo(false);
            }, 1000);
          }}
          color="primary"
          variant="contained"
        >
          {t("yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

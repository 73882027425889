import { Button, Grid, Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import GetAppIcon from "@material-ui/icons/GetApp";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import DateSelect from "../../Components/DateSelect";

import SortableTable from "../../Components/SortableTable";
import {
  ECABIN,
  XCRYO,
  formatTimeWithTZOffset,
  getDeviceType,
  getLastParameter,
  getSessionType,
  getToken,
  requestErrorHandler,
} from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import { useStyles } from "../../Utils/Styles";
const server = EnvSettings.server;

function formatSessionData(devicetype, data) {
  var arr = [];
  data.forEach((element) => {
    arr.push({
      ...element,
      id: element.id,
      startTime: formatTimeWithTZOffset(element.startTime, devicetype),
      type: getSessionType(devicetype, element.type),
      duration: element.duration,
      consumption:
        devicetype === XCRYO || devicetype === ECABIN
          ? undefined
          : element.consumption,
    });
  });
  return arr;
}

export default function SessionList(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [sessions, setSessions] = useState();
  const device_id = getLastParameter();
  const [date, setDate] = useState();
  const [limit, setLimit] = useState(500);
  const [deviceData, setDeviceData] = useState();
  const [sessionFilter, setSessionFilter] = useState([]);
  const [filteredList, setFilteredList] = useState();

  const dispatch = useDispatch();

  const history = useHistory();

  let handleRowClick = (data) => {
    if (window.location.href.includes("/cpanel/")) {
      history.push("/cpanel/sessions/" + data.id);
    } else {
      history.push("/sessions/" + data.id);
    }
  };

  const handleScrollClick = (event, id) => {
    if (window.location.href.includes("/cpanel/")) {
      window.open("/cpanel/sessions/" + id.id, "_blank");
    } else {
      window.open("/sessions/" + id.id, "_blank");
    }
  };

  function downloadJSONAsCSV(jsonData, date) {
    // Fetch JSON data from the endpoint
    // Convert JSON data to CSV
    if (!jsonData.length) {
      alert("No records");
      return;
    }
    let csvData = jsonToCsv(jsonData); // Add .items.data
    // Create a CSV file and allow the user to download it
    let blob = new Blob([csvData], { type: "text/csv" });
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement("a");
    a.href = url;
    a.download =
      jsonData[0].Device.serialNumber +
      "-SESSIONS_TABLE" +
      (date?.startDate ? "-" + date.startDate : "") +
      (date?.endDate ? "-" + date.endDate : "") +
      ".csv";
    document.body.appendChild(a);
    a.click();
  }

  function jsonToCsv(jsonData) {
    let csv = "";
    // Get the headers
    var firstRow = jsonData[0];
    // Add the data
    let headers = Object.keys(firstRow);
    headers.forEach((h, idx) => {
      if (!firstRow[h] || h === "id") {
        // Do nothing
      } else if (h.includes("Device")) {
      } else if (h === "createdAt" || h === "updatedAt") {
      } else {
        csv += h + (idx === headers.length - 1 ? "" : ";");
      }
    });
    csv += "\n";
    jsonData.forEach((a) => {
      headers.forEach((h, idx) => {
        if (!a[h] || h.includes("Device") || h === "id") {
        } else if (h === "createdAt" || h === "updatedAt") {
        } else {
          csv += a[h] + (idx === headers.length - 1 ? "" : ";");
        }
      });
      csv += "\n";
    });
    return csv;
  }

  useEffect(() => {
    setSessions();
    const getSessions = () => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: getToken(),
          limit: limit,
          deviceid: device_id,
          date: date,
        }),
      };
      fetch(server + "/get_sessions", requestOptions)
        .then((res) => res.json())
        .then((result) => {
          requestErrorHandler(result, dispatch);
          if (!result.error) {
            if (result.sessions.length !== 0) {
              setSessions(
                formatSessionData(
                  getDeviceType(result.sessions[0].Device.serialNumber),
                  result.sessions
                )
              );
              setDeviceData(result.sessions[0].Device);
            }
          }
        });
    };
    getSessions();
  }, [device_id, dispatch, date, limit]);

  const handleFilter = (event, newFormats) => {
    setSessionFilter(newFormats);
  };

  useEffect(() => {
    if (sessions && sessionFilter.length) {
      setFilteredList(
        sessions.filter((o) => sessionFilter.find((s) => s === o.type))
      );
    } else if (sessions) {
      setFilteredList(sessions);
    }
  }, [sessionFilter, sessions]);

  return (
    <Paper style={{ marginTop: 30 }}>
      <DateSelect date={date} setDate={setDate} setLimit={setLimit} />
      <Grid
        container
        spacing={3}
        style={{ marginTop: 10 }}
        justify="center"
        alignItems="center"
      >
        <Grid item>
          {date || !date ? (
            <Button
              onClick={() => {
                downloadJSONAsCSV(filteredList, date);
              }}
              variant="outlined"
              endIcon={<GetAppIcon />}
            >
              {t("download") + " .csv"}
            </Button>
          ) : null}
        </Grid>
        <Grid item>
          <Typography>{t("sessionList.filterOptions")}</Typography>
        </Grid>
        <Grid item>
          <ToggleButtonGroup value={sessionFilter} onChange={handleFilter}>
            {[...Array(100).keys()].map((i) =>
              getSessionType(getDeviceType(deviceData?.serialNumber), i) ? (
                <ToggleButton
                  key={i}
                  classes={{ selected: classes.selected }}
                  value={getSessionType(
                    getDeviceType(deviceData?.serialNumber),
                    i
                  )}
                >
                  {getSessionType(getDeviceType(deviceData?.serialNumber), i)}
                </ToggleButton>
              ) : null
            )}
          </ToggleButtonGroup>
        </Grid>
        <Grid item>
          <Typography>
            {t("sessionList.itemCount") + " " + filteredList?.length}
          </Typography>
        </Grid>
      </Grid>
      {filteredList && deviceData ? (
        <SortableTable
          headCells={[
            {
              id: "startTime",
              numeric: false,
              disablePadding: false,
              label: t("sessionList.startTime"),
            },
            {
              id: "type",
              numeric: false,
              disablePadding: false,
              label: t("sessionList.type"),
            },
            {
              id: "duration",
              numeric: false,
              disablePadding: false,
              label: t("sessionList.duration"),
            },
            !(
              getDeviceType(deviceData.serialNumber) === XCRYO ||
              getDeviceType(deviceData.serialNumber) === ECABIN
            )
              ? {
                  id: "consumption",
                  numeric: false,
                  disablePadding: false,
                  label: t("sessionList.consumption"),
                }
              : {},
          ]}
          defaultSortRow={"startTime"}
          handleRowClick={handleRowClick}
          handleScrollClick={handleScrollClick}
          tableData={filteredList}
          rowsPerPage={200}
        />
      ) : undefined}
    </Paper>
  );
}

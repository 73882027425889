import { Button } from "@material-ui/core";
import Clear from "@material-ui/icons/Clear";
import React from "react";

export default function RedExitButton({ onClick, style }) {
  return (
    <Button
      style={
        style
          ? style
          : {
              position: "absolute",
              right: 10,
              backgroundColor: "red",
              padding: 0,
              minWidth: 0,
            }
      }
      onClick={onClick}
      size="small"
    >
      <Clear style={{ color: "white" }} />
    </Button>
  );
}

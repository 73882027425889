import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Delete from "@material-ui/icons/Delete";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  ECABIN,
  checkValidClientObject,
  contactObjectSkeleton,
  formatTimeWithTZOffset,
  getLCLocation,
  getLatestLifeCycleState,
  getToken,
  readClientDataFromLCS,
  requestErrorHandler,
  resolveTransferLocation,
} from "../Utils/Common";
import EnvSettings from "../Utils/EnvSettings";
import { showPopupSnackbar } from "../redux/actions/snackbarActions";
import BrowseContactsDialog from "./Contacts/BrowseContactsDialog";
import ContactInfoBadge from "./Contacts/ContactInfoBadge";

const server = EnvSettings.server;

export default function DeviceDialog({
  data: selectedTransfer,
  handleClose,
  header,
  allowDelete,
  quickEditOptions,
  noClientInfo,
}) {
  const { t } = useTranslation();
  const [stateValue, setStateValue] = useState();
  const [cO, setCO] = useState({});
  const dispatch = useDispatch();
  const [confirm, setConfirm] = useState(false);
  const [noteText, setNoteText] = useState();
  const [submitting, setSubmitting] = useState();
  const baseDev = selectedTransfer?.Device ?? false;

  const handleRadioChange = (e) => {
    setStateValue(e.target.value);
  };

  const submitTransfer = () => {
    var sv = stateValue === "switchClient" ? "demo" : stateValue;
    if (
      sv === "sold" &&
      (!cO || !Object.keys(cO).length || !checkValidClientObject(cO))
    ) {
      alert("Client information must be added!");
      return;
    }
    setSubmitting(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        clientInfo: sv === "return" ? undefined : cO,
        transferData: {
          ...selectedTransfer,
          state: sv,
        },
        noteText: noteText,
      }),
    };
    fetch(server + "/add_transfer", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);

        // Reload on success
        if (!result.error) {
          dispatch(showPopupSnackbar(result));
          setTimeout(() => {
            window.location.reload();
          }, 1200);
        } else {
          setSubmitting(false);
        }
      })
      .catch((e) => {});
  };

  const deleteTransfer = () => {
    setSubmitting(true);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        transferData: selectedTransfer,
      }),
    };
    fetch(server + "/delete_transfer", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);

        // Reload on success
        if (!result.error) {
          dispatch(showPopupSnackbar(result));
          setTimeout(() => {
            window.location.reload();
          }, 1200);
        } else {
          setSubmitting(false);
        }
      })
      .catch((e) => {});
  };
  const getNoteStrings = (data) => {
    if (data.notes) {
      return data.notes;
    }
    var x;
    try {
      x = data.Notes.map((n) => "-" + n.text).join("\n");
    } catch (error) {}
    if (!x) {
      return getLatestLifeCycleState(data.LifeCycleStates)
        ?.Notes.map((n) => "-" + n.text)
        .join("\n");
    }
    return x;
  };

  useEffect(() => {
    if (selectedTransfer) {
      var x = noClientInfo ? {} : readClientDataFromLCS(selectedTransfer);
      setCO({ ...contactObjectSkeleton, ...x });
    }
    return () => {
      setStateValue();
      setConfirm(false);
    };
  }, [selectedTransfer, noClientInfo]);

  return (
    <Dialog
      open={selectedTransfer !== undefined}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{header}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          {selectedTransfer ? (
            <>
              <Grid item xs={12}>
                <Typography>{selectedTransfer?.User?.fullName}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  {t("date") +
                    ": " +
                    formatTimeWithTZOffset(
                      baseDev && selectedTransfer?.LifeCycleStates?.length
                        ? getLatestLifeCycleState(
                            selectedTransfer.LifeCycleStates
                          ).createdAt
                        : selectedTransfer.createdAt,
                      ECABIN
                    )}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  {t("newLocation") + ": " + baseDev &&
                  selectedTransfer?.LifeCycleStates?.length
                    ? getLCLocation(
                        getLatestLifeCycleState(
                          selectedTransfer.LifeCycleStates
                        ).location
                      )
                    : getLCLocation(resolveTransferLocation(selectedTransfer))}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  {t("serialNumber") +
                    ": " +
                    (selectedTransfer.serialNumber ||
                      selectedTransfer.Device?.serialNumber)}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>{t("clientInfo")}:</Typography>
              </Grid>
              {stateValue && stateValue !== "return" ? (
                <>
                  {stateValue === "demo" || stateValue === "switchClient" ? (
                    <Typography style={{ fontSize: 13 }}>
                      *{t("productTransfers.leaveEmptyIfInCar")}
                    </Typography>
                  ) : null}
                  <BrowseContactsDialog
                    setClientInfoObject={setCO}
                    activeObject={
                      Object.values(cO).find((v) => v !== "") ? cO : undefined
                    }
                  />
                </>
              ) : null}
              {cO &&
              Object.keys(cO).length &&
              (cO.contactName || cO.companyName || cO.location) ? (
                <Grid container direction="row" style={{ marginTop: 5 }}>
                  <Grid item xs={10}>
                    <ContactInfoBadge
                      clientInfoObject={cO}
                      noPaper={true}
                      direction={"row"}
                    />
                  </Grid>
                  {stateValue && stateValue === "switchClient" ? (
                    <Grid item xs={2}>
                      <IconButton
                        onClick={() => {
                          setCO({});
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Grid>
                  ) : null}
                </Grid>
              ) : null}
              {/* {stateValue && stateValue !== "return"
                ? Object.keys(cO).map((cl, i) => (
                    <TextField
                      key={i}
                      fullWidth
                      style={{ marginTop: 10 }}
                      variant="outlined"
                      label={t(cl)}
                      defaultValue={cO[cl]}
                      onChange={(e, v) => {
                        if (e.target) {
                          setCO({ ...cO, [cl]: e.target.value });
                          return;
                        }
                        setCO({ ...cO, [cl]: undefined });
                      }}
                    />
                  ))
                : getClientObjectStrings(cO)} */}
              <Grid item xs={12}>
                <Typography>{t("sessionDetails.notes")}</Typography>
                <Typography>{getNoteStrings(selectedTransfer)}</Typography>
                {stateValue ? (
                  <TextField
                    name="note"
                    variant="outlined"
                    value={noteText}
                    fullWidth
                    id="note"
                    label={t("sessionDetails.note")}
                    onChange={(e) => setNoteText(e.target.value)}
                  />
                ) : undefined}
              </Grid>
            </>
          ) : null}
          {quickEditOptions ? (
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">{t("changeStatus")}</FormLabel>
                <RadioGroup
                  row
                  aria-label="position"
                  name="position"
                  onChange={handleRadioChange}
                >
                  {quickEditOptions && quickEditOptions.length
                    ? quickEditOptions.map((q) => (
                        <FormControlLabel
                          key={q}
                          value={q}
                          control={<Radio color="primary" />}
                          label={t(q)}
                        />
                      ))
                    : null}
                </RadioGroup>
              </FormControl>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Button variant="outlined" onClick={handleClose} color="primary">
              {t("cancel")}
            </Button>
            {quickEditOptions ? (
              <Button
                variant="contained"
                onClick={submitTransfer}
                color="primary"
                disabled={!stateValue}
                endIcon={
                  submitting ? (
                    <CircularProgress style={{ width: 20, height: 20 }} />
                  ) : null
                }
              >
                {t("save")}
              </Button>
            ) : null}
            {allowDelete ? (
              <Button
                onClick={() => {
                  setConfirm(true);
                }}
                variant="contained"
                color="secondary"
              >
                {t("delete")}
              </Button>
            ) : null}
          </Grid>
          {allowDelete && confirm ? (
            <Grid item xs={12}>
              <Typography>{t("areYouSure")}?</Typography>
              <Button
                variant="contained"
                color="secondary"
                onClick={deleteTransfer}
                endIcon={
                  submitting ? (
                    <CircularProgress style={{ width: 20, height: 20 }} />
                  ) : null
                }
              >
                {t("confirmDelete")}
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

const uiReducer = (state = {}, action) => {
	switch (action.type) {
	  case "SNACKBAR_POPUP":
		return {
		  ...state,
		  snackbarOpen: true,
		  snackbarObject: action.message
		};
	  case "SNACKBAR_CLEAR":
		return {
		  ...state,
		  snackbarOpen: false
		};
	  default:
		return {
			...state,
			snackbarOpen:false,
			snackbarObject:{error:false, message:""}
		};
	}
  };
  
  export default uiReducer;
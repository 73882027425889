import { ListItemIcon } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import LanguageIcon from "@material-ui/icons/Language";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { USER_GROUPS, checkRequiredPermissions } from "../../../Utils/Common";
import { showSidebar } from "../../../redux/actions/sidebarActions";

export default function Settings() {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  return checkRequiredPermissions([
    USER_GROUPS.ADMIN,
    USER_GROUPS.SALES,
    USER_GROUPS.SUPER_USER,
  ]) ? (
    <ListItem
      button
      onClick={() => {
        history.push("/worldmap");
        dispatch(showSidebar(false));
      }}
    >
      <ListItemIcon>
        <LanguageIcon />
      </ListItemIcon>
      <ListItemText primary={t("mainSideBar.worldmap")} />
    </ListItem>
  ) : null;
}

import { Paper, TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getToken, requestErrorHandler } from "../../../Utils/Common";
import EnvSettings from "../../../Utils/EnvSettings";
import { showPopupSnackbar } from "../../../redux/actions/snackbarActions";

const server = EnvSettings.server;

export default function NewUser(props) {
  const { t } = useTranslation();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState();
  const [email, setEmail] = useState();
  const [companies, setCompanies] = useState();
  const dispatch = useDispatch();

  let ref = React.useRef();

  let getCompanies = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token: getToken() }),
    };
    fetch(server + "/get_companies", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          setCompanies(result.companies);
        }
      });
  };

  function getCompanyID(c) {
    var a = companies.filter((comp) => {
      return comp.name === c;
    });
    return a[0].id;
  }

  useEffect(() => {
    if (!companies) {
      getCompanies();
    }
  });

  function submitForm() {
    var company = ref.current.children[1].children[0].getAttribute("value");
    if (company === "" || company === null || company === undefined) {
      // Set error
      return;
    }
    if (!(username && firstname && lastname && email && password)) {
      //Set error
      return;
    }

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        username: username,
        email: email,
        password: password,
        name: firstname + " " + lastname,
        companyid: getCompanyID(company),
        token: getToken(),
      }),
    };
    fetch(server + "/create_new_user", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          dispatch(showPopupSnackbar(result));
        }
      });
  }

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={6}>
        <TextField
          autoComplete="uname"
          name="userName"
          variant="outlined"
          required
          fullWidth
          id="userName"
          label={t("newUser.username")}
          autoFocus
          onChange={(e) => setUsername(e.target.value)}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          required
          fullWidth
          name="password"
          label={t("newUser.password")}
          type="password"
          id="password"
          autoComplete="current-password"
          onChange={(e) => setPassword(e.target.value)}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          autoComplete="fname"
          name="firstName"
          variant="outlined"
          required
          fullWidth
          id="firstName"
          label={t("newUser.firstName")}
          onChange={(e) => setFirstname(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          required
          fullWidth
          id="lastName"
          label={t("newUser.lastName")}
          name="lastName"
          autoComplete="lname"
          onChange={(e) => setLastname(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          required
          fullWidth
          id="email"
          label={t("newUser.email")}
          name="email"
          autoComplete="email"
          onChange={(e) => setEmail(e.target.value)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        {companies ? (
          <Autocomplete
            id="combo-box"
            options={companies}
            getOptionLabel={(option) => option.name}
            PaperComponent={(props) => (
              <Paper style={{ border: "2px solid #3d57a5" }} {...props} />
            )}
            //style={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("newUser.company")}
                ref={ref}
                variant="outlined"
              />
            )}
          />
        ) : undefined}
      </Grid>
      <Grid item xs={12}>
        <Button variant="outlined" onClick={submitForm}>
          <Typography>{t("newUser.submit")}</Typography>
        </Button>
      </Grid>
    </Grid>
  );
}

import { Button, Grid, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Title from "../../Components/Title";
import {
  getLastParameter,
  getToken,
  requestErrorHandler,
} from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import { useStyles } from "../../Utils/Styles";
import { showPopupSnackbar } from "../../redux/actions/snackbarActions";
const server = EnvSettings.server;

export default function ProductionPhotos(props) {
  const classes = useStyles();
  const device_id = props.device_id || getLastParameter();
  const [checklistURL, setChecklistURL] = useState();
  // const [pdfURL, setPdfURL] = useState();
  const [files, setFiles] = useState();
  const [fileToDelete, setFileToDelete] = useState();
  const { t } = useTranslation();

  const fileUploadRef = useRef();

  const dispatch = useDispatch();

  const getPhotos = () => {
    setChecklistURL();
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        deviceid: device_id,
      }),
    };
    fetch(server + "/get_production_photos", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          if (result.files) {
            setChecklistURL(result);
          }
        }
      });
  };

  useEffect(() => {
    getPhotos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device_id, dispatch]);

  const getContentType = (val, blob) => {
    var arr = val.split(".");
    if (arr.length) {
      return new Blob([blob], { type: "image/" + arr[arr.length - 1] });
    }
  };

  const uploadPhoto = (e) => {
    if (!files && !!files.length) {
      return;
    }
    const data = new FormData();
    data.append("token", getToken());
    files.forEach((element) => {
      data.append("files[]", element);
    });
    data.append("device_id", device_id);
    fetch(server + "/upload_production_photos", {
      method: "POST",
      // headers: {
      //   "content-type": file.type,
      //   "content-length": `${file.size}`, // 👈 Headers need to be a string
      // },
      body: data,
    })
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        dispatch(showPopupSnackbar(result));
        if (!result.error) {
          getPhotos();
          fileUploadRef.current.value = null;
        }
      });
  };

  const removePhoto = () => {
    if (!fileToDelete && !device_id) {
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        filename: fileToDelete,
        device_id: device_id,
      }),
    };
    fetch(server + "/delete_production_photo", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          dispatch(showPopupSnackbar(result));
        }

        getPhotos();
      });
  };

  const showPhoto = (val, dir) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        token: getToken(),
        filename: val,
        file_location: dir,
      }),
    };
    fetch(server + "/show_checklist", requestOptions)
      .then((response) => {
        const reader = response.body.getReader();
        return new ReadableStream({
          start(controller) {
            return pump();
            function pump() {
              return reader.read().then(({ done, value }) => {
                // When no more data needs to be consumed, close the stream
                if (done) {
                  controller.close();
                  return;
                }
                // Enqueue the next data chunk into our target stream
                controller.enqueue(value);
                return pump();
              });
            }
          },
        });
      })
      // Create a new response out of the stream
      .then((stream) => new Response(stream))
      // Create an object URL for the response
      .then((response) => response.blob())
      .then((blob) => {
        // setPdfURL(blob);
        const fileName = "checklist.pdf";

        const newBlob = getContentType(val, blob);

        const newWindow = window.open("", fileName, "width=800,height=1200");
        if (newWindow) {
          setTimeout(() => {
            const dataUrl = window.URL.createObjectURL(newBlob);
            const title = newWindow.document.createElement("title");
            const iframe = newWindow.document.createElement("iframe");

            title.appendChild(document.createTextNode(fileName));
            newWindow.document.head.appendChild(title);

            iframe.setAttribute("width", "99%");
            iframe.setAttribute("height", "99%");

            iframe.setAttribute("src", dataUrl);

            newWindow.document.body.appendChild(iframe);

            setTimeout(() => {
              // For Firefox it is necessary to delay revoking the ObjectURL
              // if (!val.includes(".pdf")) {
              //   newWindow.document.body
              //     .getElementsByTagName("iframe")[0]
              //     .contentDocument.getElementsByTagName("img")[0]
              //     .setAttribute("style", "height:-webkit-fill-available;");
              // }
              window.URL.revokeObjectURL(dataUrl);
            }, 100);
          }, 100);
        }
      })
      .catch((err) => console.error(err));
  };

  const handleClose = () => {
    setFileToDelete(undefined);
  };

  return (
    <Grid container spacing={3}>
      <Dialog
        open={fileToDelete ? true : false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("delete")} {fileToDelete}?
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {t("cancel")}
          </Button>
          <Button
            onClick={() => {
              removePhoto();
              handleClose();
            }}
            color="primary"
            autoFocus
          >
            {t("delete")}
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={3} item xs={12} sm={6}>
        <Grid item xs={12}>
          <Title>{t("productionPhotos")}</Title>
        </Grid>
        {checklistURL ? (
          checklistURL.files.map((f, i) => (
            <Grid key={i} item xs={12}>
              <Button
                variant="outlined"
                onClick={() => showPhoto(f, checklistURL.root_dir)}
              >
                {f}
              </Button>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {
                  setFileToDelete(f);
                }}
              >
                {t("delete")}
              </Button>
            </Grid>
          ))
        ) : (
          <Grid item>
            <Typography>{t("noPhotosAvailable")}</Typography>
          </Grid>
        )}
      </Grid>
      <Grid container item xs={12} sm={6}>
        <Grid item xs={12}>
          <Title>{t("uploadNewPhotos")}</Title>
        </Grid>
        <Grid item>
          <input
            accept="image/*"
            className={classes.input}
            id="contained-button-file"
            type="file"
            multiple
            onChange={(e) => {
              if (e.target.files) {
                setFiles(Array.prototype.slice.call(e.target.files));
              }
            }}
            ref={fileUploadRef}
          />
        </Grid>
        <Grid item>
          <label htmlFor="contained-button-file">
            <Button
              variant="outlined"
              disabled={!(files && files.length) ? true : false}
              onClick={uploadPhoto}
            >
              {t("uploadPhotos")}
            </Button>
          </label>
        </Grid>
      </Grid>
    </Grid>
  );
}

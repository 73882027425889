import { Box, Container, CssBaseline } from "@material-ui/core";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import Copyright from "../Components/Copyright";
import Sidebar from "../Components/Sidebar";
import Topbar from "../Components/Topbar";
import { checkRequiredPermissions, getToken } from "./Common";
import { useStyles } from "./Styles";

// handle the private routes
function PrivateRoute({
  permissionRequirements,
  component: Component,
  ...rest
}) {
  const classes = useStyles();
  const redirection = window.location.pathname;

  const checkPermissionRequirements = () => {
    if (!permissionRequirements) {
      return true;
    }
    return checkRequiredPermissions(permissionRequirements.permissionlevels);
  };
  return (
    <Route
      render={() =>
        getToken() && checkPermissionRequirements() ? (
          <React.Fragment>
            <CssBaseline />
            <Topbar props={{ ...rest }} />
            <Sidebar />
            <main className={classes.content}>
              <div className={classes.appBarSpacer} />
              <Container maxWidth={false} className={classes.container}>
                <Component {...rest} />
                <Box pt={4}>
                  <Copyright />
                </Box>
              </Container>
            </main>
          </React.Fragment>
        ) : (
          <Redirect to={{ pathname: "/login" + redirection }} />
        )
      }
    />
  );
}

export default PrivateRoute;

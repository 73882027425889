import { CircularProgress, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import withWidth from "@material-ui/core/withWidth";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import DeviceDialog from "../../Components/DeviceDialog";
import DeviceStatusBadge from "../../Components/DeviceStatusBadge";
import SortableTable from "../../Components/SortableTable";
import Title from "../../Components/Title";
import {
  ECABIN,
  formatClientInfoString,
  formatTimeWithTZOffset,
  getToken,
  readClientDataFromLCS,
  requestErrorHandler,
} from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
import { useStyles } from "../../Utils/Styles";

const server = EnvSettings.server;

function DemoDevices(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [searchList, setSearchList] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedTransfer, setSelectedTransfer] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    const formatTransfer = (data) => {
      var arr = [];
      data.forEach((el) => {
        // Tässä tämä toimii, koska LCSiä haetaan tasan yksi (viimeisin)
        var element = el.LifeCycleStates[0];
        arr.push({ ...element, serialNumber: el.serialNumber });
      });
      return arr;
    };
    const getTransfers = () => {
      setLoading(true);
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: getToken(),
        }),
      };
      fetch(server + "/get_personal_demos", requestOptions)
        .then((res) => res.json())
        .then((result) => {
          requestErrorHandler(result, dispatch);
          if (!result.error) {
            var a = formatTransfer(result.lcs);
            setSearchList(a);
          }
          setLoading(false);
        })
        .catch((e) => {
          console.error(e);
          setLoading(false);
        });
    };
    getTransfers();
  }, [dispatch, t]);

  const handleClose = () => {
    setSelectedTransfer();
  };

  return (
    <Grid container spacing={3}>
      <DeviceDialog
        data={selectedTransfer}
        handleClose={handleClose}
        header={t("returnOrSell")}
        quickEditOptions={["return", "sold", "switchClient"]}
      />
      <Grid item xs={12}>
        <Paper className={classes.paper}>
          <Title>{t("mainSideBar.demos")}</Title>
          {/* <EditOrder selectedOrder={selectedOrder} /> */}
          {props.width === "xs" && searchList && searchList.length ? (
            searchList
              .sort((a, b) =>
                a.createdAt > b.createdAt
                  ? -1
                  : b.createdAt > a.createdAt
                  ? 1
                  : 0
              )
              .map((val, i) => (
                <DeviceStatusBadge
                  onClick={setSelectedTransfer}
                  data={val}
                  key={i}
                />
              ))
          ) : searchList && searchList.length ? (
            <SortableTable
              headCells={[
                {
                  id: "createdAt",
                  numeric: false,
                  disablePadding: false,
                  label: t("date"),
                },
                {
                  id: "location",
                  numeric: false,
                  disablePadding: false,
                  label: t("newLocation"),
                },
                {
                  id: "serialNumber",
                  numeric: false,
                  disablePadding: false,
                  label: t("serialNumber"),
                },
                {
                  id: "clientInfoString",
                  numeric: false,
                  disablePadding: false,
                  label: t("clientInfo"),
                },
                {
                  id: "Notes",
                  numeric: false,
                  disablePadding: false,
                  label: t("sessionDetails.notes"),
                },
              ]}
              disablePagination={true}
              defaultSortRow={"createdAt"}
              handleRowClick={(e, v) => {
                setSelectedTransfer(e);
              }}
              tableData={searchList}
              rowsPerPage={200}
              specialDataFormatting={[
                {
                  id: "createdAt",
                  format: (d) => {
                    return formatTimeWithTZOffset(d, ECABIN);
                  },
                },
                {
                  id: "location",
                  format: (d, dd, ddd) => {
                    return t("transferLocations." + d);
                  },
                },
                {
                  id: "clientInfoString",
                  format: (d, dd, ddd) => {
                    return formatClientInfoString(readClientDataFromLCS(ddd), [
                      "companyName",
                      "contactName",
                      "location",
                    ]);
                  },
                },
                {
                  id: "Notes",
                  format: (d, dd, ddd) => {
                    return d?.map((n) => n.text).join(" / ");
                  },
                },
              ]}
            />
          ) : searchList ? (
            <Typography>{t("noDemoDevices")}</Typography>
          ) : loading ? (
            <CircularProgress />
          ) : undefined}
        </Paper>
      </Grid>
    </Grid>
  );
}

export default withWidth()(DemoDevices);

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  ECABIN,
  formatTimeWithTZOffset,
  resolveTransferLocation,
} from "../../Utils/Common";

const resolveTransferState = (data) => {
  if (data === "dispatched") {
    return "sold";
  }
  if (data === "maintenance") {
    return "return";
  }
  return data;
};

export default function TransferHistory({ LCS, handleClose, header }) {
  const { t } = useTranslation();
  return (
    <Dialog
      open={LCS !== undefined}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle id="responsive-dialog-title">{header}</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          {LCS && LCS.length
            ? LCS.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)).map(
                (l, i) => (
                  <Grid key={i} item xs={12}>
                    <Typography style={{ fontSize: 15 }}>
                      {formatTimeWithTZOffset(l.createdAt, ECABIN)}
                    </Typography>
                    <Typography style={{ fontSize: 15 }}>
                      {t("transferLocations." + resolveTransferLocation(l))}
                    </Typography>
                    <Typography style={{ fontSize: 15 }}>
                      {t("transferStates." + resolveTransferState(l.state))}
                    </Typography>
                  </Grid>
                )
              )
            : null}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import SortableTable from "../../Components/SortableTable";
import {
  getLastParameter,
  getToken,
  requestErrorHandler,
} from "../../Utils/Common";
import EnvSettings from "../../Utils/EnvSettings";
const server = EnvSettings.server;

export default function CrashLogs(props) {
  const { t } = useTranslation();
  const [crashlogs, setCrashlogs] = useState();
  const device_id = getLastParameter();

  const dispatch = useDispatch();

  const history = useHistory();

  let handleRowClick = (data) => {
    if (window.location.href.includes("/cpanel/")) {
      history.push("/cpanel/crashlogs/" + data.id);
    }
  };

  const handleScrollClick = (event, id) => {
    if (window.location.href.includes("/cpanel/")) {
      window.open("/cpanel/crashlogs/" + id.id, "_blank");
    }
  };

  useEffect(() => {
    setCrashlogs();
    const getSessions = () => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          token: getToken(),
          deviceid: device_id,
        }),
      };
      fetch(server + "/get_crashlogs", requestOptions)
        .then((res) => res.json())
        .then((result) => {
          requestErrorHandler(result, dispatch);
          if (!result.error) {
            if (result.crashlogs && result.crashlogs.length !== 0) {
              setCrashlogs(result.crashlogs);
            }
          }
        });
    };
    getSessions();
  }, [device_id, dispatch]);

  return (
    <>
      {crashlogs ? (
        <SortableTable
          headCells={[
            {
              id: "date",
              numeric: false,
              disablePadding: false,
              label: t("date"),
            },
            {
              id: "type",
              numeric: false,
              disablePadding: false,
              label: t("sessionList.type"),
            },
            {
              id: "version",
              numeric: false,
              disablePadding: false,
              label: t("version"),
            },
            {
              id: "reason",
              numeric: false,
              disablePadding: false,
              label: t("reason"),
            },
          ]}
          defaultSortRow={"date"}
          handleRowClick={handleRowClick}
          handleScrollClick={handleScrollClick}
          tableData={crashlogs}
          rowsPerPage={25}
        />
      ) : undefined}
    </>
  );
}

import { ListItemIcon } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { removeUserSession } from "../../../Utils/Common";
import { useStyles } from "../../../Utils/Styles";
import { showSidebar } from "../../../redux/actions/sidebarActions";

export default function Logout() {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  return (
    <ListItem button className={classes.sideBarButton}>
      <ListItemIcon>
        <ExitToAppIcon />
      </ListItemIcon>

      <ListItemText
        primary={t("topbar.logout")}
        onClick={() => {
          removeUserSession();
          history.push("/login");
          dispatch(showSidebar(false));
        }}
      />
    </ListItem>
  );
}

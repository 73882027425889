const EnvSettings = {
  server:
    process.env.NODE_ENV !== "production"
      ? process.env.REACT_APP_DEV_ENV
        ? "http://127.0.0.1:3000/api"
        : "http://54.155.158.222:3000/api"
      : "/api",
};

export default EnvSettings;

import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Copyright from "../Components/Copyright";
import { requestErrorHandler } from "../Utils/Common";
import EnvSettings from "../Utils/EnvSettings";
import { showPopupSnackbar } from "../redux/actions/snackbarActions";

const server = EnvSettings.server;

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: "url(https://source.unsplash.com/random)",
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SendPasswordReset(props) {
  const classes = useStyles();
  const [email, setEmail] = useState();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = (e) => {
    e.preventDefault();

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email: email }),
    };
    fetch(server + "/reset_password_request", requestOptions)
      .then((res) => res.json())
      .then((result) => {
        requestErrorHandler(result, dispatch);
        if (!result.error) {
          dispatch(showPopupSnackbar(result));
        }
      });
  };

  return (
    <Grid
      container
      component="main"
      className={classes.root}
      style={{ justifyContent: "center" }}
    >
      <CssBaseline />
      <Grid item xs={6} square>
        <div className={classes.paper}>
          <Typography component="h1" variant="h5">
            {t("sendPasswordReset.header")}
          </Typography>
          {window.location.href.includes("/renew_password") ? (
            <Typography>{t("sendPasswordReset.renew")}</Typography>
          ) : undefined}
          <form className={classes.form} noValidate>
            <React.Fragment>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="Email"
                label={t("sendPasswordReset.email")}
                name="Email"
                autoComplete="Email"
                onChange={(evt) => setEmail(evt.target.value)}
                autoFocus
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={(e) => handleSubmit(e)}
              >
                {t("sendPasswordReset.submit")}
              </Button>
            </React.Fragment>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

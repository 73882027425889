import React from "react";
import CountryData from "./country_data.json";
const images = require.context("./flags/4x3/", true);

export default function CountryFlag({ country_name, style }) {
  const data = CountryData;
  let imgs;
  try {
    imgs = images(
      "./" + data.find((o) => o.country === country_name)?.code + ".svg"
    );
  } catch (error) {}

  return country_name ? (
    <img
      alt={"flag"}
      src={imgs}
      style={style ?? { width: 20, marginRight: 5 }}
    ></img>
  ) : null;
}

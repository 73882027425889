import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AdminLogin from "./AdminTools/AdminLogin";
import ControlPanel from "./AdminTools/ControlPanel";
import DeviceModule from "./AdminTools/DeviceModule";
import AdminDevices from "./AdminTools/DeviceModule/AdminDevices";
import MaintenanceTickets from "./AdminTools/MaintenanceModule/MaintenanceTickets";
import SoftwareDetails from "./AdminTools/SoftwareModule";
import CrashlogDetails from "./AdminTools/SoftwareModule/CrashlogDetails";
import Crashlogs from "./AdminTools/SoftwareModule/Crashlogs";
import StockModule from "./AdminTools/StockModule";
import SystemDashboard from "./AdminTools/SystemModule";
import NewUser from "./AdminTools/SystemModule/NewUser";
import Users from "./AdminTools/SystemModule/Users";
import EditUser from "./AdminTools/SystemModule/Users/EditUser";
import WebstoreOrders from "./AdminTools/WebstoreOrders";
import "./App.css";
import PopupSnackbar from "./Components/PopupSnackbar";
import AdminRoute from "./Utils/AdminRoute";
import { USER_GROUPS, getToken } from "./Utils/Common";
import PrivateRoute from "./Utils/PrivateRoute";
import PublicRoute from "./Utils/PublicRoute";
import { useStyles } from "./Utils/Styles";
import Alerts from "./Views/Alerts";
import AlertView from "./Views/Alerts/Alert";
import ChangePassword from "./Views/ChangePassword";
import Contacts from "./Views/Contacts";
import Contact from "./Views/Contacts/Contact";
import Dashboard from "./Views/Dashboard";
import DemoDevices from "./Views/DemoDevices";
import DeviceDetails from "./Views/DeviceDetails";
import Devices from "./Views/Devices";
import DispatchedDevices from "./Views/DispatchedDevices";
import Login from "./Views/Login";
import Orders from "./Views/Orders";
import ProductTransfers from "./Views/ProductTransfers";
import Production from "./Views/Production";
import ResupplyOrders from "./Views/ResupplyOrders";
import SendPasswordReset from "./Views/SendPasswordReset";
import SessionDetails from "./Views/SessionDetails";
import Settings from "./Views/Settings";
import WorldMap from "./Views/WorldMap";

export default function App(props) {
  const classes = useStyles();
  useEffect(() => {
    setTimeout(() => {
      // This timeout triggers after 25 hours and makes sure that when the token expires the user is forced to relogin
      window.location.reload();
    }, 1000 * 60 * 60 * 25);
  }, []);
  return (
    <React.Fragment>
      <PopupSnackbar />
      <div className={classes.root}>
        <Switch>
          <Route exact path="/">
            {getToken() ? (
              <Redirect to={{ pathname: "/dashboard" }} />
            ) : (
              <Redirect to={{ pathname: "/login" }} />
            )}
          </Route>
          <AdminRoute path="/cpanel/alerts/:alert_id" component={AlertView} />
          <AdminRoute path="/cpanel/alerts" component={Alerts} />
          <AdminRoute path="/cpanel/contacts/:type/:id" component={Contact} />
          <AdminRoute path="/cpanel/contacts" component={Contacts} />

          <AdminRoute
            path="/cpanel/users/new"
            component={NewUser}
            superuser={true}
          />
          <AdminRoute
            path="/cpanel/users/:id"
            component={EditUser}
            superuser={true}
          />
          <AdminRoute path="/cpanel/users" component={Users} superuser={true} />
          <AdminRoute
            path="/cpanel/crashlogs/:crashlog_id"
            component={CrashlogDetails}
          />
          <AdminRoute path="/cpanel/crashlogs" component={Crashlogs} />
          <AdminRoute
            path="/cpanel/devices/dashboard"
            component={DeviceModule}
          />
          <AdminRoute
            path="/cpanel/devices/:device_id"
            component={DeviceDetails}
          />
          <AdminRoute path="/cpanel/devices" component={AdminDevices} />
          <AdminRoute path="/cpanel/demos" component={DemoDevices} />
          <AdminRoute path="/cpanel/dispatches" component={DispatchedDevices} />
          {/* <AdminRoute path="/cpanel/manufacturing" component={Manufacturing} /> */}
          <AdminRoute path="/cpanel/resupply" component={ResupplyOrders} />
          <AdminRoute path="/cpanel/orders" component={Orders} />
          <AdminRoute path="/cpanel/production" component={Production} />
          <AdminRoute path="/cpanel/transfers" component={ProductTransfers} />
          <AdminRoute path="/cpanel/tickets" component={MaintenanceTickets} />
          <AdminRoute
            path="/cpanel/sessions/:session_id"
            component={SessionDetails}
          />
          <AdminRoute path="/cpanel/settings" component={Settings} />
          <AdminRoute
            path="/cpanel/software"
            component={SoftwareDetails}
            superuser={true}
          />
          <AdminRoute path="/cpanel/stock" component={StockModule} />
          <AdminRoute
            path="/cpanel/system"
            component={SystemDashboard}
            superuser={true}
          />
          <AdminRoute
            path="/cpanel/webstore"
            component={WebstoreOrders}
            superuser={true}
          />
          <AdminRoute path="/cpanel/worldmap" component={WorldMap} />
          <AdminRoute path="/cpanel" component={ControlPanel} />

          {/* Normal routes */}
          <PrivateRoute path="/worldmap" component={WorldMap} />
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/devices/:device_id" component={DeviceDetails} />
          <PrivateRoute path="/devices" component={Devices} />
          <PrivateRoute
            path="/production"
            permissionRequirements={{
              permissionlevels: [
                USER_GROUPS.ADMIN,
                USER_GROUPS.PRODUCTION,
                USER_GROUPS.SALES,
              ],
            }}
            component={Production}
          />
          <PrivateRoute
            path="/contacts/:type/:id"
            permissionRequirements={{
              permissionlevels: [USER_GROUPS.ADMIN, USER_GROUPS.SALES],
            }}
            component={Contact}
          />
          <PrivateRoute
            path="/contacts"
            permissionRequirements={{
              permissionlevels: [USER_GROUPS.ADMIN, USER_GROUPS.SALES],
            }}
            component={Contacts}
          />

          <PrivateRoute
            path="/sessions/:session_id"
            component={SessionDetails}
          />
          <PrivateRoute path="/settings" component={Settings} />
          <PrivateRoute
            path="/transfers"
            permissionRequirements={{
              permissionlevels: [USER_GROUPS.ADMIN, USER_GROUPS.SALES],
            }}
            component={ProductTransfers}
          />
          <PrivateRoute
            path="/demos"
            permissionRequirements={{
              permissionlevels: [USER_GROUPS.ADMIN, USER_GROUPS.SALES],
            }}
            component={DemoDevices}
          />
          <PrivateRoute
            path="/dispatches"
            permissionRequirements={{
              permissionlevels: [USER_GROUPS.ADMIN, USER_GROUPS.SALES],
            }}
            component={DispatchedDevices}
          />
          <PrivateRoute
            path="/orders"
            permissionRequirements={{ permissionlevels: [USER_GROUPS.ADMIN] }}
            component={Orders}
          />
          <PublicRoute
            path="/reset_password/:token"
            component={ChangePassword}
          />
          <PublicRoute path="/renew_password" component={SendPasswordReset} />
          <PublicRoute path="/reset_password" component={SendPasswordReset} />
          <PublicRoute
            path="/admin_login/:redirection_path"
            component={AdminLogin}
          />
          <PublicRoute path="/admin_login" component={AdminLogin} />
          <PublicRoute path="/login/:redirection_path" component={Login} />
          <PublicRoute path="/login" component={Login} />
        </Switch>
      </div>
    </React.Fragment>
  );
}

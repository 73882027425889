import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import React from "react";
import { useTranslation } from "react-i18next";
import { formatClientInfoString } from "../../../Utils/Common";

export default function ForceDevsDialog({
  showForceDevs,
  setShowForceDevs,
  setForceDevs,
}) {
  const { t } = useTranslation();
  return (
    <Dialog
      open={!!showForceDevs}
      onClose={() => {
        setShowForceDevs(false);
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t("orders.devicesAlreadyAssigned")}
      </DialogTitle>
      <DialogContent>
        {showForceDevs &&
          showForceDevs.map((m) => (
            <Typography>
              {m.device.serialNumber}{" "}
              {formatClientInfoString(m.order.clientInfo, [
                "companyName",
                "contactName",
                "location",
              ])}
            </Typography>
          ))}
        <Typography>{t("orders.reallocateDevices")}</Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setShowForceDevs(false);
          }}
          color="primary"
          variant="outlined"
          autoFocus
        >
          {t("no")}
        </Button>
        <Button
          onClick={() => {
            setForceDevs(true);
          }}
          color="secondary"
          variant="contained"
        >
          {t("yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

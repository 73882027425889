import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import CommentIcon from "@material-ui/icons/Comment";
import React from "react";
import { useTranslation } from "react-i18next";
import { getLogoImage, readClientDataFromLCS } from "../Utils/Common";
import { useStyles } from "../Utils/Styles";
import ContactInfoBadge from "./Contacts/ContactInfoBadge";

export default function DeviceStatusBadge({ onClick, data, noClientInfo }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const getCommentIcon = (data) => {
    var comment;
    try {
      comment = (data.Notes && data.Notes.length) || data.note;
    } catch (error) {
      console.log(data, error);
    }
    if (comment) {
      return <CommentIcon style={{ color: "gray" }} />;
    }
    return null;
  };
  return (
    <Paper
      className={classes.paper}
      style={{
        marginTop: 5,
        // This is used with the dispatched devices
        backgroundColor: data.withClient === t("returned") ? "lightgray" : "",
      }}
      onClick={() => {
        if (data.withClient && data.state !== "dispatched") {
          return;
        }
        onClick(data);
      }}
    >
      <Grid item container xs={12}>
        <Grid item xs={6}>
          <img
            alt="Device"
            src={getLogoImage(data.serialNumber || data.Device?.serialNumber)}
            style={{ maxWidth: 100, maxHeight: 100 }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography>{data.serialNumber}</Typography>
          <Typography>{data.createdAt.substring(0, 10)}</Typography>
          <Typography>
            {data.withClient
              ? data.withClient === t("returned")
                ? data.withClient
                : ""
              : data.location === "client" &&
                (!readClientDataFromLCS(data) ||
                  !Object.values(readClientDataFromLCS(data)).find(
                    (v) => v !== ""
                  ))
              ? t("transferLocations.car")
              : t("transferLocations." + data.location)}
          </Typography>
          <Typography>{getCommentIcon(data)}</Typography>
        </Grid>
        <Grid item xs={12}>
          <ContactInfoBadge
            clientInfoObject={
              noClientInfo ? undefined : readClientDataFromLCS(data)
            }
            noPaper={true}
            showBrief={true}
            spacing={1}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}
